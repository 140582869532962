 import React from 'react'
import UpdateBlogform from '../form/UpdateBlogform'

 
 const BlogUpdate = () => {
   return (
     <div>
        <UpdateBlogform/>
     </div>
   )
 }
 
 export default BlogUpdate