import moment from 'moment';
import React, { useState } from 'react'
import { OutlinedButton } from '../Common/Button';
import DataTable from 'react-data-table-component';
import FilterComponent from '../Common/FilterComponent';
import { useStationsList } from '../hooks/stations';
import StationAddModal from '../model/StationAddModal';
import UpdateStationModel from '../model/UpdateStationModel';

const StationComp = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { stationsData, stationsIsLoading } = useStationsList();
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [stationData,setStationData] = useState()
  

//     console.log(stationsData)
const arr = stationsData || []
// const active = arr.filter(function(item)
// {
//      return item.status ==='Active';
// });

    // Table Header
    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);


    const columns = [
        {
            name: 'Station Name',
            selector: row => row.stationName,
            sortable: true,
        },
        {
            name: 'Station Code',
            selector: row => row.stationCode,
            sortable: true,
        },
     
        {
            name: 'Service Status',
            selector: row => row.isServiceAvailable ? <span className='badge badge-success'>Yes</span> : <span className='badge badge-danger'>No</span>,
            sortable: true,
        },
        {
            name: 'Created at',
            selector: row => moment(row.createdAt).format('DD-MM-YYYY')
        },
        {
            name: 'Updated at',
            selector: row => moment(row.updatedAt).format('DD-MM-YYYY hh:mm')
        },
        {
            name: 'Action',
            selector: row => <>
            <button className='btn btn-primary'
               data-toggle="modal"
               data-target="#exampleModalupdatetwoo"
               data-whatever="@getbootstrap"
               onClick={()=>setStationData(row)}
            >Update</button>
            </>
        },
     
    ];

    // Filter data
    const filteredItems = stationsData && !stationsIsLoading ? stationsData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    ) : [];
    return (
    
        <div className="card pd-20 pd-sm-40">

<UpdateStationModel id="exampleModalupdatetwoo"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data={stationData}
 />
            <h6 className="card-body-title">All Stations</h6>
            <p className="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>
            <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1">
                  <i className="fas fa-store" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Stations</span>
                  <span className="info-box-number">{arr.length}</span>
                </div>
              </div>
            </div>
     

      
          </div>


            <StationAddModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
            />
            <OutlinedButton className='col-2' label='Add new station' onClick={() => setModalIsOpen(true)} />
            <div className="table-wrapper">
                {
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        progressPending={stationsIsLoading}

                        subHeaderAlign="right"
                        subHeaderWrap
                        subHeaderComponent={subHeaderComponent}
                        subHeader
                    />
                }
            </div>
        </div>
    )
}


export default StationComp;