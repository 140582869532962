import React from 'react'
import DataTable from 'react-data-table-component'
import ActionModal from './ActionModal';
import { formatDateTime } from '../../../utils/core';
import Skeleton from 'react-loading-skeleton';
import { capitalize } from '@mui/material';

export default function PendingTransactionsTable({ data, isLoading }) {
    const [currentData, setCurrentData] = React.useState(null);
    const [actionModal, setActionModal] = React.useState(false);
    const columns = [
        {
            name: "Outlet",
            cell: (row) => row?.outlet?.firm_name,
            sortable: false,
        },
        {
            name: "Type",
            selector: (row) => row.trnxType,
            cell: (row) => <div className={`badge ${row?.trnxType === 'CR' ? 'badge-success' : 'badge-primary'}`}>{isLoading ? row?.trnxType : row?.trnxType === 'CR' ? 'Credit' : 'Debit'}</div>,
            sortable: true,
        },
        {
            name: "Amount",
            cell: (row) => row?.amount?.$numberDecimal,
            sortable: false,
        },
        {
            name: "Added by",
            cell: (row) => row?.addedBy?.name,
            sortable: true,
        },
        {
            name: "Date Time",
            cell: (row) => isLoading ? row?.updatedAt : formatDateTime(row?.updatedAt, 'DD/MM/YYYY hh:mm A'),
            sortable: false,
        },
        {
            name: "Status",
            cell: (row) => <div role={row?.status === 'pending' ? 'button' : ''} className={`${row?.status === 'pending' ? 'text-primary' : row?.status === 'rejected' ? 'text-danger' : 'text-success'}`} onClick={() => { if (row?.status === 'pending') { setCurrentData(row); setActionModal(true); } }}>{isLoading ? row?.status : capitalize(row?.status)}</div>,
            sortable: false,
        },
    ];
    // rows skeleton
    const skeletonRows = Array.from({ length: 10 }, (_, index) => ({
        outlet: { firm_name: <Skeleton width={100} /> },
        amount: { $numberDecimal: <Skeleton width={50} /> },
        trnxType: <Skeleton width={50} />,
        addedBy: { name: <Skeleton width={100} /> },
        updatedAt: <Skeleton width={100} />,
        status: <Skeleton width={60} />,
    }));
    const subHeaderComponent = React.useMemo(() => {
        return (
            <div className="d-flex">
                {/* <button className="btn btn-dark btn-sm d-flex align-items-center mr-1" onClick={() => setOrdersFilterModal(true)}>
                    <i className="material-icons">filter_alt</i> <span className="ml-1">Filter</span>
                </button> */}
            </div>
        );
    }, []);
    return (
        <div>


            <DataTable
                columns={columns}
                data={isLoading ? skeletonRows : data}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                pagination
                responsive
                // progressPending={isLoading}
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
                subHeader
            />
            {actionModal && <ActionModal currentData={currentData} modal={actionModal} setModal={setActionModal} />}
        </div>
    )
}
