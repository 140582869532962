import CancelReqDetail from "../CancelComponent/Cancelreqdetail";


export default function ViewCancelReqModel({ item }) {
    return (<div className="modal fade" id="exampleModalupdatethree" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">View Cancel Request</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <CancelReqDetail item={item} />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                    >
                        Ok <i className="fa fa-check"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>)
}