
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import { Stack } from '@mui/material';
import SubmitButton from '../Common/Button';
import TextField from '../Common/MuiTextField';


export default function AddStationForm({handleSubmit,  isLoading }) {
    const validate = Yup.object({
        stationName: Yup.string()
            .required('Enter station name.'),
        stationCode: Yup.string()
            .required('Enter station code.'),
        city: Yup.string()
            .required('Enter city name.'),
    })
  
    return (
        <Formik
            initialValues={{
                stationName: '',
                stationCode: '',
                city: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {(formik)=> 
            <Form onSubmit={formik.handleSubmit}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='Station Name *' name='stationName' placeholder='Patna Junction'  onChange={formik.handleChange}/>
                    <TextField label='Station Code *' name='stationCode' placeholder='PNBE' onChange={formik.handleChange}/>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='City *' name='city' placeholder='Patna' onChange={formik.handleChange} />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <SubmitButton
                        loading={isLoading}
                        fullWidth
                        label='Add Station'
                    />
                </Stack>
            </Form>
             }
        </Formik>
    )
}
