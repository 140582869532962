import Blogdetail from "../BlogComponent/Blogdetail";


export default function ViewBlogModel({blog}) {
return <div className="modal fade" id="exampleModalview2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" style={{width:'100%'}}>
<div className="modal-dialog modal-dialog-scrollable" role="document">
<div className="modal-content">
<div className="modal-header">
        <h5 className="modal-title" id="exampleModalview2">Blog Detail</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

 <Blogdetail  blog = {blog} />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        
      </div>
</div> 
</div>
</div>
}