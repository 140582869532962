import React from 'react'
import VendorsListPage from '../../components/vendors/VendorList'
import Header from '../../components/Header/Header'
import Sidenav from '../../components/Sidenav/Sidenav'
import { useUser } from '../../components/hooks/user'

const Vendor = () => {
  const { user } = useUser();

  return (
    <div>
      <Header user={user}/>
<Sidenav/>
<div className="content-wrapper">

      

      <VendorsListPage/>
        </div>
    
    </div>
  )
}

export default Vendor