import React from 'react'
import Skeleton from 'react-loading-skeleton'

export default function OrderDetailSkeleton() {
    return (
        <div className='card pd-20 pd-sm-20 bg-light'>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h6 className="card-body-title text-primary"><Skeleton width={150} /></h6>
                    <p><Skeleton width={200} /></p>
                </div>
                <div>
                    {/* <Skeleton height={40} width={120} style={{ marginRight: '10px' }} /> */}
                    {/* <Skeleton height={40} width={120} style={{ marginRight: '10px' }} /> */}
                    <Skeleton height={40} width={400} style={{ marginRight: '10px' }} />
                </div>
            </div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <p className="display-4 font-p1 mb-1"><Skeleton width={250} /></p>
                                <p className="mb-1 display-4 font-p2"><Skeleton width={300} /></p>
                                <p className="mb-1 display-4 font-p2"><Skeleton width={150} /></p>
                            </div>
                            <div>
                                <Skeleton height={30} width={150} />
                            </div>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <Skeleton height={20} width={200} />
                                <Skeleton width={300} />
                                <Skeleton width={150} />
                                <Skeleton width={200} />
                                <Skeleton width={150} />
                            </div>
                            <div>
                                <Skeleton height={20} width={200} />
                                <Skeleton width={300} />
                                <Skeleton width={150} />
                                <Skeleton width={150} />
                                <Skeleton width={150} />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="table-responsive">
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </div>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <Skeleton width={200} />
                                <Skeleton width={150} />
                            </div>
                            <div>
                                <Skeleton width={200} />
                                <Skeleton width={150} />
                                <Skeleton width={150} />
                                <Skeleton width={150} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
