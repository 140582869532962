import React from 'react'
import { useUser } from '../../components/hooks/user';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import AddLocation from '../../components/form/AddLocation';
import LocationCont from '../../components/LocationComponent/LocationContent';

const Location = () => {
    const { user } = useUser();

    return (
      <div>
        <Header user={user} />
        <Sidenav />
        <div className="content-wrapper">
<LocationCont/>
        </div>
      </div>
    );
  };

export default Location