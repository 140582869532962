import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Loader from "../Loader";
import FilterComponent from "../Common/FilterComponent";
import { useUser, useUsersList } from "../hooks/user";
import AddUserModal from "../model/AddUserModal";
import UserViewModel from "../model/UserViewModel";
import UpdateUserModel from "../model/UpdateUserModel";

const UserComp = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { usersData, usersIsLoading } = useUsersList();
  const [data, setData] = React.useState(null);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
// console.log(user)

const { user } = useUser();
// console.log(user)
  const arr = usersData || []
  const active = arr.filter(function(item)
  {
       return item.isActive ===true;
  });

  const paginationComponentOptions = {
    rowsPerPageText: "Show records per page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All records",
  };

  // Table Header
  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "mobile",
      selector: (row) => row.mobile,
    },
   {
      name: "View",
      button: true,
      width: "200px",
      cell: (record) => ( 
        <>
          <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModaluserview"
            data-whatever="@getbootstrap"
            onClick={() => {
              setData(record);
              // console.log(record)
            }}
          >
            View
          </button>
        </>
      ),
    },
   {
      name: "Update",
      button: true,
      width: "200px",
      cell: (record) => ( 
        <>
          <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModaluserUpdate"
            data-whatever="@getbootstrap"
            onClick={() => {
              setData(record);
              // console.log(record)
            }}
          >
            Update
          </button>
        </>
      ),
    },
  ];
  if (usersIsLoading) {
    return <Loader />;
  }
  // const  filteredItems = []

  // Filter data
  const filteredItems = usersData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  return (
    <>
      <div className="card pd-20 pd-sm-40">
        <div className="text-right">
          <button className="btn btn-dark" onClick={() => setModalIsOpen(true)}>
            Add New User <i className="fa fa-plus"></i>
          </button>
        </div>
        <h6 className="card-body-title">Users Data Management</h6>
        <p className="mg-b-20 mg-sm-b-30">
          Searching, ordering and paging goodness will be immediately added to
          the table, as shown in this example.
        </p>

        <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1">
                  <i className="fas fa-store" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Users</span>
                  <span className="info-box-number">{arr.length}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1">
                  <i className="fas fa-shopping-cart" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Active Users</span>
                  <span className="info-box-number">{active.length}</span>
                </div>
              </div>
            </div>




            <div className="clearfix hidden-md-up" />
          
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">InActive Users</span>
                  <span className="info-box-number">
                    {arr.length - active.length}
                  </span>
                </div>
              </div>
            </div>

      
          </div>

        <AddUserModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
            />

        <div className="table-wrapper">
          {
            <DataTable
              columns={columns}
              data={filteredItems}
              direction="auto"
              fixedHeaderScrollHeight="300px"
              pagination
              responsive
              progressPending={usersIsLoading}
              // conditionalRowStyles={conditionalRowStyles}

              subHeaderAlign="right"
              subHeaderWrap
              subHeaderComponent={subHeaderComponent}
              subHeader
              paginationComponentOptions={paginationComponentOptions}
            />
          }
        </div>
        < UserViewModel
            id="exampleModaluserview"
            
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data={data} 
            />

        < UpdateUserModel
            id="exampleModaluserUpdate"
            
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data={data} 
            />
      </div>
    </>
  );
};

export default UserComp;
