import React from 'react'
import FilterComponent from '../Common/FilterComponent';
import Loader from '../Loader';
import DataTable from 'react-data-table-component';
import { useEnquiryList } from '../hooks/enquiry';
import UpdateEnquiryModel from '../model/UpdateEnquiryModal';
import ViewEnquaryModel from '../model/ViewEnquaryModel';
import moment from 'moment';

const EnquiryComp = () => {
    const { enquiryData, enquiryIsLoading } = useEnquiryList()
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    // console.log(enquiryData)
    const [state, setState] = React.useState({})
    const [flag, setFlag] = React.useState(false);

    const changeState = () => {
        setFlag(false)
        setState({})
    }

    const arr = enquiryData || []
    const active = arr.filter(function (item) {
        return item.status === "active";
    });

    const inProcess = arr.filter(function (item) {
        return item.status === "inProcess";
    });
    const closed = arr.filter(function (item) {
        return item.status === "closed";
    });
    const created = arr.filter(function (item) {
        return item.status === "created";
    });

    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);


    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            cell: row => <div>
                <p> {row.name}</p>
                <span style={{ fontSize: "10px" }}>update By - {row.updatedBy}</span>
            </div>,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile
        },
        {
            name: 'Description',
            selector: row => row.description,
            cell: row => <div>
                <p> {row.description.substring(0, 60)}...</p>
                <span style={{ fontSize: "10px" }}>Remarks - {row.remarks}</span>
            </div>,
            sortable: true,
        },
        {
            name: 'status',
            selector: row => {
                if (row.status === "created") {
                    return <span className="badge badge-primary">{row.status}</span>
                } else if (row.status === "closed") {
                    return <span className="badge badge-danger">{row.status}</span>
                }
                else if (row.status === "inProcess") {
                    return <span className="badge badge-warning">{row.status}</span>
                }
                else if (row.status === "active") {
                    return <span className="badge badge-success">{row.status}</span>
                }
            }
        },

        {
            name: 'Created at',
            selector: row => row.createdAt ? moment(row.createdAt).format("DD-MM-YYYY hh:mm") : ""
        },
        {
            name: 'Update',
            selector: row => <button className="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#exampleModalupdatetwo"
                data-whatever="@getbootstrap"
                onClick={() => {
                    setState(row)
                }}>Update</button>
        },
        {
            name: 'View',
            selector: row => <button className="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#exampleModalupdatethree"
                data-whatever="@getbootstrap"
                onClick={() => {
                    setState(row)
                }}>View</button>
        },
    ];

    if (enquiryIsLoading) {
        return <Loader />
    }



    const filteredItems = enquiryData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    return (
        <>

            <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">All Enquiry</h6>
                <p className="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box">
                            <span className="info-box-icon bg-info elevation-1">
                                <i className="fas fa-store" />
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-text">Enquiry</span>
                                <span className="info-box-number">{arr.length}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box mb-3">
                            <span className="info-box-icon bg-success elevation-1">
                                <i className="fas fa-shopping-cart" />
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-text">Active Enquiry</span>
                                <span className="info-box-number">{active.length}</span>
                            </div>
                        </div>
                    </div>




                    <div className="clearfix hidden-md-up" />

                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box mb-3">
                            <span className="info-box-icon bg-success elevation-1">
                                <i className="fas fa-comment-dollar" />
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-text">InProcess Enquiry</span>
                                <span className="info-box-number">
                                    {inProcess.length}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box mb-3">
                            <span className="info-box-icon bg-danger elevation-1">
                                <i className="fas fa-comment-dollar" />
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-text">Closed Enquiry</span>
                                <span className="info-box-number">
                                    {closed.length}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <div className="info-box mb-3">
                            <span className="info-box-icon bg-gradient-warning elevation-1">
                                <i className="fas fa-comment-dollar" />
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-text">Created Enquiry</span>
                                <span className="info-box-number">
                                    {created.length}
                                </span>
                            </div>
                        </div>
                    </div>


                </div>

                {/* <OutlinedButton className='col-2' label='Add new restaurant' onClick={() => navigate('/restaurants/add')}/> */}
                <div className="table-wrapper">
                    {
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            direction="auto"
                            fixedHeaderScrollHeight="300px"
                            pagination
                            responsive
                            progressPending={enquiryIsLoading}
                            // conditionalRowStyles={conditionalRowStyles}

                            subHeaderAlign="right"
                            subHeaderWrap
                            subHeaderComponent={subHeaderComponent}
                            subHeader
                        />
                    }
                </div>

                <UpdateEnquiryModel id="exampleModalupdatetwo"
                    item={state}
                    flag={flag}
                    changeState={changeState}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                />


                <ViewEnquaryModel
                    id="exampleModalupdatethree"

                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    item={state}
                />
            </div>


        </>
    )
}

export default EnquiryComp;