import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import "yup-phone";
import SubmitButton from '../Common/Button';
import { TextField, Typography } from '@mui/material';
import SelectField from '../Common/SelectField';


export default function UpdateUserForm({ isLoading = false,data }) {
    const validate = Yup.object({
        name: Yup.string().min(4)
            .required('Enter your name.'),
        role: Yup.string()
            .required('Select a role.'),
        mobile: Yup.string()
            .required('Enter your mobile.'),
        email: Yup.string().email()
            .required('Enter your email.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Enter a password for the account.'),
    })

    const ROLES_OPTIONS = [
        { 'label': 'Manager', value: 'manager' },
        { 'label': 'Staff', value: 'staff' },
        { 'label': 'Support Executive', value: 'supportExecutive' },
        { 'label': 'Floor Manager', value: 'floorManager' },
        { 'label': 'Delivery Boy', value: 'dboy' },
        { 'label': 'Accountant', value: 'accountant' },
    ]
    return (
        <Formik
            initialValues={{
                name: data.name,
                role: data.role,
                mobile: data.mobile,
                email: data.email,
                password: data.password,
            }}
            validationSchema={validate}
            // onSubmit={handleSubmit}
            enableReinitialize
        >
            {formik => (
                  <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Update User
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    //   ref={closeref}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                <Form onSubmit={formik.handleSubmit} className="form-row">
                
                        <div className="form-group col-md-12">
                        <Typography>Role <span className='req'>*</span></Typography>

                        <SelectField
                            name='role'
                            options={ROLES_OPTIONS}
                            isSearchable={false}
                            value={ROLES_OPTIONS.filter(function (option) {
                                return option.value === formik.values.role;
                            })}
                            onChange={(e) => formik.setFieldValue('role', e.value)}
                            />
                            </div>
                            <div className="form-group col-md-6">
                    <TextField label='Name' name='name' placeholder='Name' sx={{ mb: 3}} value={formik.values.name} onChange={formik.handleChange}/>
                         </div>  
                         <div className="form-group col-md-6">

                    <TextField label='Mobile' name='mobile' placeholder='Mobile' sx={{ mb: 3 }} value={formik.values.mobile}  onChange={formik.handleChange}/>
                            </div>     
                            <div className="form-group col-md-6">
                    <TextField label='Email' name='email' placeholder='Email' sx={{ mb: 3 }} value={formik.values.email}  onChange={formik.handleChange}/>
                                </div>
                                <div className="form-group col-md-6">

                    <TextField label='Password' type="password" name='password' value={formik.values.password}  placeholder='Your Password' sx={{ mb: 3 }} onChange={formik.handleChange}/>
                                </div>

                    <SubmitButton
                        loading={isLoading}
                        fullWidth
                        label='Update User'
                    />

                    {/* {response && !response.success && <div className="alert alert-danger mt-3" role="alert">{response.error}</div>} */}
                </Form>
                </div>
                </div>
            )}
        </Formik>

    )
}
