import UpdateItemForm from "../form/UpdateItemForm";

export default function UpdateItemModel({ flag, data, changeState }) {




    return (
        <div
            className="modal fade"
            id="exampleModalupdate"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            tabIndex={-1}

        >
            <div className="modal-dialog" role="document"  >
                {flag ? (
                    <UpdateItemForm
                        className="modal-content"
                        data={data}

                    />

                    //  <NewUpdateItemForm
                    //   className="modal-content"
                    //   data={data}
                    // />

                ) : (
                    "loading"
                )}
            </div>
        </div>
    );
}
