// import ItemDetail from "../Item/ItemDetail";

import { toast } from "react-toastify";
import { API_HOST_URL } from "../../config";
import { getTokens } from "../../utils/core";
import { useRef, useState } from "react";

export default function UploadModel() {
    const [value, setValue] = useState()
    const closeRef = useRef()

    const handleSubmit = (val, { resetForm }) => {


        const formData = new FormData();

        formData.append("items", val);

        const { accessToken } = getTokens();

        fetch(`${API_HOST_URL}/api/v1/item/upload`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {

                    toast.success("Upload Success!!");
                    closeRef.current.click()
                } else {
                    // console.log("Hey")
                    toast.error(data.error)
                }

            })
            .catch((error) => {
                toast.error(error)
                throw error;
            });
    };
    return (
        <div
            className="modal fade"
            id="exampleModalUpload"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalview3">
                            Upload Menu
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeRef}
                        ></button>
                    </div>
                    <div className="modal-body" style={{ padding: "20px" }}>
                        <input
                            type="file"
                            name="file"
                            accept=".xls, .xlsx"
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                handleSubmit(value);
                                closeRef.current.click();
                            }}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
