import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
// import { useRef } from 'react'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    width: 800,
};



export default function LogModal({ data, modal, setModal }) {
    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Order Log</h5>
                            <IconButton onClick={() => setModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='table-responsive'>
                            <table className="table table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th >Updated By</th>
                                        <th >Remarks</th>
                                        <th>Updated At</th>
                                        <th >Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.log?.length === 0 && <tr>
                                        <td colSpan="5" className="text-center">No Log Found</td>
                                    </tr>}
                                    {data?.log?.length > 0 && data?.log.map((val, i) => {
                                        return (
                                            <tr>
                                                <td scope="row">{i + 1}</td>
                                                <td>{val._id}</td>
                                                <td>{val.remarks}</td>
                                                <td scope="row">
                                                    {moment(data.updatedAt).format("DD-MM-YYYY hh:mm A")}
                                                </td>
                                                <td>{val.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}