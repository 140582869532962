import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";

const STATIONS_QUERY_KEY = 'stations'
const STATION_QUERY_KEY = 'station'

// Get Stations List
export function useStationsList() {
    const { isLoading, data } = useQuery([STATIONS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.stations.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000, // ms
        cacheTime: 0,
        staleTime: 1,
    })
    return { stationsData: data, stationsIsLoading: isLoading }
}




export const stripStationsList = (data) => {
    return {
        value: data._id,
        label: `${data.stationName} (${data.stationCode})`
    }
}


// Get Stations Detail
export function useStationsDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [STATION_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.stations.list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { stationsData: data, stationsIsLoading: isLoading, isError, status }
}


// Add Stations Hook
export function useStationsAdd() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.stations.add}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            ...values
        })
    }, true), {
        retry: 2,
    })

    return {
        handleAddStations: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([STATIONS_QUERY_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        addStationsIsLoading: mutation.isLoading,
    }
}




// text fetch
export function useFindStation(txt) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [STATION_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/api/v1/station?stationName=${txt}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { station: data, stationsIsLoading: isLoading, isError, status }
}



export function useUpdateStation(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.stations.list}/${id}`, {
		method: 'PUT',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleUpdateStation: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([STATIONS_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}