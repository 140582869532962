import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export function Budget({ title, diff, trend, sx, value, icon }) {

    return (
        <Card sx={sx}>
            <CardContent>
                <Stack spacing={1}>
                    <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                        <Stack spacing={1}>
                            <Typography color="text.primary" variant="overline">
                                {title}
                            </Typography>
                            <Typography variant="h6" className='poppins-bold'>{value}</Typography>
                        </Stack>
                        <Avatar sx={{ color: 'gray', background: 'none' }}>
                            <i className={icon} />
                        </Avatar>
                    </Stack>
                    
                </Stack>
            </CardContent>
        </Card>
    );
}