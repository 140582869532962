/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
// import DatePicker from '../../Common/DatePickerField';
import { RESTRO_STATUS_FILTER_OPTIONS } from './options';
import { SHOW_RECORDS_OPTIONS } from '../Order/Forms/Options';
import Loader2 from '../Common/Loader2';
import SelectField from '../Common/SelectField';
import TextField from '../Common/Fields';
import { stripStationsList, useStationsList } from '../hooks/stations';

export default function RestaurantFilterForm({ isLoading, handleSubmit, setModal, resetFrom, searchParams }) {
    const { stationsData, stationsIsLoading } = useStationsList();
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        setParams(currentParams)
        setLoading(false)
    }, [searchParams])


    if (loading) {
        return <Loader2 />
    }
    return (
        <Formik
            initialValues={{
                "q": params?.q ?? '',

                "status": params?.status ?? '',
                "limit": params?.limit ?? '20',
                "station": params?.station ?? null,

            }}
            // validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>

                    <div className='row'>
                        <div className="col-md-12">
                            <TextField label='Search' type="text" name='q' placeholder='Search' />
                        </div>
                        
                    </div>
                    <div className='row mb-4'>
                        <div className="col-md-6">
                            <Typography variant='caption'>Station </Typography>
                            <SelectField
                                name='station'
                                options={stationsData?.map(stripStationsList)}
                                value={stationsData?.map(stripStationsList).filter(function (option) {
                                    return option.value === formik.values.station;
                                })}
                                onChange={(e) => formik.setFieldValue('station', e.value)}
                                isLoading={stationsIsLoading}
                            />
                        </div>
                        <div className="col-md-3">
                            <Typography variant='caption'>Status </Typography>
                            <SelectField
                                name='status'
                                options={RESTRO_STATUS_FILTER_OPTIONS}
                                value={RESTRO_STATUS_FILTER_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.status;
                                })}
                                onChange={(e) => formik.setFieldValue('status', e.value)}
                            />
                        </div>
                        <div className="col-md-3">
                            <Typography variant='caption'>Show Records </Typography>
                            <SelectField
                                name='limit'
                                options={SHOW_RECORDS_OPTIONS}
                                value={SHOW_RECORDS_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.limit;
                                })}
                                onChange={(e) => formik.setFieldValue('limit', e.value)}
                            />
                        </div>
                    </div>

                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Filter records
                            </LoadingButton>
                            <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Clear Filter</Button>
                        </div>
                        <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>

                    </div>
                </Form>
            )}
        </Formik>
    )
}
