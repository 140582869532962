import { IconButton } from '@mui/material';
import React from 'react'
import Modal from 'react-modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TransactionForm from './TransactionForm';
import { useTransactionAdd, useTransactionApproveReject } from '../../../components/hooks/vendors';
import toast from 'react-hot-toast';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px'
    },
};

export default function ActionModal({ currentData, modal, setModal }) {
    const { handleApproveRejectTransaction, isUpdatingTransaction } = useTransactionApproveReject()

    const [remarks, setRemarks] = React.useState('');
  
    const handleAction = async (status) => {
        const toastId = toast.loading('Processing...');
        if (!remarks) {
            toast.error('Please enter a remarks.', { id: toastId });
            return;
        }
        const res = await handleApproveRejectTransaction({
            status: status,
            remarks: remarks,
            id: currentData._id
        });
        console.log(res)
        if (res.success) {
            toast.success(`Transaction ${status} successfully.`, { id: toastId })
            setModal(false);
        } else {
            toast.error(res?.error, { id: toastId });
        }
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={refetch}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel={`${currentData?.outlet?.firm_name} - ${currentData?.trnxType === 'CR' ? 'Credit' : 'Debit'}`}
        >
            <div className="container-fluid" style={{ width: '100%' }}>

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3">{`${currentData?.outlet?.firm_name} - ${currentData?.amount?.$numberDecimal} (${currentData?.trnxType === 'CR' ? 'Credit' : 'Debit'}`})</h5>
                    <IconButton onClick={() => setModal(false)}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <p>Purpose: {currentData?.purpose ?? '-'}</p>
                    <p>Reference: {currentData?.reference ?? '-'}</p>
                    <p>Remarks: {currentData?.remarks ?? '-'}</p>
                    <div >
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Remarks:<span className='text-danger'>*</span></label>
                            <div className="col-sm-10">
                                <input type="text" disabled={isUpdatingTransaction} placeholder='Remarks / Reference' className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-10 offset-sm-2 d-flex gap-1">
                                <button className="btn btn-success mr-2" disabled={isUpdatingTransaction} onClick={() => handleAction('approve')} >Approve</button>
                                <button className="btn btn-danger" disabled={isUpdatingTransaction} onClick={() => handleAction('reject')} >Reject</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </Modal>
    )
}
