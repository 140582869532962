import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import axios from 'axios';


const VENDORS_QUERY_KEY = 'vendors';
const VENDOR_QUERY_KEY = 'vendor';
const VENDORS_TRANSACTIONS_KEY = 'vendortransactions'
const VENDORS_PENDING_TRANSACTIONS_KEY = 'pending_vendortransactions'
const DOWNLOAD_REPORTS = 'downloadreports'

// Get Vendors List
export function useVendorsList() {
    const { isLoading, data } = useQuery([VENDORS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.vendors.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { vendorsData: data, vendorsIsLoading: isLoading }
}


export const stripStationsList = (data) => {
    return {
        value: data._id,
        label: `${data.stationName} (${data.stationCode})`
    }
}

export const stripVendorsList = (data) => {
    return {
        value: data._id,
        label: `${data.name}`
    }
}
export const stripCusineList = (data) => {
    return {
        value: data.cusineValue,
        label: `${data.cusineName}`
    }
}
export const stripItemList = (data) => {
    return {
        value: data.food_type,
        label: `${data.food_type}`
    }
}
// Get Vendor Detail
export function useVendorDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [VENDOR_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.vendors.list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { vendorData: data, vendorIsLoading: isLoading, isError, status }
}


// Add Vendor Hook
export function useVendorAdd() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.vendors.add}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            ...values
        })
    }, true), {
        retry: 2,
    })

    return {
        handleAddVendor: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([VENDORS_QUERY_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        addVendorIsLoading: mutation.isLoading,
    }
}




export function useUpdateVendor(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.vendors.updateByid}/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true))
    return {
        handleUpdateVendor: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([VENDORS_QUERY_KEY])
                }
                return data

            } catch (err) {
                return {
                    success: false
                }
            }
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}



export function useVendorsTransaction(id, fromDate, toDate) {
    const { accessToken } = getTokens();
    const { isLoading, data, refetch } = useQuery(
        [VENDORS_TRANSACTIONS_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.transactions.list}/${id}?fromDate=${fromDate}&toDate=${toDate}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }, true
                )
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {

        enabled: false,
        cacheTime: 0,
        staleTime: 0,
    }
    )
    return { vendorstransactionData: data, vendorstransactionIsLoading: isLoading, refetch }
}

const fetchFile = async (id, fromDate, toDate) => {
    const { accessToken } = getTokens();
    const response = await axios.get(`${API_HOST_URL}/api/v1/order/admin/report/${id}?fromDate=${fromDate}&toDate=${toDate}`, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return response.data;
};


export function useDownloadReports(id, fromDate, toDate) {
    return useQuery([DOWNLOAD_REPORTS], {
        enabled: false, // disable the query from automatically running
    }, fetchFile(id, fromDate, toDate),);
}


// Transaction Add Hook
export function useTransactionAdd() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.vendors.transactions.add}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handleAddTransaction: async (values) => {

            // try {
            const res = await mutation.mutateAsync(values);
            const data = await res.json();
            console.log('hooks data -> ', data);
            if (data.success) {
                await queryClient.invalidateQueries([VENDORS_PENDING_TRANSACTIONS_KEY])
            }
            return data;
            // } catch (err) {
            //     console.log(err)
            //     return {
            //         success: false,
            //         error: err
            //     }
            // }
        },
        isAddingTransaction: mutation.isLoading,
    }
}


export function usePendingTransactionsList() {
    const { isLoading, data } = useQuery([VENDORS_PENDING_TRANSACTIONS_KEY], async () => {
        try {
            const { accessToken } = getTokens();

            const res = await fetchJson(
                `${API_HOST_URL}/${endpoints.vendors.transactions.pending}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`

                    },
                }, true);
            return await res.json();
            
        } catch (err) {
            return {
                success: false,
                detail: err,
            };
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { pendingTransactionsData: data, pendingTransactionsIsLoading: isLoading }
}


export function useTransactionApproveReject() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.vendors.transactions.approve_reject}/${values.status}/${values.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            remarks: values.remarks
        })
    }, true), {
        retry: 2,
    })

    return {
        handleApproveRejectTransaction: async (values) => {

            // try {
            const res = await mutation.mutateAsync(values);
            const data = await res.json();
            console.log('hooks data -> ', data);
            if (data.success) {
                await queryClient.invalidateQueries([VENDORS_PENDING_TRANSACTIONS_KEY])
            }
            return data;
            // } catch (err) {
            //     console.log(err)
            //     return {
            //         success: false,
            //         error: err
            //     }
            // }
        },
        isUpdatingTransaction: mutation.isLoading,
    }
}