import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";


const CANCEL_QUERY_KEY = 'cancelreq'

export function useCancelList() {
    const { isLoading, data } = useQuery([CANCEL_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.cancel.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { cancelreqdata: data, cancelIsLoading: isLoading }
}




export function useUpdateCancelReq(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.cancel.update}/${id}`, {
		method: 'PUT',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleUpdateCancel: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([CANCEL_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}