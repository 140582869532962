import React from 'react'
import { useUser } from '../../components/hooks/user';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import MenuComponent from '../../components/MenuComponent/MenuComponent';

const Menu = () => {
    const { user } = useUser();
    return (
      <div>
        <Header user={user}/>
  <Sidenav/>
  <div className="content-wrapper">
<MenuComponent/>
</div>
      </div>
    )
  }

export default Menu