import React from 'react';
import { useField } from 'formik';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { formatDateTime } from '../../utils/core';

const FormikDatePicker = ({ label, ...props }) => {
    var [field, meta, helpers] = useField(props);

    // Convert value to moment object if it's a string
    const fieldValue = field.value ? moment(field.value, 'YYYY-MM-DD') : null;

    // Handle date changes
    const handleChange = (date) => {
        if (date) {
            // helpers.setValue(date); // Format to 'YYYY-MM-DD' for Formik
            helpers.setValue(date ? moment(date).format('YYYY-MM-DD') : '');

        } else {
            helpers.setValue(null);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                slotProps={{ textField: { size: 'small' } }}
                // value={fieldValue} // Ensure value is a moment object
                value={fieldValue ? moment(fieldValue, 'YYYY-MM-DD') : null} // Ensure value is a moment object

                onChange={handleChange}
                label={label}
                inputFormat="YYYY-MM-DD" // Ensure display format is DD-MM-YYYY
                renderInput={(params) => (
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...params}
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default FormikDatePicker;
