import { FormHelperText } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import Select from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '40px',
        height: '40px',
        boxShadow: state.isFocused ? null : null,
        cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
    menuPortal: base => ({ ...base, zIndex: 999999 }),
    singleValue: (provided) => ({
        ...provided,
        color: '#000'
    })
};

export default function SelectField({ ...props }) {
    const [field, meta, helpers] = useField(props);

    const handleChange = (selectedOption) => {
        helpers.setValue(selectedOption ? selectedOption.value : '');
    };

    return (
        <>
            <Select
                isSearchable={true}
                {...field}
                {...props}
                value={props.options ? props.options.find(option => option.value === field.value) : ''}
                onChange={handleChange}
                onBlur={() => helpers.setTouched(true)}
                menuPortalTarget={document.body}
                styles={customStyles}
                menuPosition={'fixed'}
                
            />
            {meta.touched && meta.error && (
                <FormHelperText style={{ fontSize: '12px', color: 'rgb(244, 67, 54)' }}>
                    {meta.error}
                </FormHelperText>
            )}
        </>
    );
}