import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useRestaurantDetail } from "../hooks/restaurants";
import { useItemList, useItemType } from "../hooks/item";
import MenuCard from "./MenuCard";
import Swal from "sweetalert2";
import { loadCartItems } from "../../utils/localStorage";
import { CART_LOCAL_STORE_NAME } from "../../config";
import CartSummary from "../Cart/CartSummary";
import { filterDataNonveg, filterDataVeg } from "../hooks/extraFun";

const MenuComponent = () => {
  const { id } = useParams();
  const [filterFood, setFilterFood] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const [discount, setDiscount] = useState(0);
  const [paymenttext, setPaymentText] = useState("ONLINE");
  const train = queryParams.get("train");
  const pnr = queryParams.get("pnr");
  const vegRef = useRef();
  const allRef = useRef();
  const NonvegRef = useRef();
  const { restaurantData, restaurantIsLoading } = useRestaurantDetail(id);
  const { itemData, itemIsLoading } = useItemList(id);
  const [cartItems, setCartItems] = useState(loadCartItems(id));

  const { itemTypeData, itemTypeIsLoading } = useItemType();
  useEffect(() => {

    localStorage.setItem(CART_LOCAL_STORE_NAME, JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(()=>{
    setFilterFood(itemData);
  },[itemData])
  
  let Debouncing = (e) => {
    allRef.current.click();
    let temp = itemData.filter((el) => {
      return el.itemName.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilterFood(temp);
  };

  const sub_total =
  cartItems &&
  cartItems.items.reduce((accumulator, object) => {
    return (
      parseInt(accumulator) +
      parseInt(object.yrPrice) * parseInt(object.quantity)
    );
  }, 0);


const gst_amount = Math.round(sub_total * 0.05 * 100) / 100;
const total_amount = sub_total + gst_amount;

let payable_amount = total_amount - discount;
// console.log(gst_amount)



const changePayment = (text) => {
  if (text === "CASH_ON_DELIVERY") {
   
    setDiscount(total_amount / 10);
   
  } else {

    payable_amount = total_amount - discount;
    setDiscount(0);
    
  }
};

  const handleAddToCartUpdate = (currentItem) => {
    setDiscount(0);
    if (cartItems.restaurantId === currentItem.resturant) {
      const objIndex = cartItems.items.findIndex(
        (obj) => obj.item == currentItem._id
      );

      if (objIndex === -1) {
        // Add to cart
       
        let item = {
          item: currentItem._id,
          itemName: currentItem.itemName,
          quantity: 1,
          yrPrice: currentItem.basePrice.$numberDecimal,
        };
        setCartItems({
          restaurantId: cartItems.restaurantId,
          items: [...cartItems.items, item],
        });
      } else {
        // Update cart data
        let cartData = { ...cartItems };
        let item = { ...cartData.items[objIndex] };
        item.quantity += 1;
        cartData.items[objIndex] = item;
        setCartItems(cartData);
      }
    } else {
      // handle if user changes the restaurant
      Swal.fire({
        title: "Order from another restaurant?",
        text: "You already added a different restaurant food item, You can add only on Restaurant food at a time.",
        showCancelButton: true,
        confirmButtonText: "Remove existing food from cart and add this",
      }).then((result) => {
        if (result.isConfirmed) {
          let item = {
            item: currentItem._id,
            itemName: currentItem.itemName,
            quantity: 1,
            yrPrice: currentItem.basePrice.$numberDecimal,
          };
          setCartItems({ restaurantId: id, items: [item] }); // Remove all items from cart and adding the current item to the cart
          localStorage.setItem(
            CART_LOCAL_STORE_NAME,
            JSON.stringify(cartItems)
          ); // Saving to localStorage
          Swal.fire(
            "Restaurant changed!",
            `Now you are ordering from ${restaurantData.name}`,
            "success"
          );
        }
      });
    }
  };



  const handleRemoveFromCart = (currentItem) => {
    // Check if already added to cart
    setDiscount(0);
    // console.log(currentItem)
    const objIndex = cartItems.items.findIndex(
      (obj) => obj.item == currentItem.item
    );
    let cartData = { ...cartItems };
    let item = { ...cartData.items[objIndex] };
    if (item.quantity <= 1) {
      cartData.items.splice(objIndex, 1);
    } else {
      item.quantity -= 1;
      cartData.items[objIndex] = item;
    }
    setCartItems(cartData);

    localStorage.setItem(CART_LOCAL_STORE_NAME, JSON.stringify(cartItems));
  };

  return (
    <div className="container">
      <section className="row">
        <div className="col-12 px-0 py-3">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="shadow-sm bg-white rounded border px-4">
                  <div className="row">
                    <div
                      className="col-12 px-0 fs-18 fw-500 py-3 "
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Menus ({filterFood && filterFood.length})
                      <div>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitches"
                            ref={vegRef}
                            onChange={(e) => filterDataVeg(e,allRef,NonvegRef,vegRef,itemData,setFilterFood)}
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitches"
                          >
                            Veg
                          </label>
                        </div>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitches2"
                            ref={NonvegRef}
                            onChange={(e) => filterDataNonveg(e,allRef,vegRef,NonvegRef,itemData,setFilterFood) }
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitches2"
                          >
                            Non-Veg
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div style={{ width: "100%" }}>
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href={"#bootstrap-home"}
                            aria-controls="home"
                            role="tab"
                            data-toggle="tab"
                            ref={allRef}
                            onClick={() => setFilterFood(itemData)}
                          >
                            All
                          </a>
                        </li>
                        {itemTypeData
                          ? itemTypeData.map((e, i) => {
                              return (
                                <li className="nav-item" key={i + 1}>
                                  <a
                                    className="nav-link"
                                    href={"#bootstrap" + e.food_type}
                                    aria-controls="home"
                                    role="tab"
                                    data-toggle="tab"
                                    onClick={() => {
                                      NonvegRef.current.checked = false;
                                      vegRef.current.checked = false;
                                      const filter = itemData.filter(
                                        (item) => item.itemType === e.food_type
                                      );
                                      setFilterFood(filter);
                                    }}
                                  >
                                    {e.food_type}
                                  </a>
                                </li>
                              );
                            })
                          : "loading..."}
                      </ul>

                      <div className="card ">
                        <div className="card-body">
                          <div className="searchitemDiv">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Item.."
                              onChange={Debouncing}
                            />
                          </div>

                          <div
                            className="overflow-auto"
                            style={{ height: "700px" }}
                          >
                            <div className="tab-content">
                              <div
                                role="tablist"
                                aria-labelledby="home-tab"
                                className="tab-pane fade show active"
                                id="bootstrap-home"
                              >
                                {filterFood ? (
                                    filterFood.length > 0 ? (
                                      filterFood.map((val, i) => (
                                        <MenuCard
                                          key={i}
                                          data={val}
                                          handleAddToCart={
                                            handleAddToCartUpdate
                                          }
                                        //   isLoading={!isReady}
                                        />
                                      ))
                                    ) : (
                                      <div>No Item Found!!</div>
                                    )
                                  ) : (
                                    "loading..."
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
                  <CartSummary
                    cartItems={cartItems}
                    handleRemoveFromCart={handleRemoveFromCart}
                    pnrNumber={pnr}
                    item={cartItems}
                    setPaymentText={setPaymentText}
                    paymenttext={paymenttext}
                    discount={Math.round(discount * 100) / 100}
                    setDiscount={setDiscount}
                    sub_total={sub_total}
                    gst_amount={gst_amount}
                 restId = {id}
                    total_amount={Math.round(total_amount * 100) / 100}
                    changePayment={changePayment}
                    payable_amount={Math.round(payable_amount * 100) / 100}
                  />
                
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MenuComponent;
