import React, { useState } from 'react'
import { useUser } from '../../components/hooks/user';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { usePendingTransactionsList, useVendorsTransaction } from "../../components/hooks/vendors"
import AddTransactionModal from './Comp/AddTransactionModal';
import PendingTransactionsTable from './Comp/PendingTransactionsTable';


const VendorTransactionsPage = () => {
    const { user } = useUser();
    const [addTransactionModal, setAddTransactionModal] = useState(false)
    const { pendingTransactionsData, pendingTransactionsIsLoading } = usePendingTransactionsList();

    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper">
                <div className="card pd-20 pd-sm-20 bg-light">
                    <div className="d-flex justify-content-between align-items-center ">
                        <div>
                            <h6 className="card-body-title text-primary">Vendors Transactions</h6>
                            <p className="">
                                Add Transactions
                            </p>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={() => setAddTransactionModal(true)}>Add Transaction</button>
                            <AddTransactionModal modal={addTransactionModal} setModal={setAddTransactionModal} />
                        </div>
                    </div>
                </div>
                <div className="table-wrapper">
                    <PendingTransactionsTable
                        data={pendingTransactionsData?.data ?? []}
                        isLoading={pendingTransactionsIsLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default VendorTransactionsPage 
