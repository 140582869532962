import React, { useEffect } from 'react';
import Sidenav from '../../components/Sidenav/Sidenav';
import Header from '../../components/Header/Header';
import { useUser } from '../../components/hooks/user';
import CreateInvoiceForm from './Comp/CreateInvoiceForm';
import GetInvoiceForm from './Comp/GetInvoiceForm';
import { useGenerateInvoice, useInvoiceData } from '../../components/hooks/invoices';
import InvoicePreview from './Comp/InvoicePreview';
import { useDebouncedCallback } from 'use-debounce';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function CreateInvoicePage() {
    const navigate = useNavigate();
    const { user } = useUser();
    const [invoice, setInvoice] = React.useState({});

    const { getInvoiceData, isLoadingInvoice } = useInvoiceData();
    const { handleCreateInvoice, isInvoiceGenerating } = useGenerateInvoice();

    const handleGetInvoice = async (values) => {
        if (!values.resturant || !values.fromDate || !values.toDate) {
            return toast.error('All fields are required');
        }

        const toastId = toast.loading('Processing...');
        const param = `/${values.resturant}?fromDate=${values.fromDate}&toDate=${values.toDate}`;
        const res = await getInvoiceData(param); // Get Invoice Data

        if (res.success) {
            setInvoice({ ...res.data, resturant: values.resturant });
            toast.success('Data fetched successfully', { id: toastId });
        } else {
            toast.error(res?.error || 'Error occurred', { id: toastId });
        }
    };

    const handleModifyInvoice = async (values) => {
        if (!values.invDate) {
            return toast.error('Invoice Date is required');
        }

        setInvoice({ ...invoice, ...values });
        toast.success('Modified Successfully');
    };

    const handleGenerateInvoice = async () => {
        const toastId = toast.loading('Processing...');
        const res = await handleCreateInvoice({
            ...invoice,
            commission: invoice?.totalCommission,
            id: invoice.resturant,
        });

        if (res.success) {
            navigate('/invoices');
            toast.success('Successfully generated', { id: toastId });
        } else {
            toast.error(res?.error || 'Error occurred', { id: toastId });
        }
    };


    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper mt-2">
                <div className="mx-2 pt-4 mb-3">
                    <h3 className="text-center">Create New Invoice</h3>
                </div>
                <div className="mx-2 px-4 py-2">
                    <div className="d-flex justify-content-center">
                        <div className="col-md-5 p-4 card mr-2">
                            <GetInvoiceForm
                                invoice={invoice}
                                isLoading={false}
                                handleSubmit={handleGetInvoice}
                            />
                        </div>
                        {Object.keys(invoice).length > 0 && (
                            <div className="col-md-5 p-4 card">
                                <CreateInvoiceForm handleSubmit={handleModifyInvoice} />
                            </div>
                        )}
                    </div>

                    {Object.keys(invoice).length > 0 && (
                        <div>
                            <div className="card p-4">
                                <div className="d-flex justify-content-between">
                                    <h3 className="text-center text-danger">Preview</h3>
                                    <div>
                                        <LoadingButton
                                            loading={isInvoiceGenerating}
                                            variant="contained"
                                            disableElevation
                                            onClick={handleGenerateInvoice}
                                        >
                                            Generate Invoice
                                        </LoadingButton>
                                    </div>
                                </div>
                                <InvoicePreview data={invoice} isLoading={isLoadingInvoice} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
