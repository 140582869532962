import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes/AppRoutes';
import Modal from 'react-modal';
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import './App.css';
import './fonts.css';
import { Toaster } from 'react-hot-toast';

Modal.setAppElement('#root');
function App() {
    return (
        <div className="App">
            <AppRoutes />
            <Toaster position="top-right" gutter={8} />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default App;
