import React, { useState, useEffect } from 'react'
import DataTable from '../../components/Common/DataTableBase';
import Loader from '../../components/Loader';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import FilterComponent from '../../components/Common/FilterComponent';
import { OutlinedButton } from '../../components/Common/Button';
import { useRestaurantsList, useRestaurantsPaginatedList } from '../hooks/restaurants';
import { useDebouncedCallback } from "use-debounce";
import { restaurantRows } from './Skeleton';
import RestaurantTopCounter from './TopCounter';
import FilterModal from './FilterModal';
import Skeleton from 'react-loading-skeleton';


export default function ResturantComp() {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [enableApi, setAPI] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const { restaurantsData, restaurantsIsLoading, handleParam } = useRestaurantsPaginatedList('', enableApi);

    // const [filterText, setFilterText] = React.useState("");
    // const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


    // const subHeaderComponent = React.useMemo(() => {
    //     const handleClear = () => {
    //         if (filterText) {
    //             setResetPaginationToggle(!resetPaginationToggle);
    //             setFilterText("");
    //         }
    //     };

    //     return (
    //         <FilterComponent
    //             onFilter={e => setFilterText(e.target.value)}
    //             onClear={handleClear}
    //             filterText={filterText}
    //         />
    //     );
    // }, [filterText, resetPaginationToggle]);
    const [filterModal, setFilterModal] = React.useState(false);
    const subHeaderComponent = React.useMemo(() => {
        return (
            <div className="d-flex">
                <button className="btn btn-dark btn-sm d-flex align-items-center mr-1" onClick={() => setFilterModal(true)}>
                    <i className="material-icons">filter_alt</i> <span className="ml-1">Filter</span>
                </button>
            </div>
        );
    }, []);
    const columns = [

        {
            name: 'Name',
            wrap: true,
            selector: row => <>
                <Link to={`/restaurants/${row._id}`}>{row?.name}</Link>
                <p>{row?.contact_details?.contact_number}</p>
            </>,
            maxWidth: "50%"
        },
        {
            name: 'Station Name',
            // selector: row => row.station[0].stationName,
            selector: row => (
                <>
                    {row?.station?.map((e, i) => (
                        <div key={i}>
                            <small>
                                <span className='text-dark mb-0 p-0'>{e.stationName}</span> {e?.stationCode ? e.stationCode : e.stationCode}
                            </small>
                        </div>
                    ))}
                </>
            ),
            // sortable: true,
            maxWidth: "30%"
        },
        {
            name: 'Balance',
            selector: row => row?.wallet?.$numberDecimal ?? 0,
            selector: row => <>
                <p className={`${row?.wallet?.$numberDecimal < 0 ? 'text-danger' : 'text-success'} font-weight-bold text-center`}>
                    {row?.wallet?.$numberDecimal}
                </p>
            </>,
            maxWidth: "10%"
        },

        {
            name: 'Status',
            selector: row => row?.status === "Active" ? <span className='badge badge-success'>Active</span> : <span className='badge badge-danger'>Closed</span>,
            // sortable: true,
            maxWidth: "10%"
        },

    ];

    // Filter Data 
    const loadInitData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ``;
        const limit = parseInt(currentParams?.limit ?? '20');
        params = new URLSearchParams({ ...currentParams, limit }).toString();
        setAPI(true);
        params && handleParam(`?${params}`)
    }, 300);

    useEffect(() => {
        loadInitData();
    }, [loadInitData, searchParams]);

    useEffect(() => {
        // Handling the loa`ding state
        if (restaurantsData && !restaurantsIsLoading && restaurantsData?.success) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [restaurantsData && !restaurantsIsLoading]);

    // Filter data
    // const filteredItems = restaurantsData.length > 0 ? restaurantsData.filter(
    //     item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    // ) : [];
    return (
        <>
            <div className="content-wrapper mt-2">

                <div className="container-fluid">


                    <div className="card p-4" >
                        <h6 className="card-body-title">All Restaurants</h6>
                        <p className="">
                            {isLoading ? <Skeleton width={280} /> : restaurantsData?.success ? `found total of ${restaurantsData?.totalCount ?? 0} restaurants in the list.` : <Skeleton width={280} />}
                        </p>
                        {/* <p className="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p> */}

                        <RestaurantTopCounter restaurantsData={restaurantsData ?? {}} loading={isLoading} />

                        <OutlinedButton className='col-2' label='Add new restaurant' onClick={() => navigate('/restaurants/add')} />
                        <div className="">

                            <div className="table-wrapper">

                                {
                                    <DataTable
                                        columns={columns}
                                        // data={restaurantRows}
                                        data={isLoading ? restaurantRows : restaurantsData?.data ?? []}
                                        direction="auto"
                                        fixedHeaderScrollHeight="600px"
                                        pagination
                                        responsive
                                        // progressPending={isLoading}
                                        // conditionalRowStyles={conditionalRowStyles}

                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        subHeaderComponent={subHeaderComponent}
                                        subHeader
                                    />
                                }
                            </div>
                            {filterModal && <FilterModal
                                modal={filterModal}
                                setModal={setFilterModal}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                            />}
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
