import { Box, Card, CardContent, Typography } from "@mui/material";
import moment from "moment/moment";

export default function Pagedetail({page}){
 return <>
<Card variant="outlined"  sx={{ minWidth: 400 }}>
<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3 }} >
        <div className="col-6">
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Title
          </Typography>
          <Typography component="div" variant="p">
            {page? page.title : ""}
          </Typography>
        </div>


        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div" >
          Slug
          </Typography>
          <Typography component="div" variant="p" width={"100px"}>
           {page? page.slug:""}
          </Typography>
          
        </CardContent>
      </Box>

<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3  }}>

        <div className="col-6">
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Head Tags
          </Typography>
          <Typography component="div" variant="p">
            {page? page.headTags : ""}
          </Typography>
        </div>

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          created At
          </Typography>
          <Typography component="div" variant="p">
            {page ? moment(page.createdAt).format("YYYY/MM/DD"):""}
          </Typography>
        </CardContent>
      </Box>

<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
      
<div className="col-12">
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Content
          </Typography>
          <Typography component="div" variant="p">
            {page ? <div  dangerouslySetInnerHTML={{ __html: page.content }}>

</div> : ""}
            
           
          </Typography>
        </div>
     
      </Box>

<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
       
        <CardContent sx={{ flex: '1 0 auto' }}>
         
          <button
                  className="btn btn-primary"
               
                  data-dismiss="modal"
                >Ok  <i className="fa fa-check"></i>
                </button>
        </CardContent>

      </Box>
</Card>

 </>
}