import React from 'react'
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useUser } from '../../components/hooks/user';
import PageCont from '../../components/PagesComponent/PageContent';

const DynamicPage = () => {
    const { user } = useUser();

    return (
      <div>
        <Header user={user} />
        <Sidenav />
        <div className="content-wrapper">
<PageCont/>
        </div>
      </div>
  )
}

export default DynamicPage