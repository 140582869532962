/* eslint-disable jsx-a11y/anchor-is-valid */
import { capitalize } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/user";
import { ResetPasswordModal } from "../model/ResetPasswordModal";
import { useState } from "react";

export default function Header({ user }) {
    const [resetModal, setResetModal] = useState(false)
    // console.log(user)
    let navigate = useNavigate();

    const logoutAccount = useLogout();
    const handleLogout = async () => {
        const is_logged_out = await logoutAccount();
        is_logged_out && navigate(`/account/login`);
    }
    return <>
        <nav className="main-header navbar navbar-expand navbar-dark">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></a>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Navbar Search */}
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                    </a>
                </li>
                <li className="nav-item">
                    <div className="am-header-right">
                        <div className="dropdown dropdown-profile">
                            <a href="#" className="nav-link nav-link-profile d-flex align-items-center" data-bs-toggle="dropdown">
                                <img src="/assets/img/profile2.png" title={user?.role?.toUpperCase() ?? ''} className="wd-32 rounded-circle" alt="img" />
                                <span className="logged-name d-flex align-items-center ms-2">
                                    <span className="hidden-xs-down">{capitalize(user.name)}</span>
                                    <i className="material-icons text-light ms-1">expand_more</i>
                                </span>
                            </a>
                            <div className="dropdown-menu wd-200 bg-secondary rounded">
                                <ul className="list-unstyled user-profile-nav">
                                    <li >
                                        <a style={{ color: "white", cursor: "pointer" }} className="rounded" onClick={() => setResetModal(true)}>
                                            <span className="material-symbols-outlined">encrypted</span> Change Password
                                        </a>
                                    </li>
                                    <li style={{ color: "white", cursor: "pointer" }} >
                                        <a onClick={handleLogout} className="rounded">
                                            <span className="material-symbols-outlined">logout</span> Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <ResetPasswordModal openModal={resetModal} setOpenModal={setResetModal} user={user} />

            </ul>
        </nav>


    </>
}