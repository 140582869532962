import React from 'react'
import { useRestaurantDetail } from '../../components/hooks/restaurants';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Loader from '../../components/Loader';
import RestaurantInformation from '../../components/ResturantContent/RestaurantInformation';
import Header from '../../components/Header/Header';
import { useUser } from '../../components/hooks/user';
import Sidenav from '../../components/Sidenav/Sidenav';
import RestroDetailComp from './Comp/RestroDetailComp';

const RestaurantDetailPage = () => {
    const { id } = useParams();
    const { restaurantData, restaurantIsLoading } = useRestaurantDetail(id);
    const { user } = useUser()
    

    return (
        <>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper">

                {/* <Grid container > */}
                    {/* <Grid item > */}
                        <RestroDetailComp data={restaurantData?.data} isLoading={restaurantIsLoading} />
                        {restaurantIsLoading ? <Loader /> : <RestaurantInformation data={restaurantData?.data} />}
                    {/* </Grid> */}
                {/* </Grid> */}
            </div>



        </>
    )
}

export default RestaurantDetailPage