import React, { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { useUser } from "../hooks/user";
import TextField from "../Common/MuiTextField";
import SubmitButton from "../Common/Button";
import { useUpdateCancelReq } from "../hooks/cancelreq";

export default function UpdateCancelReqForm({ data, changeState }) {
    const closeItemModal = useRef();

    const { user } = useUser();
    const { handleUpdateCancel } = useUpdateCancelReq(data._id);

    const updateitem = async (data) => {
        const res = await handleUpdateCancel(data);
        if (res.success) {
            toast.success("Canceled Request Updated");
            closeItemModal.current.click();
        } else {
            toast.error(res.error);
        }
    };

    const validate = Yup.object({
        status: Yup.string().required("Select Status"),
        comment: Yup.string().required("Enter Remarks"),
    });

    return (
        <Formik
            initialValues={{
                status: data.status,
                orderId: data.orderId,
                comment: data.comment,
                pnr: data.pnr,
                updatedBy: user.name,
            }}
            validationSchema={validate}
            onSubmit={updateitem}
            enableReinitialize
        >
            {formik => (
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Update Cancel Request Form
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={changeState}
                            ref={closeItemModal}
                        ></button>
                    </div>

                    <div className="modal-body">
                        <Form className="form-row" onSubmit={formik.handleSubmit}>
                            <div className="form-group col-md-6">
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={data.name}
                                    disabled
                                    placeholder="Name"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <TextField
                                    label="Order Id"
                                    name="orderId"
                                    value={data.orderId}
                                    disabled
                                    placeholder="Order Id"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="form-group col-md-6 item-div">
                                <Field as="select" name="status" className="custom-select mr-sm-2">
                                    <option value="new">New</option>
                                    <option value="approved">Approved</option>
                                    <option value="active">Active</option>
                                    <option value="reject">Reject</option>
                                </Field>
                            </div>
                            <div className="form-group col-md-6">
                                <textarea
                                    type="text"
                                    name="comment"
                                    value={formik.values.comment}
                                    onChange={formik.handleChange}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <TextField
                                    label="PNR"
                                    name="pnr"
                                    value={data.pnr}
                                    disabled
                                    placeholder="PNR"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <TextField
                                    label="Mobile"
                                    name="mobile"
                                    value={data.mobile}
                                    disabled
                                    placeholder="Mobile"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={changeState}
                                    ref={closeItemModal}
                                >
                                    Close
                                </button>
                                <SubmitButton fullWidth label="Update" />
                            </div>
                            <div>
                                <span className="form-error">
                                    {formik.errors.status && formik.touched.status ? formik.errors.status : null}
                                </span>
                            </div>
                            <div>
                                <span className="form-error">
                                    {formik.errors.remarks && formik.touched.remarks ? formik.errors.remarks : null}
                                </span>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Formik>
    );
}