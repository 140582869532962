import { Formik } from "formik";
import JoditEditor from "jodit-react";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import TextField from "../../components/Common/MuiTextField";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useBlogDetail, useUpdateBlog } from "../hooks/blog";
import { useNavigate, useParams } from "react-router-dom";
import Sidenav from '../Sidenav/Sidenav';
import Header from '../Header/Header';
import { useUser } from '../hooks/user';
import { usePageDetail, useUpdatePage } from "../hooks/pages";

const UpdatePage = () => {
    const editor = useRef(null);
    const [selected, setSelected] = useState([]);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [mulitimg, setMultiimg] = useState([]);
    const file1 = useRef()
    const file2ref = useRef()
    const file3 = useRef()
    const {slug} = useParams()
    const {id} = useParams()


    const navigate = useNavigate()
     const {pageData,pageIsLoading}   = usePageDetail(slug)

     const { user } = useUser();
    const {handleUpdatePage,handleUpdateIsLoading}= useUpdatePage(id);
   
    const validate = Yup.object({
      title: Yup.string().required("Enter title."),
      content: Yup.string().required("Enter content."),
      headTags: Yup.string().required("Enter Head Tags."),
    
    });
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };
    const handleMulriChange = (event) => {
      setMultiimg(event.target.files);
    };
    const handleFile2Change = (event) => {
      setFile2(event.target.files[0]);
    };
  
    const handleSubmit = async(val, { resetForm }) => {
      const {
        title,
      content,
      headTags,

      } = val;
  
      const formData = new FormData();
  
      for (let i = 0; i < mulitimg.length; i++) {
        formData.append("images", mulitimg[i]);
      }
  
      formData.append("title", title);
      formData.append("twitterImage", file);
      formData.append("ogImage", file2);
      formData.append("content", content);
      formData.append("headTags", headTags);
  
   
      const res = await handleUpdatePage(formData);
     
      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
      if (res.success) {
        editor.current.value = ""
            file1.current.value = ""
            file3.current.value = ""
            file2ref.current.value = ""
            setSelected([])
            resetForm();
            toast.success("Page Updated Successfully!!");
            navigate('/pages')
        
      } else {
        toast.error(res.message);
      }
   
       
    }
        
       
  
    const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    // buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true
    },
    width: 800,
    height: 842
  };
  
    return (
      <>
             <Header user={user}/>
  <Sidenav/>
  <div className="content-wrapper">

 

  
        <Formik
          initialValues={{
            title:  pageData?  pageData.data!=undefined? pageData.data.title:"":"",
            content:pageData? pageData.data!=undefined? pageData.data.content:"":"",
            // images: "C:\\fakepath\\Screenshot (10).png",
            headTags: pageData? pageData.data!=undefined? pageData.data.headTags:"":"",
            slug: pageData?pageData.data!=undefined?  pageData.data.slug:"":"",
           
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
          // onSubmit={(val)=>console.log(val)}
          enableReinitialize
        >
          {(formik) => (
            <div style={{ width: "70%", margin: "auto" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update page
                </h5>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={formik.handleSubmit}
                  className="form-row"
                  id="form"
                >
                  <div className="form-group col-md-12">
                    <TextField
                      label="Title"
                      name="title"
                      placeholder="Title"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Slug"
                      name="slug"
                      placeholder="Slug"
                      sx={{ mb: 3 }}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Head Tags"
                      name="headTags"
                      placeholder="Head Tags"
                      sx={{ mb: 3 }}
                    />
                  </div>
             
  
  
                  <div className="form-group col-md-4">
                    <label>Twitter Image</label>
  
                    <input
                      type="file"
                      name="twitterImage"
                      onChange={handleFile2Change}
                      ref={file1}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Fb Image</label>
  
                    <input
                      type="file"
                      ref={file2ref}
                      name="ogImage"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Images</label>
                    {/* nsanmnmasds */}
                    <input
                      type="file"
                      name="images"
                      ref={file3}
                      onChange={handleMulriChange}
                      multiple
                    />
                  </div>
  
                  <div className="form-group col-md-12" style={{ maxWidth: editorConfig.width, margin: "0 auto" }}>
                    <JoditEditor
                      ref={editor}
                      config={editorConfig}
                      value={formik.values.content}
                      tabIndex={1}
                      onChange={(newContent) =>
                        formik.setFieldValue("content", newContent)
                      }
                    />
                  </div>
  
                  <div className="modal-footer">
                    <button className="btn btn-success" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Formik>
    </div>
    </>
    )
}

export default UpdatePage