import React from 'react'
import DataTable from 'react-data-table-component'
import moment from "moment";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import FilterOrdersModal from './FilterOrdersModal';

export default function OrdersTable({ data, isLoading, searchParams, setSearchParams }) {
    const columns = [
        {
            name: "Order ID",
            selector: (row) => row._id,
            cell: (row) => <Link to={`/orders/${row._id}`}>{row.orderId}</Link>,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <span> {row.deliveryDetails.passengerName} </span>
                    <span>{row.mobile}</span>
                </div>
            ),
        },

        {
            name: "Station Name",
            selector: (row) => <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span> {row.deliveryDetails.station} </span>
                <span>{row.deliveryDetails.stationCode}</span>
            </div>,
            // sortable: true,
            minWidth: "150px",
        },

        {
            name: "Net Total",
            selector: (row) => <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span>   {row.totalAmount ? row.totalAmount.$numberDecimal : ""} Rs. </span>
                <span>
                    {row.paymentType === "CASH_ON_DELIVERY" ? (
                        <span className="badge badge-danger">COD</span>
                    ) : (
                        <span className="badge badge-success">ONLINE</span>
                    )}
                </span>
            </div>,
            // sortable: true,
        },

        ,
        {
            name: "Resturant",
            selector: (row) => row.resturant ? row.resturant.name : "",
            sortable: true,
            minWidth: "180px",
        },
        {
            name: "Order Src",
            selector: (row) => <div className='' style={{ display: 'flex', flexDirection: 'column', }}>
                <span>{row?.orderSource?.toUpperCase()}</span>
                {row?.irctcOrderId && <span title='IRCTC ORDER ID'>{row?.irctcOrderId}</span>}
            </div>,
            minWidth: "100px",
        },
        {
            name: "Ordered at",
            selector: (row) => moment(row.createdAt).format("DD-MM-YYYY hh:mm A"),
            sortable: true,
            minWidth: "180px",
        },
        {
            name: "Order Status",
            selector: (row) => {
                const statusClasses = {
                    "ORDER_PLACED": "badge-warning",
                    "ODRDER_PREPARING": "badge-warning",
                    "ORDER_ACCEPTED": "badge-primary",
                    "ORDER_READY": "badge-success",
                    "ORDER_DELIVERED": "badge-success",
                    "ORDER_CONFIRMED": "badge-success",
                };

                const statusText = typeof row.status === 'string' ? row.status.replace(/_/g, ' ') : 'UNKNOWN STATUS';
                const statusClass = statusClasses[row.status] || "badge-danger";

                return (
                    <span className="float-right">
                        <span className={`badge ${statusClass}`}>{statusText ? statusText.replace('_', ' ') : statusText}</span>
                    </span>
                );
            },
            sortable: true,
        }
    ];


    // rows skeleton
    const skeletonRows = Array.from({ length: 20 }, (_, index) => ({
        _id: `order-${index}`,
        orderId: <Skeleton width={100} />,
        deliveryDetails: {
            passengerName: <Skeleton width={150} />,
            station: <Skeleton width={100} />,
            stationCode: <Skeleton width={50} />,
        },
        mobile: <Skeleton width={100} />,
        totalAmount: { $numberDecimal: <Skeleton width={50} /> },
        paymentType: <Skeleton width={50} />,
        restaurant: <Skeleton width={100} />,
        createdAt: <Skeleton width={100} />,
        status: <Skeleton width={80} />,
    }));

    // header modal
    const [ordersFilterModal, setOrdersFilterModal] = React.useState(false);

    const subHeaderComponent = React.useMemo(() => {
        return (
            <div className="d-flex">
                <button className="btn btn-dark btn-sm d-flex align-items-center mr-1" onClick={() => setOrdersFilterModal(true)}>
                    <i className="material-icons">filter_alt</i> <span className="ml-1">Filter</span>
                </button>
            </div>
        );
    }, []);
    return (
        <div>   
            <DataTable
                columns={columns}
                data={isLoading ? skeletonRows : data}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                pagination
                responsive
                // progressPending={isLoading}
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
                subHeader
            />


            {/* Modals */}
            <FilterOrdersModal
                modal={ordersFilterModal}
                setModal={setOrdersFilterModal}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
        </div>
    )
}
