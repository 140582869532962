import React, { useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import FilterComponent from '../Common/FilterComponent';
import Loader from '../Loader';
import { useBlogList } from '../hooks/blog';
import ViewBlogModel from '../model/ViewBlogModel';



const BlogContent = () => {
    const {blogData,blogIsLoading} = useBlogList()
    const [filterText, setFilterText] =useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [blog,setBlog] = useState(null)

    // console.log(blogData)


    const subHeaderComponent = useMemo(() => {
       const handleClear = () => {
           if (filterText) {
               setResetPaginationToggle(!resetPaginationToggle);
               setFilterText("");
           }
       };

       return (
           <FilterComponent
               onFilter={e => setFilterText(e.target.value)}
               onClear={handleClear}
               filterText={filterText}
           />
       );
   }, [filterText, resetPaginationToggle]);
// console.log(blogData)

   const columns = [
       {
           name: 'Image',
           selector: row => <div>
           <img  src= {`https://backapi.yatrirestro.com/${row.ogImage}`} crossorigin="anonymous" alt={"img"} width={"100px"} height={"80px"}/>
                    </div>,
//            cell: row => <div>
// <img  src= {`https://backapi.yatrirestro.com/uploads/1693287769473.png`} alt={"img"} width={"100px"} height={"80px"}/>
//          </div>,
           sortable: true,
       },
       {
           name: 'Title',
           selector: row => row.title,
           sortable :true
       },
   
       {
           name: 'Offer Price',
           selector: row => row.tags.map((e,i)=>{
            return <span>{e}</span>
           })
       },
      
       {
           name: 'Published',
           selector: row => row.isPublished ?<span className="badge badge-success">YES</span> : <span className="badge badge-danger">NO</span>
       },
       {
           name: 'View',
           selector: row => <button className='btn btn-primary' data-toggle="modal"
           data-target="#exampleModalview2"
           data-whatever="@getbootstrap"
onClick={()=>{
    setBlog(row)
}}
         >View</button>
       },
       {
           name: 'Update',
           selector: row =><Link to={`/update-blog/${row._id}`} className='btn btn-primary'>Update</Link>
       },
   ];

   if (blogIsLoading) {
       return <Loader />
   }



      const filteredItems = blogData.length>0 ? blogData.filter(
       item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
   ):[];


    
   return <>
      <ViewBlogModel
            id="exampleModalview2"
      
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            blog={blog}
          />
<div className="container" style={{padding : "20px"}}>
<div className="text-right">
   
   <Link  to={"/addblog"}  className="btn btn-dark">Add Blog <i className="fa fa-plus"></i></Link>  
              </div>
   <div className="table-wrapper">
<h3 className="text-center" style={{ padding: "5px" }}>
                Blog List
               </h3>
               {
                   <DataTable
                   columns={columns}
                   data={filteredItems}
                   
                   direction="auto"
                   fixedHeaderScrollHeight="300px"
                   pagination
                       responsive
                       progressPending={blogIsLoading}
                       // conditionalRowStyles={conditionalRowStyles}
                       
                       subHeaderAlign="right"
                       subHeaderWrap
                       subHeaderComponent={subHeaderComponent}
                       subHeader
                       />
                   }
           </div>
                   </div> 
                       
  
   </>
}

export default BlogContent;