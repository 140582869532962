import { useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getTokens } from "../../utils/core";
import { endpoints } from "../../config/endpoints";
import { API_HOST_URL } from "../../config";
import { toast } from "react-toastify";

export default function DeleteItemModel({ Itemid, setRender, render }) {
    const closeref = useRef();

    const queryClient = useQueryClient();
    const DeleteItem = async (id) => {
        const { accessToken } = getTokens();
        await fetch(`${API_HOST_URL}/${endpoints.item.update}/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
    };

    const deleteMutation = useMutation(DeleteItem, {
        onSuccess: () => {
            queryClient.invalidateQueries("items");
            toast.success("Item Deleted");
        },
    });

    const handleDelete = (itemId) => {
        deleteMutation.mutate(itemId);
    };

    return (
        <div
            className="modal fade"
            id="exampleModaldelete"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Delete Item</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <p>Are you sure you want to delete?</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            ref={closeref}
                            aria-label="Close"
                        >
                            No
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                handleDelete(Itemid);
                                closeref.current.click();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}