import UpdateResturantForm from "../form/UpdateResturantForm";
import React from 'react'
import Modal from 'react-modal';


export default function UpdateRestaurant({ data, modalIsOpen, setModalIsOpen }) {
    const customStyles = {
        content: {

            top: '8%',
            height: "90%",
            left: '12%',
            width: '75%',
            background: "none",
            border: "none"
        },


    };


    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            contentLabel="Update Resturant"
        >
            <div className="content-wrapper">

                <UpdateResturantForm data={data} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />

            </div>

        </Modal>
    )
}
