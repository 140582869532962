import React, { useState } from "react";
import { usePnrStopage, useTrainStopage } from "../hooks/find";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { API_HOST_URL } from "../../config";
import VisibleRestCard from "../RestCard/VisibleRestCard";
// import {getRestro} from '../hooks/extraFun'
import { fetchJson } from "../../libs/api";

const BookOrderComponent = () => {
  const [inputQ, setInputQ] = useState("");
  const [serachType, setSearchType] = useState("PNR");
  const [expanded, setExpanded] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [stopage, setStopage] = useState(null);
  const [state, setState] = useState(null);
  const { pnrStopage, pnrIsLoading } = usePnrStopage(
    serachType === "PNR" && inputQ
  );
  const { trainStopage, trainIsLoading } = useTrainStopage(
    serachType === "TRAIN" && inputQ
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // getRestro
  const getRestro = async (id) => {
    try {
      const response = await fetchJson(
        `${API_HOST_URL}/api/v1/resturant/station/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
        true
      );

      const data = await response.json();
      if (data.success === true && data.data) {
        setState(data.data);
      } else {
        setState(null);
      }
    } catch (err) {
      toast.error("Error");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (serachType === "PNR") {
      if (pnrStopage && pnrStopage.data && !pnrIsLoading) {
        setShowBox(true);
        setStopage(pnrStopage.data);
      } else {
        setShowBox(false);
        toast.error("Invalid Pnr!");
      }
    } else if (serachType === "TRAIN") {
      if (trainStopage && trainStopage.data && !trainIsLoading) {
        setShowBox(true);
        setStopage(trainStopage.data);
      } else {
        setShowBox(false);
        toast.error("Invalid Train No.!");
      }
    }
  };

  return (
    <>
      <div className="container" style={{ paddingTop: "40px" }}>
        <div className="row">
          <div className="col-md-5">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <ul
                    className="list-inline m-0 radioBanner"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <li className="list-inline-item">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="PNR"
                          name="searchType"
                          className="custom-control-input"
                          onChange={() => setSearchType("PNR")}
                          checked={serachType === "PNR"}
                        />
                        <label className="custom-control-label" htmlFor="PNR">
                          PNR
                        </label>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="TrainName"
                          name="searchType"
                          className="custom-control-input"
                          onChange={() => setSearchType("TRAIN")}
                          checked={serachType === "TRAIN"}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="TrainName"
                        >
                          Train No
                        </label>
                      </div>
                    </li>
                  </ul>

                  <div className="mt-3 searchFild" style={{ display: "flex" }}>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      name="input"
                      value={inputQ}
                      onChange={(e) => setInputQ(e.target.value)}
                      placeholder={
                        serachType === "PNR"
                          ? "Enter PNR Number"
                          : "Enter Train Number"
                      }
                    />
                    <button className="btn btn-primary btn-lg" type="submit">
                      SEARCH
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <div className="card">
              <div
                className="card-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {showBox ? (
                  stopage ? (
                    stopage.trainRoutes.map((e, i) => {
                      return (
                        <div>
                          <Accordion
                            expanded={expanded === `panel${i + 1}`}
                            onChange={handleChange(`panel${i + 1}`)}
                          >
                            <AccordionSummary
                              sx={{
                                backgroundColor: "rgb(0,123,255)",
                                color: "black",
                              }}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              onClick={() => {
                                getRestro(e.stationCode);
                              }}
                            >
                              <Typography
                                sx={{ width: "100%", flexShrink: 0 }}
                                component={"div"}
                                className="sttypro"
                              >
                                <Typography component={"div"} className="stst">
                                  <Typography component={"span"}>
                                    Arrival:{" "}
                                  </Typography>
                                  <Typography component={"span"}>
                                    <Typography
                                      component={"i"}
                                      className="fa fa-clock-o"
                                    ></Typography>{" "}
                                    {moment(e.arrivalTime, "HH:mm:ss").format(
                                      "LT"
                                    )}
                                  </Typography>
                                </Typography>

                                <Typography
                                  className="stst stst2"
                                  component={"div"}
                                >
                                  <Typography component={"span"}>
                                    {e.stationName}
                                  </Typography>
                                  <Typography component={"span"}>
                                    {e.stationCode}
                                  </Typography>
                                </Typography>
                              </Typography>
                            </AccordionSummary>

                            {state
                              ? state.map((item, i) => {
                                  return (
                                    <>
                                      <VisibleRestCard
                                        item={item}
                                        data={stopage}
                                        e={e}
                                        key={i + 1}
                                        link={
                                          serachType === "TRAIN"
                                            ? `/menu/${item._id}/?train=${stopage.trainInfo.trainNumber}`
                                            : `/menu/${item._id}/?pnr=${inputQ}`
                                        }
                                      />
                                    </>
                                  );
                                })
                              :  <div
                              style={{
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "red",
                                fontWeight: "bold",
                              }}
                            > Service Started Soon!! </div>}
                          </Accordion>
                        </div>
                      );
                    })
                  ) : (
                    "Something went wrong try again"
                  )
                ) : (
                 ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookOrderComponent;
