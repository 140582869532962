// import moment from 'moment';
import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { serializeQueryStrings } from '../../utils/core';
import RestaurantFilterForm from './FilterForm';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function FilterModal({ modal, setModal, searchParams, setSearchParams }) {
    let navigate = useNavigate();

    const handleSubmit = async (values) => {
        setSearchParams(serializeQueryStrings(values))
        setModal(false)
    }
    const resetFrom = (formik) => {
        formik.resetForm()
        navigate(`/restuarants`)
        setModal(false)
    }
    return (
        <Modal
            isOpen={modal}
            style={customStyles}
            contentLabel="Orders Filter Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-dark">Filter Restaurants</h5>
                    <button className="btn btn-dark-outline shadow-none d-flex align-items-center" onClick={() => setModal(false)}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <RestaurantFilterForm
                        isLoading={false}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                        resetFrom={resetFrom}
                        searchParams={searchParams}
                    />
                </div>
            </div>
        </Modal>
    )
}
