import React from "react";
import Header from "../../components/Header/Header";
import Sidenav from "../../components/Sidenav/Sidenav";
import { useUser } from "../../components/hooks/user";
import FeatureComp from "../../components/Feature/FeatureComp";

const FeaturePage = () => {
  const { user} = useUser();

  return (
    <div>
      {" "}
      <Header user={user} />
      <Sidenav />
      <div className="content-wrapper">
        <FeatureComp />
      </div>
    </div>
  );
};

export default FeaturePage;
