import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import { fetchJson } from "../../libs/api";


const CUSINE_QUERY_KEY = 'cusine'
const FOOD_QUERY_KEY = 'foodtype'

export function useCusineItem() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/api/v1/cusine`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleAddCusine: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([CUSINE_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        addCusineIsLoading: mutation.isLoading,
    }
}
export function useFoodTypeItem() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/api/v1/food-type`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleAddFoodType: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([FOOD_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        addFoodTypeIsLoading: mutation.isLoading,
    }
}
