import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { Typography, TextField } from "@mui/material";
import { stripResturantsList, useRestaurantsList } from "../../../components/hooks/restaurants";
import SubmitButton from "../../../components/Common/Button";
import SelectField from "../../../components/Common/SelectField";
import { InputField } from "../../../components/Common/Fields";
import * as Yup from "yup";

export default function AddPromotionForm({ handleSubmit, isLoading, data }) {
    // const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();

    const PROMOTION_TYPE_OPTIONS = [
        { label: "Amount", value: 'AMOUNT' },
        { label: "Payment Type", value: 'PAYMENT_TYPE' },
    ];
    const DISCOUNT_TYPE_OPTIONS = [
        { label: "Percentage", value: 'PERCENTAGE' },
        { label: "Flat", value: 'FLAT' },
    ];

    const validation_schema = Yup.object({     
        requirement_type: Yup.string().required('Enter requirement type'),
        minimumOrderAmount: Yup.number().required('Enter minimum order amount'),
        discount_type: Yup.string().required('Select discount type'),
        value: Yup.number().required('Enter value'),
        maxDiscount: Yup.number().required('Enter max discount'),
    });

    return (
        <Formik
            initialValues={{
                requirement_type: data?.requirement_type ?? '',
                minimumOrderAmount: data?.minimumOrderAmount ?? '',
                discount_type: data?.discount_type ?? '',
                value: data?.value ?? '',
                maxDiscount: data?.maxDiscount ?? '',
            }}
            validationSchema={validation_schema}
            onSubmit={handleSubmit}
        // enableReinitialize
        >
            {(formik) => (

                <Form className="form-row">
                    <div className="col-md-4">
                        <div className="mb-3">
                            <Typography variant='caption'>Promotion Type<span className="text-danger">*</span></Typography>
                            <SelectField
                                name="requirement_type"
                                options={PROMOTION_TYPE_OPTIONS}
                                value={PROMOTION_TYPE_OPTIONS.find(option => option.value === formik.values.requirement_type)}
                                onChange={(e) => formik.setFieldValue("requirement_type", e.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="mb-3">
                            <Typography variant='caption'>Discount Type<span className="text-danger">*</span></Typography>
                            <SelectField
                                name="discount_type"
                                options={DISCOUNT_TYPE_OPTIONS}
                                value={DISCOUNT_TYPE_OPTIONS.find(option => option.value === formik.values.discount_type)}
                                onChange={(e) => formik.setFieldValue("discount_type", e.value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="mb-3">                        
                            <InputField type='number' label='Min Order Amount *' name='minimumOrderAmount' placeholder='Min Order Amount' />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="">
                            <InputField type='number' label='Discount Value *' name='value' placeholder='Discount Value' />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="">
                            <InputField type='number' label='Max Discount Value *' name='maxDiscount' placeholder='Max Discount Value' />
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <SubmitButton fullWidth label={data?.maxDiscount ? "Update Promotion" : "Add Promotion"} loading={isLoading} />
                    </div>

                </Form>

            )}
        </Formik>
    );
}