
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { endpoints } from '../../config/endpoints';
import { fetchJson } from '../../libs/api';
import { getTokens } from '../../utils/core';
import { API_HOST_URL } from '../../config';




const IMAGE_QUERY_KEY  = 'postImage'
export function PostImage() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/api/v1/featuredItems`, {

		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handlePostImage: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([IMAGE_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        postImageIsLoading: mutation.isLoading,
    }
}



export function useFeatureList() {
    const { isLoading, data } = useQuery([IMAGE_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.feature.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { featureData: data, featureIsLoading: isLoading }
}