import Modal from "react-modal";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { UseFileUploadAWS, useUpdateRest } from "../hooks/restaurants";
import toast from "react-hot-toast";

const ResturantImageModal = ({ isOpen, setOpen, data, resId }) => {
    const customStyles = {
        content: {
            top: "35%",
            height: "90%",
            left: "20%",
            width: "700px",
            background: "none",
            border: "none",
        },
    };
    const { id } = useParams();
    const [value, setValue] = useState()
    const { handleUpdateRest } = useUpdateRest(id);
    const { handleUploadFile, isFileUploading } = UseFileUploadAWS();

    const handleSubmit = async (data) => {
        // console.log(data)
        const toastId = toast.loading('Uploading...');
        const formData = new FormData();

        formData.append("images", data);
        const res = await handleUploadFile(formData);
        // console.log(res)
        if (res.success) {
            const updateRes = await handleUpdateRest({ logo: res?.data });
            // console.log(updateRes)
            if (updateRes.success) {
                toast.success("Upload Success!!", { id: toastId });
                setOpen(false)
            } else {
                toast.error(updateRes?.error ?? "Failed to saving the image!!", { id: toastId });
            }
            // toast.success("Upload Success!!", { id: toastId });
            // setOpen(false)
        } else {
            toast.error(res?.error ?? 'Upload Failed!!', { id: toastId });
        }
    };




    return (
        <Modal
            contentLabel="Upload Image"
            style={customStyles}
            isOpen={isOpen}
            ariaHideApp={false}
        >
            <div className="content-wrapper h-auto">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Upload Restaurant Profile Image
                        </h5>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setOpen(false)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        {/* <div class="mb-3">
                            <label for="formFile" class="form-label">Default file input example</label>
                            <input
                                type="file"
                                className="form-control"
                                name="file"
                                onChange={(e) => setValue(e.target.files[0])}
                            />
                        </div> */}

                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="inputGroupFile04" aria-describedby="fileUploadArea" name="file" onChange={(e) => setValue(e.target.files[0])} />
                                <label className="custom-file-label" for="inputGroupFile04">Choose file</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-outline-primary" type="button" id="fileUploadArea" onClick={() => handleSubmit(value)} disabled={isFileUploading}>Upload</button>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => handleSubmit(value)} disabled={isFileUploading}>
                                Upload
                            </button>
                        </div> */}
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ResturantImageModal;
