import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../Common/Fields';


export default function CouponForm({ discount, btnLabel, isLoading, handleSubmit }) {
    // const validate = Yup.object({
    //     mobile: Yup.string()
    //         .phone("IN")
    //         .required('Enter your valid mobile number.'),
    // })
    return (
        <Formik
            initialValues={{
                couponCode: '',
            }}
            // validationSchema={validate}
            onSubmit={handleSubmit}
        >

            <Form>
                <div className="form-group mt-2">
                    {discount > 0 ? <p className='text-success fw-500 text-center'><span className="fw-500 bg-light">Flat Rs.{discount}</span> Off on your order!</p>: <InputField type='text' name='couponCode' placeholder='Coupon Code' />}

                </div>

               <center>
               {isLoading ?
                    <button className="btn btn-dark  btn-sm " type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        {` `}Loading...
                    </button>
                    :
                    <button className='btn btn-success btn-sm ' type="submit"> {btnLabel}</button>
                }
               </center>
            </Form>

        </Formik>
    )
}
