import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDebouncedCallback } from 'use-debounce';
import InvoiceContent from './InvoiceContent';

const InvoicePrinter = ({ onPrintComplete, data }) => {
    const invoiceRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
        onAfterPrint: onPrintComplete,
    });

    const debouncedHandlePrint = useDebouncedCallback(handlePrint, 600); // Adjust the delay as needed

    useEffect(() => {
        debouncedHandlePrint();
    }, [debouncedHandlePrint]);

    return (
        <div style={{ display: 'none' }}>
            <InvoiceContent ref={invoiceRef} data={data} />
        </div>
    );
};

export default InvoicePrinter;