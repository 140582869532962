import { IconButton } from '@mui/material';
import React from 'react'
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddStationForm from '../form/AddStationForm';
import { useStationsAdd } from '../hooks/stations';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px'
    },
};

export default function StationAddModal({ modalIsOpen, setModalIsOpen }) {
    const { handleAddStations, addStationsIsLoading } = useStationsAdd()
    const handleSubmit = async (values, { resetForm }) => {
        const res = await handleAddStations(values)

        if (res.success) {
            
            toast.success('Added new station.');
            setModalIsOpen(false);
            resetForm({ values: '' });
        } else {
            toast.error(res.error)
        }
    }
    return (
        <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={refetch}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add new station"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3">Add new station</h5>
                    <IconButton onClick={() => setModalIsOpen(false)}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <AddStationForm
                        handleSubmit={handleSubmit}
                        isLoading={addStationsIsLoading}
                    />
                </div>

            </div>

        </Modal>
    )
}
