import React from 'react'
import { useItemList } from '../../../components/hooks/item'
import { API_HOST_URL } from '../../../config'
import { endpoints } from '../../../config/endpoints'
import DataTableBase from '../../../components/Common/DataTableBase'
import FilterComponent from '../../../components/Common/FilterComponent'
import Loader from '../../../components/Loader'
import UpdateItemModel from '../../../components/model/UpdateItemModel'
import DeleteItemModel from '../../../components/model/DeleteItemModel'
import ViewItemModel from '../../../components/model/ViewItemModel'

export default function RestroMenuTable({ data }) {
    const { itemData, itemIsLoading } = useItemList(data?._id)
    const [Itemid, setItemid] = React.useState([])
    const [flag, setFlag] = React.useState(false)
    const [item, setItem] = React.useState([])
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [render, setRender] = React.useState(false);
    const sendData = async (id) => {
        try {
            const fetchApi = await fetch(`${API_HOST_URL}/${endpoints.item.getDetail}/${id}`)
            const datat = await fetchApi.json()
            setItem(datat.data)
            setFlag(datat.success)
        } catch (error) {
            console.log(error);
            throw error;
        }
    };
    const columns = [
        {
            name: 'Item Name',
            selector: e => e.itemName,
            cell: e => <th scope="row">{e.itemName}</th>,
            sortable: true,
        },
        {
            name: 'Type',
            selector: e => <td>{e.itemType}</td>,
            sortable: true,
        },
        {
            name: 'Veg/Non-veg',
            selector: e => <td>
                {e.category === 'nonVeg' ? <span className="badge badge-danger">Non-Veg</span> : <span className="badge badge-success">Veg</span>} <br />
            </td>,
            sortable: true,
        },
        {
            name: 'Food Type',
            selector: e => <td>{e.itemType}</td>,
            sortable: true,
        },
        {
            name: 'Avalibility',
            selector: e => <td>
                {e.availability === true ? (
                    <p className="aval">Yes</p>
                ) : (
                    <p className="noaval">No</p>
                )}
            </td>
        },
        {
            name: 'Base Price',
            selector: e => <td>
                {!e.basePrice ? "" : e.basePrice.$numberDecimal + " rs"}
            </td>
        },
        {
            name: 'Actions',
            selector: e => <td>
                <div className='d-flex justify-content-center align-items-center gap-1 '>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm m-0"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalview"
                        data-whatever="@getbootstrap"
                        onClick={() => {
                            // sendData(e._id)
                        }}
                    >
                        <span class="material-symbols-outlined">
                            visibility
                        </span>
                    </button>
                    <button type="button"
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalupdate"
                        data-whatever="@getbootstrap"
                        onClick={() => {
                            sendData(e._id)
                        }}
                    >
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </button>
                    <button type="button"
                        className="btn btn-danger btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModaldelete"
                        data-whatever="@getbootstrap"
                        onClick={() => {
                            setItemid(e._id)

                        }}
                    >
                        <span class="material-symbols-outlined">
                            delete
                        </span>
                    </button>

                </div>

            </td>
        },
    ];

    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            // <div className="d-flex justify-content-between align-items-center mb-3">

            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
            // </div>
        );
    }, [filterText, resetPaginationToggle]);
    if (itemIsLoading) {
        return <Loader />
    }
    const filteredItems = itemData.length > 0 ? itemData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    ) : [];
    return (
        <div>
            <DataTableBase
                columns={columns}
                data={filteredItems ?? []}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                pagination
                responsive
                progressPending={itemIsLoading}
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
                subHeader
            />




            <ViewItemModel
                id="exampleModalview"

                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                item={item}
            />

            <DeleteItemModel
                id="exampleModaldelete"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                Itemid={Itemid}
                render={render}
                setRender={setRender}
            />

            < UpdateItemModel
                id="exampleModalupdate"

                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data={item}

                flag={flag}
            // changeState = {changeState}
            />

        </div>
    )
}
