/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import TextField from '../../Common/Fields';
import { CREATED_AT_FILTER_OPTIONS, ORDER_SOURCE_OPTIONS, SHOW_RECORDS_OPTIONS, STATUS_FILTER_OPTIONS } from './Options';
import SelectField from '../../Common/SelectField';
import Loader2 from '../../Common/Loader2';
import { stripResturantsList, useRestaurantsList } from '../../hooks/restaurants';
// import DatePicker from '../../Common/DatePickerField';
import FormikDatePicker from '../../Common/FormikDatePicker';

export default function OrdersFilterForm({ isLoading, handleSubmit, setModal, resetFrom, searchParams }) {
    const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        // console.log('currentParams', params,)
        setParams(currentParams)
        setLoading(false)
    }, [searchParams])

    // const validate = Yup.object({
    //     q: Yup.string().max(150, 'Maximum length is 150 characters'),
    // })
    // .shape({
    //     startDate: Yup.string().when('endDate', {
    //         is: (val) => val !== null,
    //         then: Yup.string().required('required').nullable(),
    //         otherwise: Yup.string().nullable()
    //     }),
    //     endDate: Yup.string().when('startDate', {
    //         is: (val) => val !== null,
    //         then: Yup.string().required('required').nullable(),
    //         otherwise: Yup.string().nullable()
    //     }),
    // }, ['startDate', 'endDate']);

    const validate = Yup.object().shape({
        startDate: Yup.string().nullable(),
        endDate: Yup.string().nullable()
    }).test('dates-check', 'Both start date and end date must be provided together', function (value) {
        const { startDate, endDate } = value;
        if ((startDate && !endDate) || (!startDate && endDate)) {
            return this.createError({
                path: 'startDate',
                message: 'Both start date and end date must be provided together',
            });
        }
        return true;
    });
    if (loading) {
        return <Loader2 />
    }
    return (
        <Formik
            initialValues={{
                "q": params?.q ?? '',
                "orderId": params?.orderId ?? '',
                "irctcOrderId": params?.irctcOrderId ?? '',
                "stationCode": params?.stationCode ?? '',
                "mobile": params?.mobile ?? '',
                "status": params?.status ?? '',
                "limit": params?.limit ?? '20',
                "ordered": params?.ordered ?? '',
                "orderSource": params?.orderSource ?? '',
                "resturant": params?.resturant ?? null,
                "startDate": params?.startDate ?? null,
                "endDate": params?.endDate ?? null,
                "deliveryStartDate": params?.deliveryStartDate ?? null,
                "deliveryEndDate": params?.deliveryEndDate ?? null,
            }}
            // validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-6">
                            <TextField label='Order ID' type="text" name='orderId' placeholder='Order ID' />
                        </div>
                        <div className="col-md-6">
                            <TextField label='Mobile' type="text" name='mobile' placeholder='Mobile' />
                        </div>
                    </div>
                    
                    <div className='row mb-2'>
                        <div className="col-md-6">
                            <FormikDatePicker label='Booking From' minDate='2023-04-01' name='startDate' />
                        </div>
                        <div className="col-md-6">
                            <FormikDatePicker label='Booking To' minDate='2023-04-01' name='endDate' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <TextField label='Station Code' type="text" name='stationCode' placeholder='Station Code' />
                        </div>
                        <div className="col-md-6">
                            <TextField label='IRCTC Order ID' type="text" name='irctcOrderId' placeholder='IRCTC Order ID' />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className="col-md-6">
                            <FormikDatePicker label='Delivery From' minDate='2024-08-01' name='deliveryStartDate' />
                        </div>
                        <div className="col-md-6">
                            <FormikDatePicker label='Delivery To' minDate='2024-08-01' name='deliveryEndDate' />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className="col-md-6">
                            <Typography variant='caption'>Status </Typography>
                            <SelectField
                                name='status'
                                options={STATUS_FILTER_OPTIONS}
                                value={STATUS_FILTER_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.status;
                                })}
                                onChange={(e) => formik.setFieldValue('status', e.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <Typography variant='caption'>Source </Typography>
                            <SelectField
                                name='orderSource'
                                options={ORDER_SOURCE_OPTIONS}
                                value={ORDER_SOURCE_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.orderSource;
                                })}
                                onChange={(e) => formik.setFieldValue('orderSource', e.value)}
                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        {/* <div className="col-md-4">
                            <Typography variant='caption'>Ordered </Typography>
                            <SelectField
                                name='ordered'
                                options={CREATED_AT_FILTER_OPTIONS}
                                value={CREATED_AT_FILTER_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.ordered;
                                })}
                                onChange={(e) => formik.setFieldValue('ordered', e.value)}
                            />
                        </div> */}
                        <div className="col-md-6">
                            <Typography variant='caption'>Restaurant </Typography>
                            <SelectField
                                name='resturant'
                                options={restaurantsData?.map(stripResturantsList)}
                                value={restaurantsData?.map(stripResturantsList).filter(function (option) {
                                    return option.value === formik.values.resturant;
                                })}
                                onChange={(e) => formik.setFieldValue('resturant', e.value)}
                                isLoading={restaurantsIsLoading}
                            />
                        </div>
                        
                        <div className="col-md-6">
                            <Typography variant='caption'>Show Records </Typography>
                            <SelectField
                                name='limit'
                                options={SHOW_RECORDS_OPTIONS}
                                value={SHOW_RECORDS_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.limit;
                                })}
                                onChange={(e) => formik.setFieldValue('limit', e.value)}
                            />
                        </div>
                    </div>


                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Filter records
                            </LoadingButton>
                            <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Clear Filter</Button>
                        </div>
                        <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>

                    </div>
                </Form>
            )}
        </Formik>
    )
}
