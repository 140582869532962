import { Box, Card, CardContent, Typography } from "@mui/material"
// import { API_HOST_URL } from "../../config"
import { useState } from "react"

export default function UserViewComp({data}){
    const [user] = useState(null);
        return <>
       <Card variant="outlined"  sx={{ minWidth: 500 }}>
<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3 }} >
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
           Name
          </Typography>
          <Typography component="div" variant="p">
            {data.name}
          </Typography>
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
        Role
          </Typography>
          <Typography component="div" variant="p">
            {data.role}
          </Typography>
        </CardContent>

      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3  }}>

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div" >
          Mobile
          </Typography>
          <Typography component="div" variant="p" width={"100px"}>
           {data.mobile}
          </Typography>
          
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Active
          </Typography>
          <Typography component="div" variant="p">
            {data.isActive? "Yes" : "No"}
          </Typography>
        </CardContent>
      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
      
      
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Email
          </Typography>
          <Typography component="div" variant="p">
            { data.email }
          </Typography>
        </CardContent>

        
      </Box>

      {data.log.length > 0 ?<> <Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
       
     
       <CardContent sx={{ flex: '1 0 auto' }}>
       <Typography variant="subtitle1" color="text.secondary" component="div">
          Status
          </Typography>
          <Typography component="div" variant="p">
          { data.status }
          </Typography>
          
        
       </CardContent>

       <CardContent sx={{ flex: '1 0 auto' }}>
       <Typography variant="subtitle1" color="text.secondary" component="div">
          Updated At
          </Typography>
          <Typography component="div" variant="p">
          { data.updated_at }
          </Typography>
          
        
       </CardContent>

     </Box> 

     <Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
       
     
       <CardContent sx={{ flex: '1 0 auto' }}>
       <Typography variant="subtitle1" color="text.secondary" component="div">
          Updated By
          </Typography>
          <Typography component="div" variant="p">
          {user!=null? user.name : "" }
          </Typography>
          
        
       </CardContent>

       <CardContent sx={{ flex: '1 0 auto' }}>
      
      <button
              className="btn btn-primary"
           
              data-dismiss="modal"
            >Ok  <i className="fa fa-check"></i>
            </button>
    </CardContent>

     </Box>
     </> 
     : <Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
       

     <CardContent sx={{ flex: '1 0 auto' }}>
      
       <button
               className="btn btn-primary"
            
               data-dismiss="modal"
             >Ok  <i className="fa fa-check"></i>
             </button>
     </CardContent>

   </Box>}
     


</Card>
        </>
    }