// import UpdateEnquiryForm from "../form/UpdateEnquiryForm";
import UpdateStationForm from "../form/UpdateStationForm";

export default function UpdateStationModel({ data }) {
  


  
  return (
    <div
      className="modal fade"
      id="exampleModalupdatetwoo"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
     tabIndex={-1}
     
    >
      <div className="modal-dialog" role="document"  >
        
          <UpdateStationForm
            className="modal-content"
            data={data}
           
          />


  
      </div>
    </div>
  );
}
