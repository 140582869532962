import { Box, Card, CardContent, Typography } from "@mui/material";
import moment from "moment";

export default function EnquaryDetail({item}){
    // console.log(item)
 return <>
<Card variant="outlined"  sx={{ minWidth: 700 }}>
<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3 }} >
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Name
          </Typography>
          <Typography component="div" variant="p">
            {item.name}
          </Typography>
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
         Message Discription
          </Typography>
          <Typography component="div" variant="p" width={"500px"}>
            {item.description}
          </Typography>
        </CardContent>

      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row', pl: 3, pr: 3  }}>

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div" >
          Remarks
          </Typography>
          <Typography component="div" variant="p" width={"100px"}>
           {item.remarks}
          </Typography>
          
        </CardContent>

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
         Status
          </Typography>
          <Typography component="div" variant="p">
           {item.status }
          </Typography>
        </CardContent>
       
      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
      
      
       

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
         Updated By
          </Typography>
          <Typography component="div" variant="p">
          { item.updatedBy}
          </Typography>
          
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
         Mobile
          </Typography>
          <Typography component="div" variant="p">
            {item.mobile}
          </Typography>
        </CardContent>
      </Box>
<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
      
      
       

        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="subtitle1" color="text.secondary" component="div">
       Created At
          </Typography>
          <Typography component="div" variant="p">
          {item.createdAt? moment(item.createdAt).format("DD-MM-YYYY hh:mm:A"):""}
          </Typography>
          
        </CardContent>
     
      </Box>

<Box sx={{ display: 'flex', flexDirection: 'row' , pl: 3, pr: 3 }}>
       

        <CardContent sx={{ flex: '1 0 auto' }}>
         
          <button
                  className="btn btn-primary"
               
                  data-dismiss="modal"
                >Ok  <i className="fa fa-check"></i>
                </button>
        </CardContent>

      </Box>
</Card>
 </>
}