import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { OrderStatic } from './OrderStatic';
import { TopRes } from './TopRes';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';



export default function FooterStatics({ ordersData, ordersIsLoading, selectFilter }) {
    return (
        <div>
            {ordersIsLoading ? (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader title={`Order statistics`} />
                            <Divider />
                            <Skeleton height={600} />
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader title={`Top Resturants`} />
                            <Divider />
                            <Skeleton height={600} />
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                ordersData && ordersData?.length > 0 && (
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <OrderStatic duration={selectFilter} orderData={ordersData} />
                        </Grid>
                        <Grid item xs={6}>
                            <TopRes duration={selectFilter} orderData={ordersData} />
                        </Grid>
                    </Grid>
                )
            )}
        </div>
    );
}
