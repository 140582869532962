import { API_HOST_URL } from "../../config";


import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { endpoints } from "../../config/endpoints";

// const USER_QUERY_KEY = 'user'
const CUSTOMERS_QUERY_KEY = 'customers_list'

// Get Users List
export function useCustomersList() {
    const { isLoading, data } = useQuery([CUSTOMERS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data }= await fetchJson(`${API_HOST_URL}/${endpoints.customers.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return {
                success: false,
                error: err
            };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { customersData: data, customersIsLoading:isLoading }
}


// Add New Customer
export function useAddCustomer() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.account.add_user}`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleAddUser: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const user = await res.json();
                if (user.success) {
                    console.log(user) 
                    await queryClient.invalidateQueries([CUSTOMERS_QUERY_KEY])
                }
                
                return user
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        addUserIsLoading: mutation.isLoading,
    }
}