import React, { useEffect, useState } from "react";
import { useOrdersList } from "../hooks/orders";
import { Link, useSearchParams } from "react-router-dom";
import { useUser } from "../hooks/user";
import OrdersTable from "./OrdersTable";
import OrdersExport from "./OrdersExport";
import TopCounter from "./TopCounter";
import { useDebouncedCallback } from "use-debounce";
import FooterStatics from "./FooterStatics";
import Skeleton from "react-loading-skeleton";

const OrderComp = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useUser();

    // Orders List Pagination
    const [orderExort, setOrderExport] = useState(false)
    const [enableApi, setAPI] = useState(true)
    const [isLoading, setLoading] = useState(true)
    const { ordersData, ordersIsLoading, handleParam } = useOrdersList('', enableApi);
    // const { ordersData, ordersIsLoading } = useOrdersList();
    // const [filterText, setFilterText] = React.useState("");
    // const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    // console.log(ordersData)



    // const [selectFilter, setSelectFilter] = React.useState('overall');




    // Filter Data 
    const loadInitData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ``;
        const limit = parseInt(currentParams?.limit ?? '20');
        params = new URLSearchParams({ ...currentParams, limit }).toString();
        setAPI(true);
        params && handleParam(`?${params}`)
    }, 300);

    useEffect(() => {
        loadInitData();
    }, [loadInitData, searchParams]);

    useEffect(() => {
        // Handling the loa`ding state
        if (ordersData && !ordersIsLoading && ordersData?.success) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [ordersData && !ordersIsLoading]);

    return (
        <>
            <div className="card pd-20 pd-sm-20 bg-light">
                {/* Breadcrumb */}
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h6 className="card-body-title text-primary">All Orders</h6>
                        <p className="">
                            {isLoading ? <Skeleton width={280} /> : ordersData?.success ? `We have a total of ${ordersData?.totalCount} orders in the list.` : <Skeleton width={280} />}
                        </p>
                    </div>
                    <div className="d-flex gap-1">
                        <Link className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" to="/book-order">
                            <i className="material-icons-outlined">add</i>
                            Add Order
                        </Link>
                        <button onClick={() => setOrderExport(!orderExort)} className={`btn btn-sm d-flex align-items-center justify-content-center ${orderExort ? 'btn-dark' : 'btn-info'}`}>
                            <i className="material-icons-outlined">{orderExort ? 'close' : 'upgrade'}</i>
                            Export
                        </button>
                    </div>
                </div>
                {/* Top Counter */}
                <TopCounter ordersData={ordersData ?? {}} loading={isLoading} />

                {/* Export Order */}
                {user.role === 'admin' || user.role === 'superadmin' || user.role === 'manager' ? orderExort && <OrdersExport /> : ""}

                {/* Order Table */}
                <div className="table-wrapper">
                    <OrdersTable
                        data={ordersData?.data ?? []}
                        isLoading={isLoading}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    // subHeaderComponent={subHeaderComponent}
                    />

                </div>

                {/* Footer Statics */}
                {/* {ordersData && ordersData?.data?.length > 0 && !ordersIsLoading && <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <OrderStatic duration={selectFilter} orderData={ordersData?.data ?? []} />
                    </Grid>
                    <Grid item xs={6}>
                        <TopRes duration={selectFilter} orderData={ordersData?.data ?? []} />
                    </Grid>
                </Grid>} */}

                <FooterStatics
                    ordersData={ordersData?.data ?? []}
                    ordersIsLoading={isLoading}
                // selectFilter={selectFilter}
                />
            </div>
        </>
    );
};

export default OrderComp;
