
import Modal from 'react-modal'
import React from 'react'
import TagRestaurantIrctc from '../ResturantContent/TagRestaurantIrctc';

const TagToIrctcModal = ({ isOpen, setOpen, data, resId }) => {
    const customStyles = {
        content: {
            top: '8%',
            height: "90%",
            left: '12%',
            width: '75%',
            background: "none",
            border: "none"
        },
    };
    return (
        <Modal
            contentLabel="Tag to IRCTC"
            style={customStyles}
            isOpen={isOpen}
            ariaHideApp={false}
        >
            <div className="content-wrapper h-auto">
                <TagRestaurantIrctc resId={resId} data={data} setModalIsOpen={setOpen} modalIsOpen={isOpen} />
            </div>
        </Modal>
    )
}

export default TagToIrctcModal