import React from 'react';
import { useParams } from 'react-router-dom';
import { useOrderDetail } from '../hooks/orders';
import OrderInformation from './OrderInformation';
import { useUser } from '../hooks/user';
import Header from '../Header/Header';
import Sidenav from '../Sidenav/Sidenav';
import OrderDetail from './OrderDetailComp/OrderDetail';
import OrderDetailSkeleton from './OrderDetailComp/Skeleton';
import NotFoundOrderPage from './OrderDetailComp/OrderNotFound';

export default function OrderDetailPage() {
    const { id } = useParams();
    const { orderData, orderIsLoading } = useOrderDetail(id);
    const { user, isLoading } = useUser();
    return <>
        <Header user={user} />
        <Sidenav />
        <div className="content-wrapper">
            {!orderData?.success && !orderIsLoading && <NotFoundOrderPage />}
            {!orderData?.success && orderIsLoading && <OrderDetailSkeleton />}
            {orderData?.success && !orderIsLoading && <OrderDetail data={orderData?.data} isLoading={orderIsLoading} />}
            
            {/* {orderData && !orderIsLoading && <OrderInformation data={orderData?.data} isLoadin={orderIsLoading} />} */}
        </div>
    </>

}
