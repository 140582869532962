import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import { useState } from "react";


const ORDERS_QUERY_KEY = 'orders';
const ORDER_QUERY_KEY = 'order';
const ORDER_ETA_QUERY_KEY = 'order_eta';

// Get Orders List
export function useOrdersList__backup() {
    const { isLoading, data } = useQuery([ORDERS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.orders.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { ordersData: data, ordersIsLoading: isLoading }
}


// Get Order List
const getOrders = async (key) => {
    const param = key.queryKey[1].param;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(
                `${API_HOST_URL}/${endpoints.orders.list_pagination}${param}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
                true,
            );
            return await res.json();
        }
        // const res = await fetchJson(
        //     `${API_HOST_URL}/${endpoints.orders.list_pagination}${defaultParam ? defaultParam : "?limit=50"}`,
        //     {
        //         headers: { Authorization: `Bearer ${accessToken}` },
        //     },
        //     true,
        // );
        // return await res.json();
        return {
            success: false,
            detail: 'err',
        };
    } catch (err) {
        return {
            success: false,
            detail: err,
        };
    }
};

export function useOrdersList(defaultParam = null, enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [ORDERS_QUERY_KEY, { param: param, defaultParam }],
        getOrders,
        {
            // cacheTime: Infinity,
            // staleTime: 30_000,
            cacheTime: 0,
            staleTime: 1,
            enabled: enabled,
        },
    );
    return {
        ordersData: data,
        ordersIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    };
}
// Get Order Detail
export function useOrderDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [ORDER_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.orders.list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { orderData: data, orderIsLoading: isLoading, isError, status }
}


export function useUpdateOrderStatus(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.statusUpdate}/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values),
    }, true))
    return {
        handleUpdateOrder: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data) {
                    await queryClient.invalidateQueries([ORDER_QUERY_KEY])
                }
                return data

            } catch (err) {
                return {
                    success: false
                }
            }
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}

export function useNewUpdateOrderStatus(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.newStatusUpdate}/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values),
    }, true))
    return {
        handleNewUpdateOrder: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data) {
                    await queryClient.invalidateQueries([ORDER_QUERY_KEY])
                }
                return data

            } catch (err) {
                return {
                    success: false
                }
            }
        },
        handleNewUpdateIsLoading: mutation.isLoading,
    }
}



export function useCheckOrderStatus(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [ORDERS_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.orders.statusCheck}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { statusData: data, statusisLoading: isLoading, isError, status }
}

const PLACE_ORDER_KEY = 'placeorder'

export function usePlaceOrder() {
    const queryClient = useQueryClient();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.placeOrder}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handlePlaceOrder: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([PLACE_ORDER_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        isPlaceOrderLoading: mutation.isLoading,
    }
}



const COUPON_QUERY_KEY = 'couponkey'

export function useApplyCoupon() {
    const queryClient = useQueryClient();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.couponValidate}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handleCoupon: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([COUPON_QUERY_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        isCouponLoading: mutation.isLoading,
    }
}

export function useConfirm() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((id) => fetchJson(`${API_HOST_URL}/${endpoints.orders.confirm}/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    }, true))
    return {
        handleConfirm: async (id) => {
            try {
                const res = await mutation.mutateAsync(id);
                const data = await res.json();
                queryClient.invalidateQueries([ORDER_QUERY_KEY]);
                return data;
            } catch (error) {
                return { success: false, error: error }
            }
        },
        isConfirmLoading: mutation.isLoading
    }
}


export function useSwapOrder(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.orders.swapOrder}/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values),
    }, true))
    return {
        handleSwapOrder: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data) {
                    await queryClient.invalidateQueries([ORDER_QUERY_KEY])
                }
                return data

            } catch (err) {
                return {
                    success: false,
                    errror: err
                }
            }
        },
        IsOrderSwaping: mutation.isLoading,
    }
}


export function useOrderETA(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([ORDER_ETA_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.orders.eta}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000,
        cacheTime: 0,
        staleTime: 1,
        enabled: enabled,
    })
    return {
        orderEtaData: data,
        isCheckingEta: isLoading,
        refreshEta: async (value) => setParam(value),
    }
}