import { Link } from "react-router-dom";
import { useUser } from "../hooks/user";

const roleLinks = {
    admin: [
        { to: "/restuarants", icon: "restaurant", text: "My Restaurants" },
        { to: "/vendor", icon: "badge", text: "Vendor Register" },
        { to: "/order", icon: "shopping_cart", text: "Orders" },
        { to: "/stations", icon: "train", text: "Stations" },
        { to: "/enquiry", icon: "help", text: "Enquiry" },
        { to: "/vendor-rds", icon: "local_offer", text: "RDS" },
        { to: "/vendor-transactions", icon: "list", text: "Vendor Trans." },
        { to: "/featureitem", icon: "list_alt", text: "Feature Item" },
        { to: "/customers", icon: "group", text: "Customers" },
        { to: "/users", icon: "person", text: "Users" },
        { to: "/cusine", icon: "restaurant_menu", text: "Cuisine" },
        { to: "/foodtype", icon: "fastfood", text: "Food Type" },
        { to: "/cancel-req", icon: "cancel", text: "Cancel Req" },
        { to: "/blog", icon: "rss_feed", text: "Blog" },
        { to: "/location", icon: "location_on", text: "Location" },
        { to: "/pages", icon: "pages", text: "Page" },
        { to: "/invoices", icon: "picture_as_pdf", text: "Invoices" },
    ],
    superadmin: [
        { to: "/restuarants", icon: "restaurant", text: "My Restaurants" },
        { to: "/vendor", icon: "badge", text: "Vendor Register" },
        { to: "/order", icon: "shopping_cart", text: "Orders" },
        { to: "/stations", icon: "train", text: "Stations" },
        { to: "/enquiry", icon: "help", text: "Enquiry" },
        { to: "/vendor-rds", icon: "local_offer", text: "RDS" },
        { to: "/vendor-transactions", icon: "list", text: "Vendor Trans." },
        { to: "/featureitem", icon: "list_alt", text: "Feature Item" },
        { to: "/customers", icon: "group", text: "Customers" },
        { to: "/users", icon: "person", text: "Users" },
        { to: "/cusine", icon: "restaurant_menu", text: "Cuisine" },
        { to: "/foodtype", icon: "fastfood", text: "Food Type" },
        { to: "/cancel-req", icon: "cancel", text: "Cancel Req" },
        { to: "/blog", icon: "rss_feed", text: "Blog" },
        { to: "/location", icon: "location_on", text: "Location" },
        { to: "/pages", icon: "pages", text: "Page" },
        { to: "/invoices", icon: "picture_as_pdf", text: "Invoices" },
    ],
    manager: [
        { to: "/restuarants", icon: "restaurant", text: "My Restaurants" },
        { to: "/vendor", icon: "badge", text: "Vendor Register" },
        { to: "/order", icon: "shopping_cart", text: "Orders" },
        { to: "/stations", icon: "train", text: "Stations" },
        { to: "/enquiry", icon: "help", text: "Enquiry" },
        { to: "/vendor-rds", icon: "local_offer", text: "RDS" },
        { to: "/vendor-transactions", icon: "list", text: "Vendor Trans." },
        { to: "/featureitem", icon: "list_alt", text: "Feature Item" },
        { to: "/customers", icon: "group", text: "Customers" },
        { to: "/users", icon: "person", text: "Users" },
        { to: "/cusine", icon: "restaurant_menu", text: "Cuisine" },
        { to: "/foodtype", icon: "fastfood", text: "Food Type" },
        { to: "/cancel-req", icon: "cancel", text: "Cancel Req" },
        { to: "/invoices", icon: "picture_as_pdf", text: "Invoices" },
    ],
    accountant: [
        { to: "/restuarants", icon: "restaurant", text: "My Restaurants" },
        { to: "/order", icon: "shopping_cart", text: "Orders" },
        { to: "/vendor-transactions", icon: "list", text: "Vendor Trans." },
    ],
    supportExecutive: [
        { to: "/restuarants", icon: "restaurant", text: "My Restaurants" },
        { to: "/order", icon: "shopping_cart", text: "Orders" }
    ],
    floorManager: [
        { to: "/restuarants", icon: "restaurant", text: "My Restaurants" },
        { to: "/order", icon: "shopping_cart", text: "Orders" }
    ],
    editor: [
        { to: "/blog", icon: "rss_feed", text: "Blog" },
        { to: "/location", icon: "location_on", text: "Location" },
        { to: "/pages", icon: "pages", text: "Page" }
    ]
};

const getLinksForRole = (role) => {
    return roleLinks[role] || [];
};

export default function Sidenav() {
    const { user } = useUser();
    const links = getLinksForRole(user.role);

    return (
        <>
            <aside className="main-sidebar sidebar-dark-primary elevation-1">
                {/* Brand Logo */}
                <Link to="/" className="brand-link text-decoration-none">
                    <img
                        src="/img/favicon.png"
                        alt="Logo"
                        className="brand-image img-circle elevation-1 bg-light"
                        // style={{ opacity: ".8" }}
                    />
                    <span className="poppins-semibold mx-3">Yatri Restro</span>
                </Link>
                {/* Sidebar */}
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                            <li className="nav-item">
                                <Link to="/" className="nav-link d-flex align-items-center">
                                    <i className="material-icons nav-icon">dashboard</i>
                                    <p className="ms-2 mb-0">Dashboard</p>
                                </Link>
                            </li>
                            {links.map((link, index) => (
                                <li key={index} className="nav-item">
                                    <Link to={link.to} className="nav-link d-flex align-items-center">
                                        <i className="material-icons nav-icon">{link.icon}</i>
                                        <p className="ms-2 mb-0">{link.text}</p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    );
}