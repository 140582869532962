import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { Typography, TextField } from "@mui/material";
import { stripResturantsList, useRestaurantsList } from "../../../components/hooks/restaurants";
import SubmitButton from "../../../components/Common/Button";
import SelectField from "../../../components/Common/SelectField";
import { InputField } from "../../../components/Common/Fields";
import * as Yup from "yup";

export default function TransactionForm({ handleSubmit, isLoading,  data }) {
    const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();

    const TRANSACTION_TYPE_OPTIONS = [
        { label: "Credit", value: 'CR' },
        { label: "Debit", value: 'DR' },
    ];
    const PURPOSE_OPTIONS = [
        { label: "Deposit", value: 'deposit' },
        { label: "Commission", value: 'commission' },
        { label: "Reversal", value: 'reversal' },
        { label: "Withdrawal", value: 'withdrawal' },
        { label: "GST", value: 'gst' },
        { label: "Refund", value: 'refund' },
        { label: "Other", value: 'other' },
    ];

    const validation_schema = Yup.object({
        trnxType: Yup.string().required('Select transaction type'),
        amount: Yup.number().required('Enter amount'),
        outlet: Yup.string().required('Select outlet'),
        purpose: Yup.string().required('Select purpose'),
        transactionDate: Yup.date().required('Enter transaction date'),
    });

    return (
        <Formik
            initialValues={{
                amount: '',
                trnxType: '',
                outlet: null,
                purpose: '',
                transactionDate: data?.transactionDate ? moment(data.transactionDate).format('yyyy-MM-DD') : "",
                remarks: '',
            }}
            validationSchema={validation_schema}
            onSubmit={handleSubmit}
        // enableReinitialize
        >
            {(formik) => (

                <Form className="form-row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <Typography variant='caption'>Restaurant Name<span className="text-danger">*</span></Typography>
                            <SelectField
                                name='outlet'
                                options={restaurantsData?.map(stripResturantsList)}
                                value={restaurantsData?.map(stripResturantsList).filter(function (option) {
                                    return option.value === formik.values.outlet;
                                })}
                                onChange={(e) => formik.setFieldValue('outlet', e.value)}
                                isLoading={restaurantsIsLoading}
                            />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="mb-3">
                            <Typography variant='caption'>Transaction Type<span className="text-danger">*</span></Typography>
                            <SelectField
                                name="trnxType"
                                options={TRANSACTION_TYPE_OPTIONS}
                                value={TRANSACTION_TYPE_OPTIONS.find(option => option.value === formik.values.trnxType)}
                                onChange={(e) => formik.setFieldValue("trnxType", e.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="mb-3">
                            <Typography variant='caption'>Purpose<span className="text-danger">*</span></Typography>
                            <SelectField
                                name="purpose"
                                options={PURPOSE_OPTIONS}
                                value={PURPOSE_OPTIONS.find(option => option.value === formik.values.purpose)}
                                onChange={(e) => formik.setFieldValue("purpose", e.value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <Field
                                as={TextField}
                                size="small"
                                label='Amount *'
                                type="number"
                                name='amount'
                                placeholder='Amount'
                                fullWidth
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={<ErrorMessage name="amount" />}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <Field
                                as={TextField}
                                type="date"
                                label="Trans. Date *"
                                name="transactionDate"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                fullWidth
                                error={formik.touched.transactionDate && Boolean(formik.errors.transactionDate)}
                                helperText={<ErrorMessage name="transactionDate" />}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <InputField label='Remarks' type="text" name='remarks' placeholder='Reference Number / Remarks' />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        {/* <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => setModal(false)}
                        >
                            Close
                        </button> */}
                        <SubmitButton fullWidth label="Add Transaction" loading={isLoading} />
                    </div>

                </Form>

            )}
        </Formik>
    );
}