import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";


const BLOG_QUERY_KEY = 'blogList'

export function useBlogList() {
    const { isLoading, data } = useQuery([BLOG_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.blog.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { blogData: data, blogIsLoading: isLoading }
}







export function useUpdateBlog(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.blog.update}/${id}`, {
		method: 'PUT',
		headers: { 
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: values,
	}, true))
    return { 
        handleUpdateBlog: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([BLOG_QUERY_KEY])
                }
                return data
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}




export function useBlogDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [BLOG_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.blog.detail}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { blogData: data, blogIsLoading: isLoading, isError, status }
}