import { Button } from "@mui/material";
import { UseTagResturant } from "../hooks/restaurants";
import { toast } from "react-toastify";
import { useState } from "react";


export default function TagRestaurantIrctc({ resId, data, setModalIsOpen }) {
    const { handleTagResturant, isTagResLoading } = UseTagResturant();
    const [buttonLoading, setButtonLoading] = useState();
    const tagfunction = async (stCode, i) => {
        setButtonLoading(i);
        const data = await handleTagResturant({ resId, stCode });
        // console.log(data);
        if (data.success) {
            toast.success(data.msg);
        } else {
            toast.error(data.msg)
        }
    }
    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Tag To IRCTC
                    </h5>
                    <button
                        type="button"
                        className="close"
                        onClick={() => setModalIsOpen(false)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="d-flex justify-content-between m-2 modal-header">
                        <div>City</div>
                        <div> Station </div>
                        <div> stationCode </div>
                        <div> Tag </div>
                    </div>
                    <div>
                        {
                            data.map((res, i) => {
                                return (
                                    <div key={i} className="d-flex justify-content-between m-2">
                                        <div className="w-25"> {res.city ? res.city : "None"} </div>
                                        <div className="w-25"> {res.stationName} </div>
                                        <div className="w-25"> {res.stationCode} </div>
                                        <Button variant="contained" onClick={() => tagfunction(res.stationCode, i)}>{isTagResLoading && buttonLoading === i ? "Loading" : "Tag"}</Button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
