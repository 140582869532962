import Modal from "react-modal";
import React from "react";
import { Button } from "@mui/material";
import TextField from "../Common/MuiTextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useUpdateRest } from "../hooks/restaurants";
import { useParams } from "react-router-dom";

const ScheduleClosed = ({ isOpen, setOpen, data, resId }) => {
  const customStyles = {
    content: {
      top: "8%",
      height: "90%",
      left: "12%",
      width: "75%",
      background: "none",
      border: "none",
    },
  };

  const validate = Yup.object({
    closedFrom: Yup.date(),
    closedTo : Yup.date()
  })
  const { id } = useParams();
  const { handleUpdateRest } = useUpdateRest(id);

  const submitResturantUpdate = async(value) => {
    const res = await handleUpdateRest(value);
    if (res.success) {
      toast.success("Resturant Updated");
      setOpen(false)
     
    }
    else {
      toast.error(res.error);
    }
  }

  return (
    <Modal
      contentLabel="Schedule Closed"
      style={customStyles}
      isOpen={isOpen}
      ariaHideApp={false}
    >
      <div className="content-wrapper h-auto">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Schedule Closed
            </h5>
            <button
              type="button"
              className="close"
              onClick={() => setOpen(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Formik
              initialValues={{
                closedFrom: "",
                closedTo: "",
              }}
              validationSchema={validate}
              onSubmit={submitResturantUpdate}
            >
              <Form>
                <div className="d-flex justify-content-between m-2 modal-header">
                  <TextField
                    type="date"
                    label="Closed From"
                    name="closedFrom"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Closed From"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="d-flex justify-content-between m-2 modal-header">
                  <TextField
                    type="date"
                    label="Closed To"
                    name="closedTo"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Closed To"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button variant="contained" type="submit" className="m-2">
                    {" "}
                    Update{" "}
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleClosed;
