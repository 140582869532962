import Skeleton from "react-loading-skeleton";

    // rows skeleton
    export const restaurantRows = Array.from({ length: 20 }, (_, index) => ({
        _id: `restro-${index}`,
        name: <Skeleton width={100} />,
        contact_details: {
            contact_number: <Skeleton width={150} />,
        },
        wallet: {
            $numberDecimal: <Skeleton width={50} />,
        },
        station: [
            {
                stationName: <Skeleton width={100} />,
                stationCode: <Skeleton width={50} />,
            },
        ],
        status: <Skeleton width={80} />,
    }));