import React from 'react'
import ResturantComp from '../../components/ResturantContent/ResturantComp';
import Sidenav from '../../components/Sidenav/Sidenav';
import Header from '../../components/Header/Header';
import { useUser } from '../../components/hooks/user';

const RestPage = () => {
    const { user } = useUser();
    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <ResturantComp />
        </div>
    )
}

export default RestPage;