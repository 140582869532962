import moment from "moment";
import { TOKEN_STORE_NAME } from "../config";
import 'moment-timezone';

export const getTokens = () => {
    const data = localStorage.getItem(TOKEN_STORE_NAME);
    if (data) {
        const token = JSON.parse(data)
        return {
            accessToken: token,
        }
    } else {
        return {
            accessToken: '',
        }
    }
}


export const serializeQueryStrings = function (obj) {
    const str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p) && obj[p]) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


export const formatDateTime = (datetime, format = 'YYYY-MM-DD', day = false) => {

    if (day) {
        return moment(datetime).tz('Asia/Kolkata').startOf('day').fromNow();
    }
    if (datetime === 'today') {
        return moment().tz('Asia/Kolkata').format(format)
    } else if (datetime) {
        return moment(datetime).tz('Asia/Kolkata').format(format)
    } else {
        return '-'

    }
}