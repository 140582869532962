import UpdateCancelReqForm from "../form/UpdateCancelreqform";
import UpdateEnquiryForm from "../form/UpdateEnquiryForm";

export default function UpdateCancelreqModel({ flag, item, changeState }) {


    return (
        <div
            className="modal fade"
            id="exampleModalcancelupdate"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            tabIndex={-1}

        >
            <div className="modal-dialog" role="document"  >
                {!flag ? (
                    <UpdateCancelReqForm
                        className="modal-content"
                        data={item}
                        changeState={changeState}
                    />


                ) : (
                    "loading"
                )}

                {/* <UpdateEnquiryForm
            className="modal-content"
            data={[]}
            changeState={changeState}
          /> */}
            </div>
        </div>
    );
}
