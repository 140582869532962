import React, {  useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { useUpdateEnq } from "../hooks/enquiry";
import { useUser } from "../hooks/user";
import TextField from "../Common/MuiTextField";
import SubmitButton from "../Common/Button";

export default function UpdateEnquiryForm({ data ,changeState}) {
const closeItemModal= useRef() 

const {user}=   useUser()
 const  {handleUpdateEnq} = useUpdateEnq(data._id)


const updateitem = async(data) =>{ 
  const res = await handleUpdateEnq(data);
  if (res.success) {
    toast.success("Enquiry Updated");
    closeItemModal.current.click()
    
  } else {
    toast.error(res.error);
  }
}

  
  const validate = Yup.object({
    
    status : Yup.string().required("select Status"),
    remarks: Yup.string().required("Enter Remarks"),
    

  });
  return (
    <Formik
    initialValues = {{
        status:data.status,
      
        remarks: data.remarks,
       
        updatedBy : user.name,
    }}
      validationSchema={validate}
      onSubmit={updateitem}
      enableReinitialize
    >
         {formik => (
      <div className="modal-content" >
        <div className="modal-header" >
          <h5 className="modal-title" id="exampleModalLabel">
            Update Enquiry Form
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={changeState}
          >
            <span aria-hidden="true"  onClick={changeState}>&times;</span>
          </button>
        </div>

        <div className="modal-body " >
          <Form className="form-row" onSubmit={formik.handleSubmit}> 
          <div className="form-group col-md-6">
          
          
          <TextField
              label="Name"
              name= "name"
              value={data.name}
              disabled
              placeholder="Name"
              sx={{ mb: 3 }}
            />

         </div>



         

          <div className="form-group col-md-6 item-div">

<Field as="select" name="status"  className="custom-select mr-sm-2"  >
             <option value="closed">Closed</option>
             <option value="inProcess">InProcess</option>
             <option value="active">Active</option>
             <option value="created">Created</option>
           </Field>
            

            </div>
          <div className="form-group col-md-6">
            
           <textarea type="text" name="remarks" value={formik.values.remarks} onChange={formik.handleChange}  style={{width:"100%"}} />
          </div>
          <div className="form-group col-md-6">
            
           <textarea type="text" name="description" value={data.description} disabled  style={{width:"100%"}}> </textarea>
          </div>

          <div className="form-group col-md-6">
          
          
          <TextField
              label="Mobile"
              name= "mobile"
              value={data.mobile}
              disabled
              placeholder="Mobile"
              sx={{ mb: 3 }}
            />

         </div>

 <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={changeState}
                ref={closeItemModal}
              >
                Close
              </button>
              <SubmitButton  fullWidth label="Update" />
        
            </div>


<div>
           <span className="form-error">{formik.errors.status && formik.touched.status ? formik.errors.status: null}</span>
</div>
<div>

           <span className="form-error">{formik.errors.remarks && formik.touched.remarks ? formik.errors.remarks: null}</span>
</div>
           
          </Form>
        </div>
      </div>
         )}
    </Formik>
  );
}
