import React from 'react';

export default function ItemDetail({ item }) {
    return (
        <div className="" >
            <div className="row">
                <div className="col-md-6">
                    <h6 className="text-secondary">Item Name</h6>
                    <p>{item.itemName}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Item Id</h6>
                    <p>{item.itemId}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h6 className="text-secondary">Item Type</h6>
                    <p>{item.itemType}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Cusine Type</h6>
                    <p>{item.cusineType}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h6 className="text-secondary">Description</h6>
                    <p style={{ width: '100px' }}>{item.description}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Availability</h6>
                    <p>{item.availability ? 'Yes' : 'No'}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-6">
                    <h6 className="text-secondary">Yatri Restro Price</h6>
                    <p>{!item.yrPrice ? '' : item.yrPrice.$numberDecimal}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Cost Price</h6>
                    <p>{!item.basePrice ? '' : item.basePrice.$numberDecimal}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-6">
                    <h6 className="text-secondary">Opening Time</h6>
                    <p>{item.opening_time}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Closing Time</h6>
                    <p>{item.closing_time}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-6">
                    <h6 className="text-secondary">Category</h6>
                    <p>{item.category}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Best Selling</h6>
                    <p>{item.isBestSelling ? 'Yes' : 'No'}</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-6">
                    <h6 className="text-secondary">GST</h6>
                    <p>{item.gst ? item.gst.$numberDecimal : ''}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Festive Item</h6>
                    <p>{item.isFestiveItem ? 'Yes' : 'No'}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h6 className="text-secondary">Festive End Date</h6>
                    <p>{item.festiveEndDate}</p>
                </div>
                <div className="col-md-6">
                    <h6 className="text-secondary">Festive Start Date</h6>
                    <p>{item.festiveStartDate}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <button
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                    >
                        Ok <i className="fa fa-check"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}