import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import { useState } from "react";


const RESTAURANTS_QUERY_KEY = 'restaurants';
const RESTAURANT_QUERY_KEY = 'restaurant';

// Get Restaurants List
export function useRestaurantsList() {
    const { isLoading, data } = useQuery([RESTAURANTS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data } = await fetchJson(`${API_HOST_URL}/${endpoints.restaurant.list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
    })
    return { restaurantsData: data, restaurantsIsLoading: isLoading }
}

export const stripResturantsList = (data) => {
    // console.log(data)
    return {
        value: data._id,
        label: `${data.name}`
    }
}
export const stripResturantsLargeList = (data) => {
    // console.log(data)
    return {
        value: data._id,
        data: data,
        label: `${data.name}`
    }
}


// Get Restaurnts List
const getRestaurants = async (key) => {
    const param = key.queryKey[1].param;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(
                `${API_HOST_URL}/${endpoints.restaurant.list_pagination}${param}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
                true,
            );
            return await res.json();
        }
        return {
            success: false,
            detail: 'err',
        };
    } catch (err) {
        return {
            success: false,
            detail: err,
        };
    }
};

export function useRestaurantsPaginatedList(defaultParam = null, enabled = false) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [RESTAURANTS_QUERY_KEY, { param: param, defaultParam }],
        getRestaurants,
        {
            // cacheTime: Infinity,
            // staleTime: 30_000,
            cacheTime: 0,
            staleTime: 1,
            enabled: enabled,
        },
    );
    return {
        restaurantsData: data,
        restaurantsIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    };
}


// Get Restaurant Detail
export function useRestaurantDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [RESTAURANT_QUERY_KEY], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.restaurant.list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { restaurantData: data, restaurantIsLoading: isLoading, isError, status }
}


// Add Restaurant Hook
export function useRestaurantAdd() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.restaurant.add}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handleAddRestaurant: async (values) => {
            try {
                const res = await mutation.mutateAsync({ ...values });
                const data = await res.json();
                // console.log('hooks data -> ', data);
                if (data.success) {
                    await queryClient.invalidateQueries([RESTAURANTS_QUERY_KEY])
                }
                return data;
            } catch (err) {
                return {
                    success: false,
                    error: err
                }
            }
        },
        addRestaurantIsLoading: mutation.isLoading,
    }
}

// update Restruant


export function useUpdateRest(id) {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.restaurant.update}/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true))
    return {
        handleUpdateRest: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([RESTAURANT_QUERY_KEY])
                }
                return data

            } catch (err) {
                return {
                    success: false
                }
            }
        },
        handleUpdateIsLoading: mutation.isLoading,
    }
}

export function UseTagResturant() {
    const { accessToken } = getTokens();
    const mutation = useMutation(({ resId, stCode }) => fetchJson(`${API_HOST_URL}/${endpoints.restaurant.tag}/${stCode}/${resId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true));
    return {
        handleTagResturant: async ({ resId, stCode }) => {
            try {
                const res = await mutation.mutateAsync({ resId, stCode })
                const data = await res.json();
                return { ...data, msg: "Tagged to IRCTC Successfully" };
            } catch (error) {
                return {
                    success: false,
                    msg: "something went wrong"
                }
            }
        },
        isTagResLoading: mutation.isLoading
    }
}


// AWS File Upload
export function UseFileUploadAWS() {
    const { accessToken } = getTokens();
    const mutation = useMutation((formData) => fetchJson(`${API_HOST_URL}/${endpoints.restaurant.file_upload}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        body: formData
    }, true));
    return {
        handleUploadFile: async (formData) => {
            try {
                const res = await mutation.mutateAsync(formData)
                const data = await res.json();

                return data;
            } catch (error) {
                return {
                    success: false,
                    error: "something went wrong"
                }
            }
        },
        isFileUploading: mutation.isLoading
    }
}

