import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
export function TopRes({ duration  , orderData }) {
  let map = new Map();
  for(let i=0; i<orderData.length; i++){
    if(map.has(orderData[i].resturant?.name)){
      map.set(orderData[i].resturant?.name , map.get(orderData[i].resturant?.name) + 1);
    }else{
      map.set(orderData[i].resturant?.name , 1);
    }
  }
  map = new Map([...map.entries()].sort((a , b) => b[1] - a[1]));

  const array = [...map];

  const array2 = array.splice(0 , 10);

  return (
    <Card sx={{height:'482px'}}>
      <CardHeader title={`Top Resturants`} />
      <Divider />
      <List>
        {array2.map((value , i) => {
          return (
            <div key={i}>
              <List style={{display:'flex' , justifyContent:'space-between'}}>
                <div className='mx-4'>{value[0]}</div>
                <div className='mx-4'>{value[1]}</div>
              </List>
              <Divider />
            </div>
          )
        })}
      </List>
    </Card>
  );
}