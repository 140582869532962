import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { formatDateTime } from '../../../../utils/core';
import { Link } from 'react-router-dom';
// import { useRef } from 'react'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    width: 800,
};



export default function RestroInfoModal({ data, modal, setModal }) {
    data = data?.resturant || {};
    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Restaurant Info</h5>
                            <IconButton onClick={() => setModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='modal-content card pd-20 pd-sm-20 bg-light'>

                            <Link to={`/restaurants/${data?._id}`}><p className="mb-1 font-p2 font-bolder text-info">{data?.name?.toUpperCase()} </p></Link>

                            <p className="mb-1">☎ {data?.contact_details?.contact_number ? data?.contact_details?.contact_number : ''} {data?.contact_details?.contact_number ? `/ ${data?.contact_details?.contact_number}` : ''}</p>
                            <p className="mb-1">📧 {data?.contact_details?.email ?? '-'}</p>
                            <hr />
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="mb-1">
                                        Opening Time: <span className='font-bold font-p3'>{moment(data?.opening_time, "hh:mm A").format("hh:mm A")}</span>
                                    </p>
                                    <p className="mb-1">
                                        Min. Order Time: <span className='font-bold font-p3'>{data?.min_order_time ?? 0} mins</span>
                                    </p>
                                </div>
                                <div>
                                    <p className="mb-1">
                                        Closing Time: <span className='font-bold font-p3'>{moment(data?.closing_time, "hh:mm A").format("hh:mm A")}</span>
                                    </p>
                                    <p className="mb-1">
                                        Min. Order Amount: <span className='font-bold font-p3'>Rs. {data?.min_order_amount?.$numberDecimal ?? 0}</span>
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <p className="mb-1">
                                Address: <span className='font-bold font-p3'>{data?.address ?? '-'}</span>
                            </p>
                            <p className="mb-1">
                                Contact Person: <span className='font-bold font-p3'>{data?.contact_details?.contact_person ?? '-'}</span>
                            </p>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}