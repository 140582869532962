import React from 'react'
import UpdateVendorForm from '../form/UpdateVendorForm'

const UpdateVendorModel = ({data}) => {

  return (
    <div
      className="modal fade"
      id="exampleModalvendor"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
     tabIndex={-1}
     
    >
      <div className="modal-dialog" role="document"  >
      <UpdateVendorForm
            className="modal-content"
            data={data}
          
          />
        {/* {flag ? (
          <UpdateVendorForm
            className="modal-content"
            // data={data}
          
          />

        
        
        ) : (
          "loading"
        )} */}
      </div>
    </div>
  )
}

export default UpdateVendorModel