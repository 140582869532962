import React from 'react'
import { useSwapOrder } from '../../../hooks/orders';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import OrderSwapForm from '../../Forms/OrderSwapForm';
import toast from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    width: 700,
};

export default function OrderSwapModal({ data, modal, setModal }) {
    const { handleSwapOrder, IsOrderSwaping } = useSwapOrder(data._id);
    
    const handleSwapOrderSubmit = async (values) => {

        const toastId = toast.loading('Swapping...');
        const res = await handleSwapOrder(values);
        if (res.success) {
            toast.success("Order Updated Successfully", { id: toastId });
            setModal(false)
        } else {
            toast.error(res.error, { id: toastId });
        }
    }
    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Swap Order</h5>
                            <IconButton onClick={() => setModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div>
                            <OrderSwapForm
                                data={data}
                                setModal={setModal}
                                handleSubmit={handleSwapOrderSubmit}
                                isLoading={IsOrderSwaping}
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}