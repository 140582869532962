import { AccordionDetails, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';

const VisibleRestCard = ({item,data,e,link}) => {
  // console.log(link)
  return (
    <AccordionDetails sx={{ width: "100%",boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px' }} >
    <Typography sx={{ width: "100%" }} component={"div"}>
      <Typography className="stty2" component={"div"}>
        <Typography component={"div"}>
          <Typography component={"h4"}>
            {item.name}
          </Typography>
        </Typography>

        <Typography className="stst4" component={"div"}>
          <Typography component={"span"}>
            {item.rating}⭐
          </Typography>
          <Link to={link} style={{color:'white',textDecoration:'none'}}>
          <Typography  component={"button"}   className='btn btn-dark'  onClick={() => {
                          localStorage.setItem(
                            "station",
                            JSON.stringify({
                              trainNumber:
                                data.trainInfo.trainNumber,
                              trainName:
                                data.trainInfo.trainName,
                              stationName:
                                e.stationName,
                              stationCode:
                                e.stationCode,
                            })
                          );
                        }}>
                      
            Food Menu
          </Typography>
                    </Link>  
        </Typography>
        <Typography component={"div"} sx={{fontSize:'12px',display:'flex',gap:'10px'}}>
          <Typography component={"span"} sx={{fontSize:'12px'}}>
          <Typography component={"span"} sx={{fontWeight:'700',fontSize:'12px'}}>Min order</Typography> {item.min_order_amount.$numberDecimal}
            /-{" "}
          </Typography>

          <Typography component={"span"} sx={{fontSize:'12px'}}>
          <Typography component={"span"} sx={{fontWeight:'700',fontSize:'12px'}}>opening time</Typography> {moment(item.opening_time, "hh:mm a").format("hh:mm a")}
       
          </Typography>
          <Typography component={"span"} sx={{fontSize:'12px'}}>
          <Typography component={"span"} sx={{fontWeight:'700',fontSize:'12px'}}>closing time</Typography> {moment(item.closing_time, "hh:mm a").format("hh:mm a")}
         
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  </AccordionDetails>
  )
}

export default VisibleRestCard