import { useQuery } from "@tanstack/react-query";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";

const PNR_QUERY_KEY = 'pnrkey'
const TRAIN_QUERY_KEY = 'trainnokey'

export function usePnrStopage(pnr) {

    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [PNR_QUERY_KEY,pnr], async () => {
            if(pnr.length===10){

         
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.find.pnrStopage}/?pnr=${pnr}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }else{
            return "Enter 10 Digit PNR No."
        }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
    })
    return { pnrStopage: data, pnrIsLoading: isLoading, isError, status }
}




export function useTrainStopage(train,enabled) {
// console.log("fun",train)
    // const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [TRAIN_QUERY_KEY,train], async () => {
            if(train.length===5){
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.find.trainstopage}/?trainNo=${train}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }else{
            return "Enter 5 Digit Train No."
        }
        }, {
        // cacheTime: 0,
        // staleTime: 1,
        // retry: 3,
        refetchOnWindowFocus: true,
        refetchOnMount: true, // ms
        enabled:enabled
    })
    return { trainStopage: data, trainIsLoading: isLoading, isError, status }
}