/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import TextField from '../../Common/Fields';
import { PAYMENT_TYPE_OPTIONS } from './Options';
import SelectField from '../../Common/SelectField';
import { stripResturantsList, useRestaurantsList } from '../../hooks/restaurants';

export default function OrderSwapForm({ isLoading, handleSubmit, data, setModal }) {
    const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();

    const validationSchema = Yup.object().shape({
        resturant: Yup.string().nullable().required('Restaurant is required'),
        coach: Yup.string().required('Coach is required'),
        berth: Yup.string().required('Berth is required'),
        paymentType: Yup.string().nullable().required('Payment type is required'),
    });

    return (
        <Formik
            initialValues={{
                "resturant": data?.resturant?._id ?? null,
                "coach": data?.deliveryDetails?.coach ?? '',
                "berth": data?.deliveryDetails?.berth ?? '',
                "paymentType": data?.paymentType ?? null,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-6">
                            <Typography variant='caption'>Coach </Typography>
                            <TextField label='Coach *' type="text" name='coach' placeholder='Coach' />
                        </div>
                        <div className="col-md-6">
                            <Typography variant='caption'>Berth </Typography>
                            <TextField label='Berth *' type="text" name='berth' placeholder='Berth' />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className="col-md-6">
                            <Typography variant='caption'>Restaurant </Typography>
                            <SelectField
                                name='resturant'
                                options={restaurantsData?.map(stripResturantsList)}
                                value={restaurantsData?.map(stripResturantsList).filter(function (option) {
                                    return option.value === formik.values.resturant;
                                })}
                                onChange={(e) => formik.setFieldValue('resturant', e.value)}
                                isLoading={restaurantsIsLoading}
                            />
                        </div>

                        <div className="col-md-6">
                            <Typography variant='caption'>Payment Type </Typography>
                            <SelectField
                                name='paymentType'
                                options={PAYMENT_TYPE_OPTIONS}
                                value={PAYMENT_TYPE_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.paymentType;
                                })}
                                onChange={(e) => formik.setFieldValue('paymentType', e.value)}
                                isSearchable={false}
                            />
                        </div>
                    </div>


                    <div className='d-flex justify-content-between align-items-center'>

                        <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Modify the Order
                            </LoadingButton>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
