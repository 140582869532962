import React, { useState} from 'react'
import DataTable from '../Common/DataTableBase';
import moment from 'moment';
import { useVendorsList } from '../hooks/vendors';
import FilterComponent from '../Common/FilterComponent';
import { OutlinedButton } from '../Common/Button';
import AddVendorModal from '../model/AddVendorModal';
import UpdateVendorModel from '../model/UpdateVendorModel';

export default function VendorsListPage() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { vendorsData, vendorsIsLoading } = useVendorsList();
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
const [state,setState] = useState([])
  

const arr = vendorsData || []
const active = arr.filter(function(item)
{
    return item.isActive ===true;
});
// console.log(active, "active")
    // Table Header
    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile
        },
        {
            name: 'Email',
            selector: row => row.email
        },
        {
            name: 'Status',
            selector: row => row.isActive ? 'Active' : 'Deactivate',
        },
        {
            name: 'Created at',
            selector: row => moment(row.createdAt).format('DD-MM-YYYY')
        },
        {
            name: 'Updated at',
            selector: row => moment(row.updatedAt).format('DD-MM-YYYY hh:mm')
        },
        {
            name: 'Action',
            selector: row => <button        type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModalvendor"
            data-whatever="@getbootstrap"
            onClick={() =>{
              setState(row)
            } }
            >Update</button>
        },
       
    ];

    // Filter data
    const filteredItems = vendorsData && !vendorsIsLoading ? vendorsData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    ) : [];
    return (
    <>
 
        <div className="card pd-20 pd-sm-40">

            <h6 className="card-body-title">All Vendors</h6>
            <p className="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>

            <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1">
                  <i className="fas fa-store" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Vendors</span>
                  <span className="info-box-number">{arr.length}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1">
                  <i className="fas fa-shopping-cart" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Active Vendors</span>
                  <span className="info-box-number">{active.length}</span>
                </div>
              </div>
            </div>




            <div className="clearfix hidden-md-up" />
          
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">InActive Vendors</span>
                  <span className="info-box-number">
                    {arr.length - active.length}
                  </span>
                </div>
              </div>
            </div>

      
          </div>

            <AddVendorModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
            />
            <OutlinedButton className='col-2' label='Add new vendor' onClick={() => setModalIsOpen(true)} />

            <div className="table-wrapper">
                {
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        progressPending={vendorsIsLoading}
                        // conditionalRowStyles={conditionalRowStyles}

                        subHeaderAlign="right"
                        subHeaderWrap
                        subHeaderComponent={subHeaderComponent}
                        subHeader
                    />
                }
            </div>
        </div>


        < UpdateVendorModel
            id="exampleModalvendor"
            
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data={state} 
            
            // flag = {flag}
            // changeState = {changeState}
          />
        </>
    )
}
