import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "../Common/MuiTextField";
import { stripCusineList, stripItemList } from "../hooks/vendors";
import { toast } from "react-toastify";
import { useCusineType, useItemType, useUpdateItem } from "../hooks/item";
import SelectField from "../Common/SelectField";
import Loader2 from "../Common/Loader2";
import { Typography } from "@mui/material";


export default function UpdateItemForm({ data }) {
    const closeref = useRef()
    const { cusineTypeData } = useCusineType();
    const { itemTypeData } = useItemType()
    const availability_options = [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ];

    const { handleUpdateItem } = useUpdateItem(data._id)

    const updateitem = async (data) => {
        const res = await handleUpdateItem(data);
        if (res.success) {
            toast.success("Item Updated");
            closeref.current.click()
        } else {
            toast.error(res.error);
        }
    }



    const validate = Yup.object({
        itemName: Yup.string().min(2).required("Please enter Item name"),
        description: Yup.string().required("Please enter Description"),
        // opening_time: Yup.string().required("Please select Opening time"),
        // closing_time: Yup.string().required("Please select Closing time"),
        category: Yup.string().required("Please select category"),
        // isFeatured: Yup.string().required("Please select Featured or not"),
        // isFestiveItem: Yup.string().required("Please select Festive or not"),
        // isBestSelling: Yup.string().required("Please select Best Selling or not"),
        isRecomended: Yup.string().required("Please select Recomanded or not"),
        yrPrice: Yup.string().required("Please enter Yr price"),
        basePrice: Yup.string().required("Please enter Base price"),
        gst: Yup.string().required("Please Enter Gst"),
    });
    return (
        <Formik
            initialValues={{
                "closing_time": data.closing_time,
                "opening_time": data.opening_time,
                "availability": data.availability,
                "isBestSelling": data.isBestSelling,
                "isFeatured": data.isFeatured,
                "isFestiveItem": data.isFestiveItem,
                // "festiveEndDate": data.festiveEndDate,
                // "festiveStartDate": data.festiveStartDate,
                "gst": data.gst.$numberDecimal,
                "cusineType": data.cusineType,
                "description": data.description,
                "itemType": data.itemType,
                "itemName": data.itemName,
                "category": data.category,
                "basePrice": data.basePrice.$numberDecimal,
                "yrPrice": data.yrPrice.$numberDecimal,
                "isRecomended": true
            }}
            validationSchema={validate}
            onSubmit={updateitem}
            // onSubmit={(val)=>console.log(val)}
            enableReinitialize
        >
            {formik => (
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Update Item
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeref}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={formik.handleSubmit} className="row g-3">
                            <div className="col-md-6">
                                <TextField
                                    label="Item Name"
                                    name="itemName"
                                    placeholder="Item Name"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <textarea
                                    name="description"
                                    placeholder="Description"
                                    style={{ width: "100%" }}
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                ></textarea>
                            </div>
                            <div className="col-md-6">
                                <Typography variant="h9" color="#949494">
                                    Item Type <span className="req">*</span>
                                </Typography>
                                {itemTypeData ? (
                                    <SelectField
                                        options={itemTypeData.map(stripItemList)}
                                        onChange={(e) => formik.setFieldValue("itemType", e.value)}
                                        name="itemType"
                                    />
                                ) : (
                                    <Loader2 color="inherit" />
                                )}
                            </div>
                            <div className="col-md-6">
                                <Typography variant="h9" color="#949494">
                                    Cusine Type <span className="req">*</span>
                                </Typography>
                                {cusineTypeData ? (
                                    <SelectField
                                        options={cusineTypeData.map(stripCusineList)}
                                        onChange={(e) => formik.setFieldValue("cusineType", e.value)}
                                        name="cusineType"
                                    />
                                ) : (
                                    <Loader2 color="inherit" />
                                )}
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Yatri Restro price"
                                    name="yrPrice"
                                    placeholder="yrPrice"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="basePrice"
                                    name="basePrice"
                                    placeholder="basePrice"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="opening_time" className="form-label">
                                    Opening Time
                                </label>
                                <input
                                    type="time"
                                    className="form-control"
                                    autoComplete="off"
                                    name="opening_time"
                                    id="opening_time"
                                    placeholder="Opening Time"
                                    value={formik.values.opening_time}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <span className="form-error">
                                    {formik.errors.opening_time && formik.touched.opening_time
                                        ? formik.errors.opening_time
                                        : null}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="closing_time" className="form-label">
                                    Closing Time
                                </label>
                                <input
                                    type="time"
                                    autoComplete="off"
                                    name="closing_time"
                                    className="form-control"
                                    id="closing_time"
                                    placeholder="Closing Time"
                                    value={formik.values.closing_time}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <span className="form-error">
                                    {formik.errors.closing_time && formik.touched.closing_time
                                        ? formik.errors.closing_time
                                        : null}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="isFeatured" className="form-label">
                                    Featured
                                </label>
                                <select
                                    name="isFeatured"
                                    id="isFeatured"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.isFeatured}
                                    aria-label="Default select example"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                <span className="form-error">
                                    {formik.errors.isFeatured && formik.touched.isFeatured
                                        ? formik.errors.isFeatured
                                        : null}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="isFestiveItem" className="form-label">
                                    Festive Item
                                </label>
                                <select
                                    name="isFestiveItem"
                                    id="isFestiveItem"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.isFestiveItem}
                                    aria-label="Default select example"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                <span className="form-error">
                                    {formik.errors.isFestiveItem && formik.touched.isFestiveItem
                                        ? formik.errors.isFestiveItem
                                        : null}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label>Festive Start Date</label>
                                <input
                                    type="date"
                                    name="festiveStartDate"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.festiveStartDate}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Festive End Date</label>
                                <input
                                    type="date"
                                    name="festiveEndDate"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.festiveEndDate}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="isBestSelling" className="form-label">
                                    Best Selling
                                </label>
                                <select
                                    name="isBestSelling"
                                    id="isBestSelling"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.isBestSelling}
                                    aria-label="Default select example"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                <span className="form-error">
                                    {formik.errors.isBestSelling && formik.touched.isBestSelling
                                        ? formik.errors.isBestSelling
                                        : null}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="isRecomended" className="form-label">
                                    Recommended
                                </label>
                                <select
                                    name="isRecomended"
                                    id="isRecomended"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.isRecomended}
                                    aria-label="Default select example"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                                <span className="form-error">
                                    {formik.errors.isRecomended && formik.touched.isRecomended
                                        ? formik.errors.isRecomended
                                        : null}
                                </span>
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="GST"
                                    name="gst"
                                    placeholder="GST"
                                    sx={{ mb: 3 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="category" className="form-label">
                                    Category
                                </label>
                                <select
                                    name="category"
                                    id="category"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.category}
                                    aria-label="Default select example"
                                >
                                    <option value="veg">VEG</option>
                                    <option value="nonVeg">NON-VEG</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label>Availability</label>
                                <SelectField
                                    name="availability"
                                    options={availability_options}
                                    value={availability_options.filter(function (option) {
                                        return option.value === formik.values.availability;
                                    })}
                                    onChange={(e) => formik.setFieldValue("availability", e.value)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button className="btn btn-success" type="submit">
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    );
}
