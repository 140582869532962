import React from "react";
import { Formik, Form } from "formik";
import { InputField, RadioField } from "../Common/Fields";
import * as Yup from "yup";
// import "yup-phone";
// import { useRouter } from "next/router";

export default function OrderInfoForm({
  isLoading,
  handleSubmit,
  restId,
  pnrNumber,
  item,
  discount,
  setPaymentText,
  changePayment,
  setDiscount,
  paymenttext,total_amount 
}) {
  // const router = useRouter();
  // const id = router.query.slug;

  const stationData = JSON.parse(localStorage.getItem("station"));

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const validate = Yup.object({
    deliveryDetails: Yup.object().shape({
      pnr: Yup.number().test(
        "len",
        "PNR is a 10-digit number.",
        (val) => val && val.toString().length === 10
      ),
      passengerName: Yup.string().required("Passenger Name is Required."),
      berth: Yup.string().required("Enter Seat No."),
      coach: Yup.string().required("Enter coach."),
      passengerCount: Yup.string().required("Enter passengerCount."),
      trainName: Yup.string().required("Enter Train Name."),
      trainNo: Yup.string().required("Enter Train Number."),
      station: Yup.string().required("Enter Station."),
      stationCode: Yup.string().required("Enter stationCode."),
    }),
    paymentType: Yup.string().required("Payment method is required."),
    journeyDate: Yup.string().required("Journey Date is required."),
    email: Yup.string().email().required("Email is required"),
    mobile: Yup.string()
      .required("Enter your valid mobile number."),
  });

  return (
    <Formik
      initialValues={{
        resturant: restId,
        mobile: "",
        email: "",
        deliveryDetails: {
          passengerName: "",
          pnr: pnrNumber !== undefined ? pnrNumber : "",
          trainNo: stationData ? stationData.trainNumber : "",
          trainName: stationData ? stationData.trainName : "",
          station: stationData ? stationData.stationName : "",
          stationCode: stationData ? stationData.stationCode : "",
          berth: "",
          coach: "",
          passengerCount: "",
        },
        orderItems: item ? item.items : [],
        journeyDate: "",
        paymentType: paymenttext=== 'ONLINE'? "CASH_ON_DELIVERY" : 'ONLINE',
      }}
      validationSchema={validate}
      onSubmit={handleSubmit}
      // onSubmit={(val) => {
      //   console.log(val);
      // }}
      enableReinitialize
    >
      {(formik) => (
        <>
          <Form>
            <div className="row">
              <div className="col-6">
                <InputField
                  type="text"
                  name="mobile"
                  placeholder="Mobile Number"
                />
              </div>
              <div className="col-6">
                <InputField type="email" name="email" placeholder="Email" />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <InputField
                  type="text"
                  name="deliveryDetails.passengerName"
                  placeholder="Passenger Name"
                />
              </div>
              <div className="col-6">
                <InputField
                  type="text"
                  name="deliveryDetails.pnr"
                  placeholder="PNR"
                  disabled={pnrNumber !== null ? true : false}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <InputField
                  type="text"
                  name="deliveryDetails.coach"
                  placeholder="Coach"
                />
              </div>
              <div className="col-6">
                <InputField
                  type="text"
                  name="deliveryDetails.berth"
                  placeholder="Seat No."
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <InputField
                  type="text"
                  name="deliveryDetails.passengerCount"
                  placeholder="Number of  Passenger"
                />
              </div>
              <div className="col-6">
                <InputField
                  min={disablePastDate()}
                  type="date"
                  name="journeyDate"
                  placeholder="Journey Date"
                />
          
              </div>
            </div>

            <hr />
            <div className="col-12">
              <div className="form-group">
                <RadioField
                  label="Payment Method"
                 onClick = {()=>{
                  //  changePayment(formik.values.paymentType)
                  //  setPaymentText(formik.values.paymentType)
                  //  console.log(formik.values.paymentType,"pa")
                  if(formik.values.paymentType!=='ONLINE'){
                    setDiscount(total_amount/10)
                  }else{
                    setDiscount(0)
                  }
                  }

                }
                
                  options={[
                    {
                      label: "Online Payment",
                      value: "ONLINE",
                    },
                    {
                      label: "Cash on Delivery",
                      value: "CASH_ON_DELIVERY",
                    },
                  ]}
                  name="paymentType"
                
                />

              
              </div>
            {discount===0 ? ""  : <p className='text-success fw-500 text-center'><span className="fw-500 bg-light">Flat Rs.{discount}</span> Off on your order!</p>}  
            </div>

            {isLoading ? (
              <button className="btn btn-dark btn-block" type="button" disabled>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {` `}Loading...
              </button>
            ) : (
              <button className="btn btn-danger btn-block" type="submit">
                {" "}
                {formik.values.paymentType === "ONLINE"
                  ? "Pay Online"
                  : "Order now"}
              </button>
            )}
          </Form>
        </>
      )}
    </Formik>
  );
}
