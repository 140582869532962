import React from 'react';
import './invoice.css';
import { Typography } from '@mui/material';
import SelectField from '../../../components/Common/SelectField';
import FormikDatePicker from '../../../components/Common/FormikDatePicker';
import { Form, Formik } from 'formik';
import { stripResturantsList, useRestaurantsList } from '../../../components/hooks/restaurants';
import { LoadingButton } from '@mui/lab';


export default function GetInvoiceForm({ data, invoice, handleSubmit, isLoading }) {
    const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();

    return (

        <Formik
            initialValues={{
                resturant: data?.resturant ?? null,
                fromDate: data?.fromDate ?? null,
                toDate: data?.toDate ?? null,
            }}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row mb-3'>
                        <div className="col-md-12">
                            <Typography variant='caption'>Select Restaurant </Typography>
                            <SelectField
                                name='resturant'
                                options={restaurantsData?.map(stripResturantsList)}
                                value={restaurantsData?.map(stripResturantsList).filter(function (option) {
                                    return option.value === formik.values.resturant;
                                })}
                                onChange={(e) => formik.setFieldValue('resturant', e.value)}
                                isLoading={restaurantsIsLoading}
                            />
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className="col-md-6">
                            <FormikDatePicker label='From' minDate='2023-04-01' name='fromDate' />
                        </div>
                        <div className="col-md-6">
                            <FormikDatePicker label='To' minDate='2023-04-01' name='toDate' />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Fetch Information
                            </LoadingButton>
                            {/* <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => formik.resetForm()}>Clear Filter</Button> */}
                        </div>

                    </div>
                    {invoice?.totalDelivered ? <div className='d-flex justify-content-center align-items-center mt-5'>
                        <div>
                            {invoice?.totalDelivered > 0 ? <p className='m-0 text-sm text-success'>Total {invoice?.totalDelivered ?? 0} deliveries </p> : <p className='m-0 text-sm text-danger'>No deliveries found</p>}
                        </div>

                    </div> : ''}
                </Form>
            )}
        </Formik>



    );
}
