import { IconButton } from '@mui/material';
import React from 'react'
import Modal from 'react-modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TransactionForm from './TransactionForm';
import { useTransactionAdd } from '../../../components/hooks/vendors';
import toast from 'react-hot-toast';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px'
    },
};

export default function AddTransactionModal({ modal, setModal }) {
    const { handleAddTransaction, isAddingTransaction } = useTransactionAdd()
    const handleSubmit = async (values, { resetForm }) => {
        const toastId = toast.loading('Processing...');
        const res = await handleAddTransaction(values);
        if (res.success) {

            toast.success('Transaction added successfully.', { id: toastId })
            setModal(false);
            resetForm({ values: '' });
        } else {
            toast.error('Failed to add transaction.', { id: toastId });
        }
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={refetch}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add new transaction"
        >
            <div className="container-fluid" style={{width:'100%'}}>

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3">Add new transaction</h5>
                    <IconButton onClick={() => setModal(false)}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <TransactionForm 
                        handleSubmit={handleSubmit}
                        isLoading={isAddingTransaction}
                        data={{}}
                    />
                </div>

            </div>

        </Modal>
    )
}
