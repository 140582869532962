import { Formik } from 'formik';
import React, { useRef } from 'react'
import { toast } from 'react-toastify';
import TextField from '../Common/MuiTextField';
import * as Yup from "yup";
import { useCusineItem } from '../hooks/extraHook';


const AddCusineForm = () => {
    const closeref = useRef();
    const { handleAddFoodType } = useCusineItem()


    const signUpSchema = Yup.object({
        cusineValue: Yup.string().required("Please enter Cusine Value"),
        cusineName: Yup.string().required("Please enter Cusine Name"),

    });

    const sendData = async (data, { resetForm }) => {
        const res = await handleAddFoodType(data);
        if (res.success) {
            toast.success("Cusine Item Updated");
            resetForm();
            closeref.current.click();
        } else {
            toast.error(res.error);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    cusineName: "",
                    cusineValue: "",


                }}
                validationSchema={signUpSchema}
                onSubmit={sendData}
                // onSubmit={(val)=>console.log(val)}
                enableReinitialize
            >
                {(formik) => (
                    <div className="">
                        {/* <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Add Item
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                ref={closeref}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                        <div className="">
                            <form onSubmit={formik.handleSubmit} className="form-row">
                                <div className="form-group col-md-6">
                                    <TextField
                                        label="cusine Name"
                                        name="cusineName"
                                        placeholder="cusine Name"
                                        sx={{ mb: 3 }}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <TextField
                                        label="cusine Value"
                                        name="cusineValue"
                                        placeholder="cusine Value"
                                        sx={{ mb: 3 }}
                                    />
                                </div>




                                <div className="d-flex gap-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button className="btn btn-success" type="submit">
                                        Add
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default AddCusineForm