import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import FilterComponent from '../Common/FilterComponent';
import { useCustomersList } from '../hooks/customers';
import Loader from '../Loader';
import moment from 'moment';

const CustomerComp = () => {
    const { customersData, customersIsLoading } = useCustomersList();
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

const arr = customersData || []
const active = arr.filter(function(item)
{
    return item.status ==="active";
});

    const paginationComponentOptions = {
        rowsPerPageText: 'Show records per page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All records',
    };

    // Table Header
    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (

            
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);




    const columns = [
        {
            name: 'Name',
            selector: row => row.full_name,
            cell: row => row.full_name
            ,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email
        },
        {
            name: 'mobile',
            selector: row => row.mobile,
        },
        {
            name: 'Active',
            selector: row => row.status ? "Yes" : "No",
        },
        {
            name: 'Created at',
            selector: row => moment(row.createdAt).format("DD-MM-YYYY hh:mm A"),
        }
    ];
    if (customersIsLoading) {
        return <Loader />
    }

    // Filter data
    const filteredItems = customersData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
    return (
<>


        <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title">Customers Management</h6>
            <p className="mg-b-20 mg-sm-b-30">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>
            {/* <UserProfileDetailModal
                modalIsOpen={userModalIsOpen}
                setModalIsOpen={setUserModalIsOpen}
                user={user}
                /> */}


<div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1">
                  <i className="fas fa-store" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Customers</span>
                  <span className="info-box-number">{arr.length}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1">
                  <i className="fas fa-shopping-cart" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Active Customers</span>
                  <span className="info-box-number">{active.length}</span>
                </div>
              </div>
            </div>




            <div className="clearfix hidden-md-up" />
          
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1">
                  <i className="fas fa-comment-dollar" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">InActive Customers</span>
                  <span className="info-box-number">
                    {arr.length - active.length}
                  </span>
                </div>
              </div>
            </div>

      
          </div>
            <div className="table-wrapper">

                {
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        direction="auto"
                        fixedHeaderScrollHeight="300px"
                        pagination
                        responsive
                        progressPending={customersIsLoading}
                        // conditionalRowStyles={conditionalRowStyles}
                        
                        subHeaderAlign="right"
                        subHeaderWrap
                        subHeaderComponent={subHeaderComponent}
                        subHeader

                        paginationComponentOptions={paginationComponentOptions}
                    />
                }
            </div>
        </div>

                        </>
    )
}


export default CustomerComp