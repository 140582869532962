import { Formik } from "formik";
import JoditEditor from "jodit-react";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import TextField from "../../components/Common/MuiTextField";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useBlogDetail, useUpdateBlog } from "../hooks/blog";
import { useNavigate, useParams } from "react-router-dom";
import Sidenav from '../Sidenav/Sidenav';
import Header from '../Header/Header';
import { useUser } from '../hooks/user';

const UpdateBlogform = () =>  {
    const editor = useRef(null);
    const [selected, setSelected] = useState([]);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [mulitimg, setMultiimg] = useState([]);
    const file1 = useRef()
    const file2ref = useRef()
    const file3 = useRef()
    const {id} = useParams()

    const navigate = useNavigate()
     const {blogData,blogIsLoading}   = useBlogDetail(id)
     const { user } = useUser();
    //  console.log(blogData)
    const {handleUpdateBlog,handleUpdateIsLoading}= useUpdateBlog(id);
   
    const validate = Yup.object({
      title: Yup.string().required("Enter title."),
      content: Yup.string().required("Enter content."),
      metaTitle: Yup.string().required("Enter metaTitle."),
      metaDescription: Yup.string().required("Enter metaDescription."),
      ogTitle: Yup.string().required("Enter ogTitle."),
      ogDescription: Yup.string().required("Enter ogDescription."),
      twitterTitle: Yup.string().required("Enter twitterTitle."),
      twitterDescription: Yup.string().required("Enter twitterDescription."),
      // keywords: Yup.string().required("Enter keywords."),
      // tags :  Yup.array().of(Yup.string()).required("Enter tags")
    });
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };
    const handleMulriChange = (event) => {
      setMultiimg(event.target.files);
    };
    const handleFile2Change = (event) => {
      setFile2(event.target.files[0]);
    };
  
    const handleSubmit = async(val, { resetForm }) => {
      const {
        title,
        content,
        metaTitle,
        ogTitle,
        tags,
        ogDescription,
        twitterTitle,
        twitterDescription,
        keywords,
        metaDescription
      } = val;
  
      const formData = new FormData();
  
      for (let i = 0; i < mulitimg.length; i++) {
        formData.append("images", mulitimg[i]);
      }
  
      formData.append("title", title);
      formData.append("twitterImage", file);
      formData.append("ogImage", file2);
      formData.append("content", content);
      formData.append("twitterTitle", twitterTitle);
      formData.append("twitterDescription", twitterDescription);
      formData.append("ogDescription", ogDescription);
      formData.append("ogTitle", ogTitle);
      formData.append("metaTitle", metaTitle);
      formData.append("metaDescription", metaDescription);
      formData.append("tags", tags);
      formData.append("keywords", keywords);
   
      const res = await handleUpdateBlog(formData);
     
      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
      if (res.success) {
        editor.current.value = ""
            file1.current.value = ""
            file3.current.value = ""
            file2ref.current.value = ""
            setSelected([])
            resetForm();
            toast.success("Blog Added Successfully!!");
            navigate('/blog')
        
      } else {
        toast.error(res.message);
      }
   
       
    }
        
       
  
    const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    // buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true
    },
    width: 800,
    height: 842
  };
  
    return (
      <>
             <Header user={user}/>
  <Sidenav/>
  <div className="content-wrapper">

 

  
        <Formik
          initialValues={{
            title:  blogData?  blogData.data!=undefined? blogData.data.title:"":"",
            content:blogData? blogData.data!=undefined? blogData.data.content:"":"",
            // images: "C:\\fakepath\\Screenshot (10).png",
            metaTitle: blogData? blogData.data!=undefined? blogData.data.metaTitle:"":"",
            metaDescription: blogData?blogData.data!=undefined?  blogData.data.metaDescription:"":"",
            ogTitle: blogData?blogData.data!=undefined? blogData.data.ogTitle:"":"",
            ogDescription:blogData? blogData.data!=undefined? blogData.data.ogDescription:"":"",
            twitterTitle:blogData?blogData.data!=undefined? blogData.data.twitterTitle:"":"",
            twitterDescription:blogData? blogData.data!=undefined? blogData.data.twitterDescription:"":"",
            tags:blogData? blogData.data!=undefined?blogData.data.tags : "":"",
            keywords:blogData? blogData.data!=undefined? blogData.data.keywords:"":"",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
          // onSubmit={(val)=>console.log(val)}
          enableReinitialize
        >
          {(formik) => (
            <div style={{ width: "70%", margin: "auto" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Blog
                </h5>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={formik.handleSubmit}
                  className="form-row"
                  id="form"
                >
                  <div className="form-group col-md-12">
                    <TextField
                      label="Title"
                      name="title"
                      placeholder="Title"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Meta Title"
                      name="metaTitle"
                      placeholder="Meta Title"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Meta Description"
                      name="metaDescription"
                      placeholder="Meta Description"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="FB Title"
                      name="ogTitle"
                      placeholder="Og Title"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Fb Description"
                      name="ogDescription"
                      placeholder="Og Description"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Twitter Title"
                      name="twitterTitle"
                      placeholder="Twitter Title"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Twitter Description"
                      name="twitterDescription"
                      placeholder="Twitter Description"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      label="Keywords"
                      name="keywords"
                      placeholder="keywords"
                      sx={{ mb: 3 }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TagsInput
                    name="tags"
                      value={ selected}
                      placeHolder="tags"
                      onChange={(val) => formik.setFieldValue("tags", val)}
                    />
                  </div>
                  <div className="form-group col-md-12">
                  <span className="form-error" style={{color:"red",fontSizeL:"10px"}}>
                      {formik.errors.tags && formik.touched.tags
                        ? formik.errors.tags
                        : null}
                    </span>
                  </div>
  
  
                  <div className="form-group col-md-4">
                    <label>Twitter Image</label>
  
                    <input
                      type="file"
                      name="twitterImage"
                      onChange={handleFile2Change}
                      ref={file1}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Fb Image</label>
  
                    <input
                      type="file"
                      ref={file2ref}
                      name="ogImage"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Images</label>
                    {/* nsanmnmasds */}
                    <input
                      type="file"
                      name="images"
                      ref={file3}
                      onChange={handleMulriChange}
                      multiple
                    />
                  </div>
  
                  <div className="form-group col-md-12" style={{ maxWidth: editorConfig.width, margin: "0 auto" }}>
                    <JoditEditor
                      ref={editor}
                      config={editorConfig}
                      value={formik.values.content}
                      tabIndex={1}
                      onChange={(newContent) =>
                        formik.setFieldValue("content", newContent)
                      }
                    />
                  </div>
  
                  <div className="modal-footer">
                    <button className="btn btn-success" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Formik>
    </div>
      </>
    );
  };
  

export default UpdateBlogform