import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import NewOrderStatusForm from "../Forms/NewOrderStatusForm";




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    width: 500,
};



export function NewOrderStatusModal({ data, newstatusmodal, setNewstatusmodal }) {

    return (
        <div>
            <Modal
                open={newstatusmodal}
                onClose={() => setNewstatusmodal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Order Status</h5>
                            <IconButton onClick={() => setNewstatusmodal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div>
                            <NewOrderStatusForm data={data} setNewstatusmodal={setNewstatusmodal} />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}