import React from 'react'
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useUser } from '../../components/hooks/user';
import BookOrderComponent from '../../components/BookOrderComponent/BookOrderComponent';

const BookOrder = () => {
    const { user} = useUser();

    return (
      <div>
        {" "}
        <Header user={user} />
        <Sidenav />
        <div className="content-wrapper">
          <BookOrderComponent/>
        </div>
      </div>
  )
}

export default BookOrder