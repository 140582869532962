import React, { useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import FilterComponent from '../Common/FilterComponent';
import Loader from '../Loader';
import { useLocationList } from '../hooks/location';



const LocationCont = () => {
    const {locationData,locationIsLoading} = useLocationList()
    const [filterText, setFilterText] =useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // console.log(locationData)


    const subHeaderComponent = useMemo(() => {
       const handleClear = () => {
           if (filterText) {
               setResetPaginationToggle(!resetPaginationToggle);
               setFilterText("");
           }
       };

       return (
           <FilterComponent
               onFilter={e => setFilterText(e.target.value)}
               onClear={handleClear}
               filterText={filterText}
           />
       );
   }, [filterText, resetPaginationToggle]);


   const columns = [
       {
           name: 'Image',
           selector: row => <div>
           <img  src= {`https://backapi.yatrirestro.com/uploads/1693287769473.png`} crossorigin="anonymous" alt={"img"} width={"100px"} height={"80px"}/>
                    </div>,
//            cell: row => <div>
// <img  src= {`https://backapi.yatrirestro.com/uploads/1693287769473.png`} alt={"img"} width={"100px"} height={"80px"}/>
//          </div>,
           sortable: true,
       },
       {
           name: 'Station Code',
           selector: row => row.stationCode,
           sortable :true
       },
       {
           name: 'Station Name',
           selector: row => row.stationName,
           cell: row=>row.stationName,
           sortable: true,
       },
     
    
   ];

   if (locationIsLoading) {
       return <Loader />
   }



      const filteredItems = locationData.filter(
       item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
   );


    
   return <>
  
  


<div className="container" style={{padding : "20px"}}>
<div className="text-right">
   
   <Link  to={"/addlocation"}  className="btn btn-dark">Add Location <i className="fa fa-plus"></i></Link>  
              </div>
   <div className="table-wrapper">
<h3 className="text-center" style={{ padding: "5px" }}>
                Location List
               </h3>
               {
                   <DataTable
                   columns={columns}
                   data={filteredItems}
                   
                   direction="auto"
                   fixedHeaderScrollHeight="300px"
                   pagination
                       responsive
                       progressPending={locationIsLoading}
                       // conditionalRowStyles={conditionalRowStyles}
                       
                       subHeaderAlign="right"
                       subHeaderWrap
                       subHeaderComponent={subHeaderComponent}
                       subHeader
                       />
                   }
           </div>
                   </div> 
   </>
}

export default LocationCont;