import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import "yup-phone";
import SubmitButton from '../Common/Button';
import { TextField, Typography } from '@mui/material';
import SelectField from '../Common/SelectField';


export default function AddUserForm({ isLoading, handleSubmit, response }) {
    const validate = Yup.object({
        name: Yup.string().min(4)
            .required('Enter your name.'),
        role: Yup.string()
            .required('Select a role.'),
        mobile: Yup.string()
            .required('Enter your mobile.'),
        email: Yup.string().email()
            .required('Enter your email.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Enter a password for the account.'),
    })

    const ROLES_OPTIONS = [
        { 'label': 'Manager', value: 'manager' },
        { 'label': 'Staff', value: 'staff' },
        { 'label': 'Support Executive', value: 'supportExecutive' },
        { 'label': 'Floor Manager', value: 'floorManager' },
        { 'label': 'Delivery Boy', value: 'dboy' },
        { 'label': 'Accountant', value: 'accountant' },
    ]
    return (
        <Formik
            initialValues={{
                name: '',
                role: '',
                mobile: '',
                email: '',
                password: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form onSubmit={formik.handleSubmit}>
                    <div className="">
                        <Typography>Role <span className='req'>*</span></Typography>
                        <SelectField
                            name='role'
                            options={ROLES_OPTIONS}
                            isSearchable={false}
                            value={ROLES_OPTIONS.filter(function (option) {
                                return option.value === formik.values.role;
                            })}
                            onChange={(e) => formik.setFieldValue('role', e.value)}
                        />
                    </div>
                    <TextField label='Name' name='name' placeholder='Name' sx={{ mb: 3, mt: 3 }} onChange={formik.handleChange}/>
                    <TextField label='Mobile' name='mobile' placeholder='Mobile' sx={{ mb: 3 }} onChange={formik.handleChange}/>
                    <TextField label='Email' name='email' placeholder='Email' sx={{ mb: 3 }} onChange={formik.handleChange}/>
                    <TextField label='Password' type="password" name='password' placeholder='Your Password' sx={{ mb: 3 }} onChange={formik.handleChange}/>

                    <SubmitButton
                        loading={isLoading}
                        fullWidth
                        label='Add User'
                    />

                    {response && !response.success && <div className="alert alert-danger mt-3" role="alert">{response.error}</div>}
                </Form>
            )}
        </Formik>
    )
}
