import { formatDateTime } from '../../../utils/core';
import { splitText } from '../../../utils/functions';
import './invoice.css';
import React, { forwardRef } from 'react';


const InvoiceContent = forwardRef((props, ref) => {
    const { data } = props;

    const outlet = data?.outlet ?? {};
    return <div className="container mt-5 border px-5 py-3" ref={ref}>
        <div>
            {/* Invoice Content */}
            <div className="row">
                <div className='m-auto px-5 mx-5'>
                    <p className='m-0 poppins-semibold text-warning text-sm'>Inspigear India Pv. Ltd.</p>
                    <p className='m-0 poppins-medium text-warning text-sm'>D/56, PC Colony, Kankarbag, Patna, Bihar-800020</p>
                    <p className='m-0 poppins-medium text-warning text-sm'>https://yatrirestro.com</p>
                </div>
                <hr className='custom-hr' />
                <div>
                    <h5 className='text-center text-shadow poppins-bold mt-3 mb-4'>Tax Invoice</h5>
                </div>
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <div className='text-sm'>
                        <p className='m-1 poppins-semibold'>Inspigear India Private Limited</p>
                        <p className='m-1 poppins-medium'>D/56, PC Colony, Kankarbag, <br />Patna, Bihar - 800020</p>
                        <p className='m-1 poppins-medium'>GSTIN: 10AAFCI5092N1ZF</p>
                        <p className='m-1 poppins-medium'>Email: support@yatrirestro.com</p>

                    </div>
                    <div className='text-sm'>
                        {/* <p className='m-1 poppins-medium'>Invoice Period: 07/05/2024 to 08/05/2024</p> */}
                        <p className='m-1 poppins-semibold'>Invoice Number: #{data?.invNo}</p>
                        <p className='m-1 poppins-medium'>Invoice Date: {formatDateTime(data?.invDate, 'DD/MM/YYYY')}</p>
                        <p className='m-1 poppins-medium'>Outlet: : {outlet?.firm_name}</p>
                        <p className='m-1 poppins-medium'>{splitText(outlet?.address, 40) ?? '-'}</p>
                        <p className='m-1 poppins-medium'>Mobile: {outlet?.contact_details?.contact_number ?? '-'}</p>
                        <p className='m-1 poppins-medium'>Email: {outlet?.contact_details?.email ?? '-'}</p>
                        <p className='m-1 poppins-medium'>GSTIN: {outlet?.gstin ?? '-'}</p>
                    </div>
                </div>
            </div>

            <table className="table table-bordered text-center mt-4">
                <thead>
                    <tr>
                        <th>S. No.</th>
                        <th>Particulars</th>
                        <th>Amount (Rs.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td className='poppins-semibold'>Total Commission</td>
                        <td>{data?.commission?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Non-Delivery Fee</td>
                        <td>{data?.undeliveryCharges?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Penalty (By IRCTC)</td>
                        <td>{data?.penalty?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>IGST</td>
                        <td>{data?.igst?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td className='poppins-semibold'>Total Payable for the Month</td>
                        <td>{data?.payableForMonth?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td className='poppins-semibold'>*Add: Previous Month Balance</td>
                        <td>{data?.previousDues?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>*Less: Online Payments received</td>
                        <td>{data?.onlinePaid?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Add: GST Collected at Source @5</td>
                        <td>{data?.gstAtSource?.$numberDecimal}</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td className='poppins-bold'>Net Payable Amount</td>
                        <td>{data?.netPayable?.$numberDecimal}</td>
                    </tr>
                </tbody>
            </table>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <div className='text-sm'>
                        <p className='m-1 poppins-semibold'>Banking Details</p>
                        <p className='m-1 poppins-medium'>Account Name:  Inspigear India Private Limited </p>
                        <p className='m-1 poppins-medium'>Account Number: 42949864799</p>
                        <p className='m-1 poppins-medium'>IFSC Code: SBIN0007506</p>
                    </div>
                    <div>
                        <img src="/img/qr-code.jpg" alt="QR Code" width={200} height={200} />
                    </div>

                </div>
            </div>

            <div className="text-sm mt-4">
                <p className='m-0'>This is a computer-generated invoice and requires no signature.</p>
                <p className='m-0'>You may write to us at support@yatrirestro.com</p>
            </div>
        </div>
    </div>
});

export default InvoiceContent;