import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { PostImage } from "../hooks/feature";
import { toast } from "react-toastify";
import { endpoints } from "../../config/endpoints";
import TextField from "../Common/MuiTextField";
import SubmitButton from "../Common/Button";
import Sidenav from "../Sidenav/Sidenav";
import Header from "../Header/Header";
import { useUser } from "../hooks/user";
import { API_HOST_URL } from "../../config";

export default function FeaturedItemForm() {
  const [image, setImage] = useState(null);
  const [imgUrl, setImgUrl] = useState({ url: "" });
  const { handlePostImage, postImageIsLoading } = PostImage();
  const { user } = useUser();


  const uploadToServer = async (e) => {
    try {
      const formData = new FormData();
      formData.append("image", image[0]);
      const response = await axios({
        method: "post",
        url: `${API_HOST_URL}/${endpoints.feature.image}`,
        data: formData,
      });
        setImgUrl(response.data.result);
        toast.success("Image Uploaded!!");
      
    } catch (error) {
      toast.error("Upload image Less than 1 mb");
      toast.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const res = await handlePostImage(values);
    if (res.success) {
      toast.success("Featured Item Added");
      resetForm();
    } else {
      toast.error(res.error);
    }
  };

  const validate = Yup.object({
    altTag: Yup.string().required("Enter altag."),
    price: Yup.string().required("Enter your Price."),
    offerPrice: Yup.string().required("Enter your OfferPrice."),
    featured: Yup.string().required("Select featured."),
  });
  return (
    <> 
          <Header user={user} />
      <Sidenav />
      <div className="container"  style={{ background: "white", padding: "20px" }}>
     <h3 className="text-center" style={{ padding: "5px" }}>
                  Add Featured Item
                </h3>
</div>
      {
        <Formik
          initialValues={{
            image: imgUrl.url,
            altTag: "",
            price: "",
            offerPrice: "",
            featured: true,
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formik) => (
            <>
            <div className="container"  style={{ background: "white", padding: "20px" }}>

                  <div className="form-group col-md-12 item-div">
                    <label>Upload image</label>

                    <input
                      label="Image"
                      type="file"
                      placeholder="Image "
                      onChange={(e) => setImage(e.target.files)}
                      
                      />
                    <button
                      onClick={uploadToServer}
                      className="btn btn-primary btn-sm"
                    >
                      upload
                    </button>
                  </div>
                      </div>
             
              <Form onSubmit={formik.handleSubmit}>
                <div
                  className="container"
                  style={{ background: "white", padding: "20px" }}
                >
                  <div className="form-group col-md-12">
                    <TextField
                      label="Alt Tag"
                      name="altTag"
                      placeholder="altTag"
                      sx={{ mb: 3 }}
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <TextField
                      label="Offer Price"
                      name="offerPrice"
                      placeholder="Offer Price"
                      sx={{ mb: 3 }}
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <TextField
                      label="Price"
                      name="price"
                      placeholder="Price"
                      sx={{ mb: 3 }}
                    />
                  </div>

                  <div className="form-group col-md-12 item-div">
                    <Field
                      as="select"
                      name="featured"
                      className="custom-select mr-sm-2"
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Field>
                  </div>

                  <div className="modal-footer">
                    <SubmitButton
                      fullWidth
                      loading={postImageIsLoading}
                      label="Add Featured Image"
                    />
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      }
      {/* </div> */}
    </>
  );
}
