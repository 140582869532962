import UserViewComp from "../User/UserViewComp";



export default function UserViewModel({data}) {
return <div className="modal fade" id="exampleModaluserview" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog" role="document">
  {data!=null? <UserViewComp className="modal-content" data = {data} /> : "Loading..."}  
</div>
</div>
}