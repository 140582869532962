import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Budget } from '../Common/CardForValue';

export default function TopCounter({ ordersData, loading }) {
    const ordersData1 = ordersData?.data || []
    const orderarr = ordersData1 || []

    const Delivered = orderarr.length > 0 && orderarr.filter(item => item.status === 'ORDER_DELIVERED');
    const Canceled = orderarr.length > 0 && orderarr.filter(item => item.status === 'ORDER_CANCELED');

    return (
        <div className="row">
            <div className="col-12 col-md-4">
                {loading ? (
                    <Skeleton height={100} />
                ) : (
                    <Budget title={"Total Order"} diff={12} icon={'fas fa-store'} trend="up" sx={{ height: '100%', backgroundColor: '#cce3d5' }} value={ordersData.totalCount ?? 0} />
                )}
            </div>
            <div className="col-12 col-md-4">
                {loading ? (
                    <Skeleton height={100} />
                ) : (
                    <Budget title={"Order Delivered"} icon={'fas fa-shopping-cart'} diff={12} trend="up" sx={{ height: '100%', backgroundColor: '#b5ebc3' }} value={Delivered.length ?? 0} />
                )}
            </div>
            <div className="col-12 col-md-4">
                {loading ? (
                    <Skeleton height={100} />
                ) : (
                    <Budget title={"Canceled Order"} icon={'fas fa-ban'} diff={12} trend="down" sx={{ height: '100%', backgroundColor: '#f7c3c3' }} value={Canceled.length ?? 0} />
                )}
            </div>
        </div>
    );
}
