import { CART_LOCAL_STORE_NAME } from "../config";

export const loadCartItems = (restroId) => {
    if (typeof localStorage === 'undefined') {
        return false;
    }
    
    const value = localStorage.getItem(CART_LOCAL_STORE_NAME)
    return (value === null) ? {
        restaurantId: restroId,
        items: []
    } : JSON.parse(value)
}
