


export const STATUS_FILTER_OPTIONS = [
    { 'label': 'Pending', value: 'ORDER_PENDING' },
    { 'label': 'Confirmed', value: 'ORDER_CONFIRMED' },
    { 'label': 'Preparing', value: 'ORDER_PREPARING' },
    { 'label': 'Delivered', value: 'ORDER_DELIVERED' },
    { 'label': 'Cancelled', value: 'ORDER_CANCELLED' },
    { 'label': 'Undelivered', value: 'ORDER_UNDELIVERED' },
];


export const SHOW_RECORDS_OPTIONS = [
    { 'label': '10', value: '10' },
    { 'label': '20', value: '20' },
    { 'label': '50', value: '50' },
    { 'label': '100', value: '100' },
    { 'label': '200', value: '200' },
    { 'label': '500', value: '500' },
    { 'label': '1000', value: '1000' },
    { 'label': '3000', value: '3000' },
    { 'label': '5000', value: '5000' },
    { 'label': 'All records', value: '-1' },
]


export const CREATED_AT_FILTER_OPTIONS = [
    { 'label': 'Today', value: 'today' },
    { 'label': 'Yesterday', value: 'yesterday' },
    { 'label': 'Last 7 days', value: 'last_7_days' },
    { 'label': 'Last 30 days', value: 'last_30_days' },
    { 'label': 'This month', value: 'this_month' },
    { 'label': 'Last month', value: 'last_month' },
]
export const ORDER_SOURCE_OPTIONS = [
    { 'label': 'IRCTC', value: 'irctc' },
    { 'label': 'Web', value: 'web' },
    { 'label': 'Android', value: 'android' },
    { 'label': 'iOS', value: 'ios' },
]
export const PAYMENT_TYPE_OPTIONS = [
    { label: 'Online Payment', value: 'ONLINE' },
    { label: 'Cash on Delivery', value: 'CASH_ON_DELIVERY' },
]
