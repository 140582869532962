import React, { useState } from 'react'
import { useUser } from '../../components/hooks/user';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import {  Card, Typography } from '@mui/material';
import moment from "moment";
import { useVendorsTransaction } from "../../components/hooks/vendors"
import { Form, Formik } from 'formik';
import { useRestaurantsList, stripResturantsList } from '../../components/hooks/restaurants';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import VendorRdsTable from '../../components/Vendor-rds/VendorRdsTable';
import SelectFieldOld from '../../components/Common/SelectField_old';



const VendorRds = () => {
  const { user } = useUser();
  const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();

  const [vendor, setVendor] = React.useState("")
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [fetchData, setFetchData] = useState("")

  const { vendorstransactionData, vendorstransactionIsLoading, refetch } = useVendorsTransaction(vendor, startDate, endDate)
  console.log(vendorstransactionData)

  return (
    <div>
      <Header user={user} />
      <Sidenav />
      <div className="content-wrapper">

        <Card className="p-3">
          <h4 className='text-center'>Vendors Rolling Deposit</h4>
          <div style={{ width: "100%" }}>
            <Formik
              initialValues={{
                vendorname: "",
                datefrom: "",
                dateTo: ""
              }}
              onSubmit={(values) => {
                setFetchData(values);
                refetch()
              }}
            >
              {(formik) => (
                <Form>
                  <div className='d-flex' style={{ flexDirection: "column" }}>
                    <div className="d-flex flex-row align-items-center " style={{ justifyContent: "space-around" }}>

                      <div className='d-flex my-4' style={{ width: "30%" }}>
                        <div className='mx-2 mt-2'>
                          <Typography variant="h9">
                            Vendor
                          </Typography>
                        </div>
                        <div style={{ width: "80%" }}>
                          {!restaurantsIsLoading ? (
                            <SelectFieldOld
                              options={restaurantsData?.map(stripResturantsList)}
                              // onChange={(e) => formik.setFieldValue("vendorname", e.value)}
                              onChange={(e) => setVendor(e.value)}
                              name="vendorname"
                            />
                          ) : ""}

                        </div>

                      </div>
                      <div className='' style={{ marginTop: "-4px" }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              slotProps={{ textField: { size: 'small' } }}
                              value={moment(startDate, 'YYYY-MM-DD')}
                              ampm={false}
                              //onChange={(value) => formik.setFieldValue("datefrom", moment.utc( value).startOf('day').format('YYYY-MM-DD HH:mm:ss'))}
                              onChange={(value) => setStartDate(moment.utc(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'))}
                              name="datefrom" label="Date From" />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='' style={{ marginTop: "-4px" }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              slotProps={{ textField: { size: 'small' } }}
                              value={moment(endDate, 'YYYY-MM-DD ')}
                              ampm={false}
                              //onChange={(value) => formik.setFieldValue("dateTo",moment.utc( value).endOf('day').format('YYYY-MM-DD HH:mm:ss'))}
                              onChange={(value) => setEndDate(moment.utc(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'))}
                              name="dateTo" label="Date To" />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className=''>
                        <button className='btn btn-success' type="submit"  >Search</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="table-wrapper">
            {
              <Card className='mt-3' style={{ minHeight: "100vh" }}>
                {fetchData ?
                  <VendorRdsTable vendorstransactionData={vendorstransactionData} vendorstransactionIsLoading={vendorstransactionIsLoading} />
                  : ""}

              </Card>
            }
          </div>
        </Card>
      </div>
    </div>
  );
}

export default VendorRds 
