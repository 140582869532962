import { useNavigate } from 'react-router-dom';

export default function OrderNotFound() {
    const navigate = useNavigate();

    return (
        <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-light text-center">
            <span className="material-icons text-warning mb-4" style={{ fontSize: '64px' }}>
                warning
            </span>
            <h1 className="display-4 fw-bold text-dark mb-4">Order Not Found</h1>
            <p className="lead text-muted mb-4">
                The order you're looking for doesn't seem to exist. Please check the order ID and try again.
            </p>
            <button
                onClick={() => navigate('/order')}
                className="btn btn-primary btn-lg"
            >
                Go Back to Orders
            </button>
        </div>
    );
}