import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "../Common/MuiTextField";
import "yup-phone";
import { Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import SelectField from "../Common/SelectField";
import {
    stripStationsList,
    stripVendorsList,
    useVendorsList,
} from "../hooks/vendors";
import SubmitButton, { CancelButton } from "../Common/Button";
import { useNavigate } from "react-router-dom";
import Loader2 from "../Common/Loader2";

import { useStationsList } from "../hooks/stations";
import SelectFieldOld from "../Common/SelectField_old";

const firm_type_options = [
    { label: "Private Limited", value: "Private Limited" },
    { label: "Partnership Firm", value: "Partnership" },
    { label: "Proprietorship", value: "Properietorship" },
];
const restaurant_type_options = [
    { label: "Veg", value: "veg", id: "1" },
    { label: "Non-Veg", value: "Non-Veg", id: "2" },
    { label: "Jain", value: "Jain", id: "3" },
];
const delivery_type_options = [
    { label: "Delivery", value: "delivery" },
    { label: "Pickup", value: "pickup" },
];
const status_type_options = [
    { label: "Active", value: "Active" },
    { label: "Deactive", value: "Closed" },
];
const fssai_status_type_options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];
export default function AddRestaurantForm({ handleSubmit, isLoading }) {
    let navigate = useNavigate();

    const { vendorsData, vendorsIsLoading } = useVendorsList();

    const { stationsData, stationsIsLoading } = useStationsList();
    // console.log(stationsData)
    const validate = Yup.object({
        name: Yup.string().min(4).required("Enter restaurant name."),
        brand: Yup.string().min(4).required("Enter brand name."),
        address: Yup.string().min(4).required("Enter address."),
        station_distance: Yup.string().min(1).required("Enter station distance."),
        firm_name: Yup.string().min(4).required("Enter company name."),
        company_pan: Yup.string().min(4).required("Enter company pan."),
        // gstin: Yup.string().min(4).required("Enter gst number."),
        email: Yup.string().email().required("Enter email address."),
        contact_number: Yup.string().required("Enter contact number."),
        fssai_lic_num: Yup.string().required("Enter fssai licence number."),
        closing_time: Yup.string().required("Enter closing time."),
        opening_time: Yup.string().required("Enter opening time."),
        contact_person: Yup.string().required("Enter contact person."),
        ac_holder_name: Yup.string().required(),
        account: Yup.string().required(),
        ifsc: Yup.string().required(),
        min_order_amount: Yup.string().required("Enter Minimum Order Amount."),
        min_order_time: Yup.string().required("Enter Minimum Order Time."),
        city: Yup.string().required("Enter city name"),
        state: Yup.string().required("Enter state name"),
        pinCode: Yup.number().required("Enter pincode"),
        fssaiValidUpto: Yup.date().required("Enter fssai validation date")
    });

    let restaurant_type = [];

    const pushItem = (e, id) => {
        if (e.target.checked) {
            restaurant_type.push(e.target.value);
        } else if (!e.target.checked) {
            var indexToRemove = restaurant_type.indexOf(e.target.value);
            if (indexToRemove !== -1) {
                restaurant_type.splice(indexToRemove, 1);
            }
        }
    };

    const [state, setState] = useState({ selectedOption: "" });
    const [newArr, setNewarr] = useState([]);

    // let newArr = []
    const addStation = () => {
        setNewarr([]);
        var arr = [],
            result = state.selectedOption.map((data) => {
                arr.push(data.value);
                newArr.push(data.value);
            });
    };

    const handleChange = (selectedOption) => {
        setState({ selectedOption });
    };

    return (
        <Formik
            initialValues={{
                name: "",
                brand: "",
                userId: null,
                station: newArr,
                station_distance: "",

                address: "",
                firm_name: "",
                firm_type: "", // Private Limited, Partnership Firm, Proprietorship
                company_pan: "",
                gstin: "",

                contact_person: "",
                email: "",
                contact_number: "",
                alt_number: "",

                restaurant_type: [], // * Veg, Non-Veg, Jain
                delivery_type: "delivery", // delivery, pickup
                fssai_status: true,
                fssai_lic_num: "",
                status: "Active",

                opening_time: "",
                closing_time: "",

                ifsc: "",
                account: "",
                ac_holder_name: "",
                min_order_time: "",
                min_order_amount: "",
                city: "",
                state: "",
                pinCode: "",
                fssaiValidUpto: "",
            }}
            validationSchema={validate}
            // onSubmit={handleSubmit}
            onSubmit={(val, { resetForm }) => {
                addStation();
                handleSubmit(val, resetForm);
            }}
            enableReinitialize
        >
            {(formik) => (
                <Form>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <Typography variant="h9" color="#949494">
                                Select Station <span className="req">*</span>
                            </Typography>

                            <SelectFieldOld
                                options={stationsData?.map(stripStationsList)}
                                // filterOption={createFilter({ ignoreAccents: false })}
                                isLoading={stationsIsLoading}
                                onChange={handleChange}
                                name="station"
                                isMulti
                            />
                        </div>

                        <div className="col-md-6">
                            <Typography variant="h9" color="#949494">
                                Select Vendor <span className="req">*</span>
                            </Typography>
                            {/* {vendorsData ? ( */}
                                <SelectField
                                    options={vendorsData?.map(stripVendorsList)}
                                    onChange={(e) => formik.setFieldValue("userId", e.value)}
                                    name="userId"
                                    isLoading={vendorsIsLoading}
                                />
                            {/* ) : (
                                <Loader2 color="inherit" />
                            )} */}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-3">
                            <TextField
                                label="Restaurant Name *"
                                name="name"
                                placeholder="Lazeez Express NDSL"
                            />
                        </div>
                        <div className="col-md-3">
                            <TextField
                                label="Brand Name *"
                                name="brand"
                                placeholder="Brand Name"
                            />
                        </div>
                        <div className="col-md-3" style={{ display: "flex", gap: "10px" }}>
                            <Typography variant="h9" color="#949494">
                                Opening Time <span className="req">*</span>
                            </Typography>
                            <Field type="time" name="opening_time" />
                        </div>
                        <div className="col-md-3" style={{ display: "flex", gap: "10px" }}>
                            <Typography variant="h9" color="#949494">
                                Closing Time <span className="req">*</span>
                            </Typography>
                            <Field type="time" name="closing_time" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <TextField
                                label="Address *"
                                name="address"
                                placeholder="New Delhi"
                            />
                        </div>
                        <div className="col-md-2">
                            <TextField
                                label="Station Distance *"
                                name="station_distance"
                                placeholder="4 km"
                            />
                        </div>
                        <div className="col-md-4">
                            <TextField
                                label="Firm Name *"
                                name="firm_name"
                                placeholder="InspiGear India Pvt. Ltd."
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <TextField
                                label="City"
                                name="city"
                                placeholder="City"
                            />
                        </div>
                        <div className="col-md-2">
                            <TextField
                                label="State"
                                name="state"
                                placeholder="State"
                            />
                        </div>
                        <div className="col-md-4">
                            <TextField
                                type='number'
                                label="Pincode"
                                name="pinCode"
                                placeholder="Pincode"
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-3">
                            <Typography variant="h9" color="#949494">
                                Firm Type <span className="req">*</span>
                            </Typography>
                            <SelectField
                                name="firm_type"
                                options={firm_type_options}
                                value={firm_type_options.filter(function (option) {
                                    return option.value === formik.values.firm_type;
                                })}
                                onChange={(e) => formik.setFieldValue("firm_type", e.value)}
                            />
                        </div>
                        <div className="col-md-8">
                            <div>
                                <Typography variant="h9" color="#949494">
                                    Restaurant Type<span className="req">*</span>
                                </Typography>
                                <div style={{ display: "flex", gap: "30px" }}>
                                    {restaurant_type_options.map((val, i) => {
                                        return (
                                            <div className="form-check" key={i + 1}>
                                                <Checkbox
                                                    name="restaurant_type"
                                                    value={val.value}
                                                    onChange={(e, i) => {
                                                        pushItem(e, val.id);
                                                        formik.setFieldValue(
                                                            "restaurant_type",
                                                            restaurant_type
                                                        );
                                                    }}
                                                />

                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexCheckDefault"
                                                >
                                                    {val.label}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Typography variant="h9" color="#949494">
                                Fssai Status <span className="req">*</span>
                            </Typography>
                            <SelectField
                                name="fssai_status"
                                options={fssai_status_type_options}
                                value={fssai_status_type_options.filter(function (option) {
                                    return option.value === formik.values.fssai_status;
                                })}
                                onChange={(e) => formik.setFieldValue("fssai_status", e.value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <Typography variant="h9" color="#949494">
                                Delivery Type <span className="req">*</span>
                            </Typography>
                            <SelectField
                                name="delivery_type"
                                options={delivery_type_options}
                                value={delivery_type_options.filter(function (option) {
                                    return option.value === formik.values.delivery_type;
                                })}
                                onChange={(e) => formik.setFieldValue("delivery_type", e.value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <Typography variant="h9" color="#949494">
                                Status <span className="req">*</span>
                            </Typography>
                            <SelectField
                                name="status"
                                options={status_type_options}
                                value={status_type_options.filter(function (option) {
                                    return option.value === formik.values.status;
                                })}
                                onChange={(e) => formik.setFieldValue("status", e.value)}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <TextField label="PAN *" name="company_pan" placeholder="PAN" />
                        </div>
                        <div className="col-md-4">
                            <TextField label="GSTIN *" name="gstin" placeholder="GSTIN" />
                        </div>
                        <div className="col-md-4">
                            <TextField
                                label="FSSAI *"
                                name="fssai_lic_num"
                                placeholder="FSSAI"
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <TextField
                                type="date"
                                label="Fssai Valid upto"
                                name="fssaiValidUpto"
                                InputLabelProps={{ shrink: true }}
                                placeholder="Fssai Valid upto"
                                sx={{ mb: 3 }}
                            />
                        </div>
                    </div>
                    <hr />
                    <Typography color="#949494" align="center" variant="caption">
                        Contact Details
                    </Typography>
                    <div className="row mt-2">
                        <div className="col-md-3">
                            <TextField
                                label="Contact Person *"
                                name="contact_person"
                                placeholder="Contact Person"
                            />
                        </div>
                        <div className="col-md-3">
                            <TextField label="Email *" name="email" placeholder="Email" />
                        </div>
                        <div className="col-md-3">
                            <TextField
                                label="Contact Number *"
                                name="contact_number"
                                placeholder="Contact Number"
                            />
                        </div>
                        <div className="col-md-3">
                            <TextField
                                label="Alt Number *"
                                name="alt_number"
                                placeholder="Alt Number"
                            />
                        </div>
                    </div>
                    <hr />
                    <Typography color="#949494" align="center" variant="caption">
                        Bank Account Details
                    </Typography>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <TextField
                                label="Account Name *"
                                name="ac_holder_name"
                                placeholder="Account Name"
                            />
                        </div>
                        <div className="col-md-4">
                            <TextField
                                label="IFSC Code *"
                                name="ifsc"
                                placeholder="IFSC Code"
                            />
                        </div>
                        <div className="col-md-4">
                            <TextField
                                label="Account Number *"
                                name="account"
                                placeholder="Account Number"
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-4">
                            <TextField
                                label="Minimum Order Time *"
                                name="min_order_time"
                                placeholder="Minimum Order Time"
                            />
                        </div>
                        <div className="col-md-4">
                            <TextField
                                label="Minimum Order Amount *"
                                name="min_order_amount"
                                placeholder="Minimum Order Amount"
                            />
                        </div>
                    </div>

                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        mb={2}
                        mt={5}
                    >
                        <SubmitButton loading={isLoading} label="Add Restaurant" />
                        <CancelButton
                            label="Cancel"
                            onClick={() => navigate(`/restaurants`)}
                        />
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}
