import React, { useEffect } from 'react'
import Sidenav from '../../components/Sidenav/Sidenav';
import Header from '../../components/Header/Header';
import { useUser } from '../../components/hooks/user';
import OrderComp from '../../components/Order/OrderComp';

const OrderPage = () => {
    const { user } = useUser();


    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper">
                <OrderComp />
            </div>
        </div>
    )
}


export default OrderPage