import React from 'react'
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useUser } from '../../components/hooks/user';
import UserComp from '../../components/User/UserComp';

const User = () => {
    const { user } = useUser();

    return (
        <div>
          <Header user={user} />
          <Sidenav />
          <div className="content-wrapper">
<UserComp/>
          </div>
        </div>
      );
    };

export default User