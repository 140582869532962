import React from 'react'
import UpdateStatusOrderForm from '../../../form/UpdateOrderStatusForm'
import { useUpdateOrderStatus } from '../../../hooks/orders';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IRCTCStatusForm from '../Forms/IRCTCStatusForm';
// import { useRef } from 'react'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    width: 500,
};



export default function IRCTCStatusModal({ data, modal, setModal }) {
    const { handleUpdateOrder, handleUpdateIsLoading } = useUpdateOrderStatus(data._id);
    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="container-fluid modalbody">
                        <div className="d-flex justify-content-between">
                            <h5 className="">Update Enquiry Form</h5>
                            <IconButton onClick={() => setModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div>
                            <IRCTCStatusForm data={data} handleUpdateIsLoading={handleUpdateIsLoading} handleUpdateOrder={handleUpdateOrder} setModal={setModal} />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}