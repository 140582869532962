import React, { useState } from 'react'
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import { useAddUser } from '../hooks/user';
import AddUserForm from '../form/AddUserForm';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px'
    },
};

export default function AddUserModal({ modalIsOpen, setModalIsOpen }) {
    const { handleAddUser, addUserIsLoading } = useAddUser();
    const [response, setResponse] = useState(null)

    const handleSubmit = async (values, { resetForm }) => {
        const res = await handleAddUser(values);
        setResponse(res);
        if (res.success) {
            setModalIsOpen(false);
            toast.success('User added successfully.')
            resetForm({ values: '' })
        } else {
            toast.error(res.error);
        }
    }
    return ( 
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            contentLabel="Add new station"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">Add new user</h6>
                    <IconButton onClick={() => setModalIsOpen(false)}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <AddUserForm
                        handleSubmit={handleSubmit}
                        isLoading={addUserIsLoading}
                        response={response}
                    />
                </div>

            </div>

        </Modal>
    )
}
