import {  useRef } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CART_LOCAL_STORE_NAME } from "../../config";
import PlaceOrderModal from "../model/PlaceOrderModal";
import { useApplyCoupon, usePlaceOrder } from "../hooks/orders";
import CouponForm from "../form/CouponForm";

export default function CartSummary({
  cartItems,
  handleRemoveFromCart,
  pnrNumber,
  setPaymentText,
  sub_total,
  paymenttext,
  gst_amount,
  total_amount,
  payable_amount,
  setDiscount,discount,restId,
  changePayment
}) {
  const closeModel = useRef();
  const { handlePlaceOrder, isPlaceOrderLoading } = usePlaceOrder();
  const { handleCoupon, isCouponLoading } = useApplyCoupon();
 

 

  const handlePlaceOrderSubmit = async (values, { resetForm }) => {
    if (values.orderItems.length > 0) {
      const res = await handlePlaceOrder(values);
      if (values.paymentType !== "CASH_ON_DELIVERY") {
        if (res.success) {
          window.open(`${res.data.data.instrumentResponse.redirectInfo.url}`)
          localStorage.removeItem(CART_LOCAL_STORE_NAME);
          resetForm()
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: '<a href="">Why do I have this issue?</a>',
          });
        }
      } else {
        if (res.success) {
          Swal.fire(
            "Order Placed!",
            `You order has been placed successfully.`,
            "success"
          ).then(() => {
            window.location = "/thank-you";
          });
          localStorage.removeItem(CART_LOCAL_STORE_NAME);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: '<a href="">Why do I have this issue?</a>',
          });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Add Item to Cart First!!",
        text: "Your cart is empty!!",
        // footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };

  const handleApplyCoupon = async (values, { resetForm }) => {
    

    const data = await handleCoupon({ ...values, totalAmount: total_amount });
    if (data.success) {
      setDiscount(data.discount);
    } else {
      toast.error(`Code is expired or invalid.`);
    }
  };
  return (
    <>
      <div className="bottomCart">
        <div className="d-flex justify-content-around align-items-center w-100">
          <div className="px-2 py-2">
            <a className="cartBTN">
              <span>{cartItems ? cartItems.items.length : ""}</span>
            </a>
          </div>
          <div className="px-2 py-2 border-left border-right">
            <button
              className="btn fw-500 text-danger"
              data-toggle="modal"
              data-target="#MobileSummaryPopup"
            >
              VIEW CART
            </button>
          </div>
          <div className="px-2 py-2 text-right">
            <span className="text-nowrap d-block fs-10 lh-1 px-3">You Pay</span>
            <span className="text-nowrap d-block fs-18 lh-1 px-3 fw-500">
              ₹ {payable_amount}
            </span>
          </div>
          <div className="px-2 py-2 border-left">
            <button
              className="btn fw-500 btn btn-danger"
              data-toggle="modal"
              data-target="#placeorder"
            >
              PAY NOW
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4 DesktopSummary">
        <div className="CartSummary">
          <div className="shadow-sm bg-white rounded border p-3 mb-3">
            <div className="row">
              <div className="col-12 pb-3 border-bottom">
                <h5 className="m-0">Order Summry</h5>
              </div>
            </div>

            {cartItems.items.length > 0 ? (
              <>
                {cartItems.items.map((val, i) => (
                  <div className="row py-3 border-bottom" key={i}>
                    <div className="col-8">
                      <div className="fw-500">{val.itemName}</div>
                      <div>
                        ₹{val.yrPrice}{" "}
                        <span className="text-success">(X{val.quantity})</span>
                      </div>
                    </div>
                    <div className="col-4 text-right">
                      <div>
                        <button
                          className="btn btn-sm btn-light text-muted fs-12"
                          onClick={() => handleRemoveFromCart(val)}
                        >
                          <i className="fas fa-minus m-0"></i>
                        </button>
                      </div>
                      <div>₹{val.yrPrice * val.quantity}</div>
                    </div>
                  </div>
                ))}
                {/* Coupon Managment */}
                <CouponForm
                  discount={discount}
                  isLoading={isCouponLoading}
                  handleSubmit={
                    discount > 0 ? () => setDiscount(0) : handleApplyCoupon
                  }
                  btnLabel={discount > 0 ? "Remove Coupon" : "Apply Coupon"}
                />
              </>
            ) : (
              <div className="row py-3 border-bottom">
                <div className="col-8">
                  <div className="fw-500">No items added on cart...</div>
                </div>
              </div>
            )}

            {cartItems.items.length > 0 && (
              <div className="row pt-3">
                <div className="col-12 ">
                  <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                    <div>Sub Total</div>
                    <div>₹ {sub_total}</div>
                  </div>
                  <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                    <div>Delivery Charge</div>
                    <div>
                      <span className="badge badge-warning">Free</span>
                    </div>
                  </div>
                  <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                    <div>Packaging Charge</div>
                    <div>
                      <span className="badge badge-warning">Free</span>
                    </div>
                  </div>
                  <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                    <div>GST</div>
                    <div>₹ {gst_amount}</div>
                  </div>
                  <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                    <div>Total</div>
                    <div>₹ {total_amount}</div>
                  </div>
                  {discount !== 0 && (
                    <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                      <div>Discount</div>
                      <div>₹ {discount}</div>
                    </div>
                  )}
                 
                
                  <hr />
                  <div className="d-flex text-success bg-light justify-content-between fs-18 p-2 fw-500 rounded">
                    <div>Net Payable</div>
                    <div>₹ {payable_amount}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {cartItems.items.length > 0 && (
            <div className="shadow-sm bg-white rounded border p-3">
              <div className="pb-3">
                <button
                  className="btn btn-danger btn-block"
                  data-toggle="modal"
                  data-target="#placeorder"
                  onClick={() => closeModel.current.click()}
                  id="place"
                >
                  Place Order
                </button>
              </div>
              <div className="fs-14">
                We accept COD, UPI, Internet Banking & Debit/Credit Cards
              </div>
            </div>
          )}
        </div>
      </div>

      <PlaceOrderModal
        pnrNumber={pnrNumber}
        isPlaceOrderLoading={isPlaceOrderLoading}
        handlePlaceOrderSubmit={handlePlaceOrderSubmit}
        item={cartItems}
        payable={payable_amount}
        discount={discount}
        total_amount ={total_amount}
        paymenttext={paymenttext}
        setPaymentText={setPaymentText}
        setDiscount={setDiscount}
        changePayment={changePayment}
        restId={restId}
        className="modal"
        id="placeorder"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="tcModalCenterTitle"
        aria-hidden="true"
      />

      <div
        className="modal SummryModel show"
        id="MobileSummaryPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="tcModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="modal-header border-0 py-0 justify-content-end">
                <button
                  type="button"
                  className="close fw-400 position-absolute"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModel}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="col-12 col-lg-4 DesktopSummary">
                <div className="CartSummary">
                  <div className="shadow-sm bg-white rounded border p-3 mb-3">
                    <div className="row">
                      <div className="col-12 pb-3 border-bottom">
                        <h5 className="m-0">Order Summry</h5>
                      </div>
                    </div>

                    {cartItems.items.length > 0 ? (
                      <>
                        {cartItems.items.map((val, i) => (
                          <div className="row py-3 border-bottom" key={i}>
                            <div className="col-8">
                              <div className="fw-500">{val.itemName}</div>
                              <div>
                                ₹{val.yrPrice}{" "}
                                <span className="text-success">
                                  (X{val.quantity})
                                </span>
                              </div>
                            </div>
                            <div className="col-4 text-right">
                              <div>
                                <button
                                  className="btn btn-sm btn-light text-muted fs-12"
                                  onClick={() => handleRemoveFromCart(val)}
                                >
                                  <i className="fas fa-minus m-0"></i>
                                </button>
                              </div>
                              <div>₹{val.yrPrice * val.quantity}</div>
                            </div>
                          </div>
                        ))}

                        <CouponForm
                          discount={discount}
                          isLoading={isCouponLoading}
                          handleSubmit={
                            discount > 0
                              ? () => setDiscount(0)
                              : handleApplyCoupon
                          }
                          btnLabel={
                            discount > 0 ? "Remove Coupon" : "Apply Coupon"
                          }
                        />
                      </>
                    ) : (
                      <div className="row py-3 border-bottom">
                        <div className="col-8">
                          <div className="fw-500">
                            No items added on cart...
                          </div>
                        </div>
                      </div>
                    )}

                    {cartItems.items.length > 0 && (
                      <div className="row pt-3">
                        <div className="col-12 ">
                          <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                            <div>Sub Total</div>
                            <div>₹ {sub_total}</div>
                          </div>
                          <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                            <div>GST</div>
                            <div>₹ {gst_amount}</div>
                          </div>
                          <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                            <div>Delivery Charge</div>
                            <div>
                              <span className="badge badge-warning">Free</span>
                            </div>
                          </div>
                          <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                            <div>Packaging Charge</div>
                            <div>
                              <span className="badge badge-warning">Free</span>
                            </div>
                          </div>
                          <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                            <div>Total</div>
                            <div>₹ {total_amount}</div>
                          </div>
                          {discount !== 0 && (
                            <div className="d-flex bg-dark text-white justify-content-between p-2 ">
                              <div>Discount</div>
                              <div>₹ {discount.toFixed(2)}</div>
                            </div>
                          )}
                          <hr />
                          <div className="d-flex text-success bg-light justify-content-between fs-18 p-2 fw-500 rounded">
                            <div>Net Payable</div>
                            <div>₹ {payable_amount}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {cartItems.items.length > 0 && (
                    <div className="shadow-sm bg-white rounded border p-3">
                      <div className="pb-3">
                        <button
                          className="btn btn-danger btn-block"
                          data-toggle="modal"
                          data-target="#placeorder"
                          onClick={() => closeModel.current.click()}
                          id="place"
                        >
                          Place Order
                        </button>
                      </div>

                      <div className="fs-14">
                        We accept COD, UPI, Internet Banking & Debit/Credit
                        Cards
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlaceOrderModal
        handlePlaceOrderSubmit={handlePlaceOrderSubmit}
        isPlaceOrderLoading={isPlaceOrderLoading}
        pnrNumber={pnrNumber}
        item={cartItems}
        discount={discount}
        paymenttext={paymenttext}
        payable={payable_amount}
        setPaymentText={setPaymentText}
        changePayment={changePayment}
        className="modal"
        id="placeorder"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="tcModalCenterTitle"
        aria-hidden="true"
      />
    </>
  );
}
