export const endpoints = {
    account: {
        login: "api/v1/admin/login",
        logout: "api/v1/admin/logout",
        add_user: "api/v1/admin/add",
        user: "api/v1/admin/me",
        users_list: "api/v1/admin/accounts",
    },
    restaurant: {
        list: "api/v1/resturant",
        list_pagination: "api/v1/resturant/new/all",
        add: "api/v1/resturant/add",
        update: "api/v1/resturant",
        tag: "api/v1/irctc/outlet/add",
        file_upload: "api/v1/aws",
    },
    feature: {
        image: "api/v1/cloudinary/upload-images",
        list: "api/v1/featuredItems",
    },
    coupans: {
        list: "api/v1/coupon/coupons",
        add: "api/v1/coupon/coupon",
        delete: "api/v1/coupon/coupon",
    },
    enquiry: {
        list: "api/v1/feedback",
    },
    item: {
        add: "api/v1/item",
        getDetail: "api/v1/item",
        update: "api/v1/item",
    },
    stations: {
        list: "api/v1/station",
        add: "api/v1/station",
    },
    vendors: {
        list: "api/v1/vendor/all",
        add: "api/v1/vendor/add",
        update: "api/v1/vendor",
        updateByid: "api/v1/admin",
        transactions: {
            pending: "api/v1/transaction/pending",
            add: "api/v1/transaction/add-transaction",
            approve_reject: "api/v1/transaction", // /api/v1/transaction/reject/64ccf082e7215e6668592f21
        }
    },
    customers: {
        list: "api/v1/admin/customers",
    },
    orders: {
        list: "api/v1/order/admin",
        list_pagination: "api/v1/order/admin/all",
        latest: 'api/v1/order/admin/latest-order',
        statusUpdate: 'api/v1/order/admin/status',
        newStatusUpdate: 'api/v1/order/admin/update',
        statusCheck: 'api/v1/phonepe/status',
        placeOrder: "api/v1/order/user",
        couponValidate: "api/v1/coupon/test",
        confirm: "api/v1/order/admin/confirm",
        swapOrder: "api/v1/order/admin/swap",
        eta: "api/v1/order/admin/eta"
    },
    blog: {
        list: "api/v1/blog/post",
        update: 'api/v1/blog/post',
        detail: 'api/v1/blog/post/get'
    },
    location: {
        list: 'api/v1/location/get-locations'
    }, cancel: {
        list: 'api/v1/order-cancel',
        update: 'api/v1/order-cancel'
    },
    pages: {
        list: 'api/v1/pages',
        update: 'api/v1/pages',
        getBySlug: 'api/v1/pages',
        updatePage: 'api/v1/pages'
    },
    find: {
        pnrStopage: 'api/v1/train/pnrtostopage',
        trainstopage: "api/v1/train/trainStopage",
    },
    transactions: {
        list: 'api/v1/transaction'
    },
    invoices: {
        list: 'api/v1/invoice/all',
        data: 'api/v1/invoice/data',
        detail: 'api/v1/invoice/detail',
        create: 'api/v1/invoice/add',
    }
};
