import React from "react";
import Header from "../../components/Header/Header";
import Sidenav from "../../components/Sidenav/Sidenav";
import { useUser } from "../../components/hooks/user";
import CustomerComp from "../../components/Customer/CustomerComp";

const CustomerPage = () => {
  const { user } = useUser();
  return (
    <div>
      <Header user={user} />
      <Sidenav />
      <div className="content-wrapper">
        <CustomerComp />
      </div>
    </div>
  );
};

export default CustomerPage;
