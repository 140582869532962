import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function RestaurantTopCounter({ restaurantsData = {}, loading }) {
    const arr = restaurantsData?.data || [];
    const active = arr.filter(item => item.status === 'Active');

    return (
        <div className="row">
            <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box">
                    <span className="info-box-icon bg-info elevation-1">
                        <i className="fas fa-store" />
                    </span>
                    <div className="info-box-content">
                        <span className="info-box-text">Total Restaurants</span>
                        {loading ? (
                            <Skeleton width={40} />
                        ) : (
                            <span className="info-box-number">{restaurantsData?.totalCount ?? 0}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-success elevation-1">
                        <i className="fas fa-shopping-cart" />
                    </span>
                    <div className="info-box-content">
                        <span className="info-box-text">Active Restaurants</span>
                        {loading ? (
                            <Skeleton width={40} />
                        ) : (
                            <span className="info-box-number">{active.length ?? 0}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="clearfix hidden-md-up" />
            <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-danger elevation-1">
                        <i className="fas fa-comment-dollar" />
                    </span>
                    <div className="info-box-content">
                        <span className="info-box-text">Closed Restaurants</span>
                        {loading ? (
                            <Skeleton width={40} />
                        ) : (
                            <span className="info-box-number">
                                {(arr.length - active.length) ?? 0}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
