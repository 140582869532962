import React, { useState } from 'react'
// import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import "yup-phone";
import { Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
// import { toast } from 'react-toastify';
// import { useRef } from 'react';
import SelectFieldOld from '../../../Common/SelectField_old';
import SubmitButton from '../../../Common/Button';
import toast from 'react-hot-toast';

const IRCTCStatusForm = ({ data, handleUpdateIsLoading, handleUpdateOrder, setModal }) => {
    // const closeItemModal = useRef()
    const validate = Yup.object({
        status: Yup.string()
            .required('Select Status.')
    })

    const [orderStatus, setOrderStatus] = useState();

    const updateStatus = async (data) => {
        const toastId = toast.loading('Processing...');
        const res = await handleUpdateOrder(data);
        if (res.success) {
            toast.success("Order Status Updated Successfully", { id: toastId });
            setModal(false)
        } else {
            toast.error(res.error, { id: toastId });
        }
    }

    const ROLES_OPTIONS = [
        { 'label': 'ORDER PREPARING', value: 'ORDER_PREPARING' },
        { 'label': 'ORDER PREPARED', value: 'ORDER_PREPARED' },
        { 'label': 'ORDER OUT FOR DELIVERY', value: 'ORDER_OUT_FOR_DELIVERY' },
        { 'label': 'ORDER DELIVERED', value: 'ORDER_DELIVERED' },
        { 'label': 'ORDER CANCELLED', value: 'ORDER_CANCELLED' },
        { 'label': 'ORDER UNDELIVERED', value: 'ORDER_UNDELIVERED' },
        { 'label': 'ORDER PARTIALLY DELIVERED', value: 'ORDER_PARTIALLY_DELIVERED' },
    ]

    const ORDER_CANCELED_REASON = [
        { 'label': 'BEYOND SERVICE HOUR', value: 'BEYOND_SERVICE_HOUR' },
        { 'label': 'TRAIN DELAYED', value: 'TRAIN_DELAYED' },
        { 'label': 'LAW N ORDER', value: 'LAW_N_ORDER' },
        { 'label': 'NATURAL CALAMITY', value: 'NATURAL_CALAMITY' },
        { 'label': 'PASSENGER JOURNEY CANCELLED', value: 'PASSENGER_JOURNEY_CANCELLED' },
    ]

    const ORDER_UNDELIVERED_REASON = [
        { 'label': 'CUSTOMER NOT AVAILABLE', value: 'CUSTOMER_NOT_AVAILABLE' },
        { 'label': 'OTP MISMATCH', value: 'OTP_MISMATCH' },
        { 'label': 'VENDOR INABILITY', value: 'VENDOR_INABILITY' },
        { 'label': 'VENDOR CLOSED', value: 'VENDOR_CLOSED' },
        { 'label': 'CUSTOMER DENIED', value: 'CUSTOMER_DENIED' },
    ]


    return (
        <Formik
            initialValues={{
                status: data ? data.status : "",
                remarks: data && data.remarks ? data.remarks : "",
                deliveryPersonName: data && data.deliveryPersonName ? data.deliveryBoy : "",
                otp: '1234',
                deliveryPersonContactNo: data && data.deliveryPersonContactNo ? data.deliveryBoyMob : "",
                aditionalRemarks: "ok",
                orderItems: [

                ]
            }}
            validationSchema={validate}
            onSubmit={updateStatus}

            enableReinitialize
        >
            {formik => (
                <div className="modal-content container" >
                    <Form onSubmit={formik.handleSubmit}>
                        <Typography variant='caption'>Order Status <span className='req'>*</span></Typography>
                        <SelectFieldOld
                            name='status'
                            options={ROLES_OPTIONS}
                            isSearchable={false}
                            value={ROLES_OPTIONS.filter(function (option) {
                                return option.value === formik.values.status;
                            })}
                            onChange={(e) => { formik.setFieldValue('status', e.value); setOrderStatus(e.value); formik.setFieldValue('remarks', "") }}
                        />
                        {(orderStatus === "ORDER_CANCELLED" || orderStatus === "ORDER_UNDELIVERED") &&
                            <>
                                <Typography>Reason <span className='req'>*</span></Typography>
                                <SelectFieldOld
                                    name='remarks'
                                    options={orderStatus === "ORDER_CANCELLED" ? ORDER_CANCELED_REASON : ORDER_UNDELIVERED_REASON}
                                    isSearchable={false}
                                    value={orderStatus === "ORDER_CANCELLED" ? ORDER_CANCELED_REASON.filter(function (option) {
                                        return option.value === formik.values.remarks;
                                    }) : ORDER_UNDELIVERED_REASON.filter(function (option) {
                                        return option.value === formik.values.remarks;
                                    })}
                                    onChange={(e) => formik.setFieldValue('remarks', e.value)}
                                />
                            </>
                        }
                        {orderStatus === "ORDER_OUT_FOR_DELIVERY" &&
                            <>
                                <div>
                                    <TextField placeholder="Delivery Boy Name" size="small" className='w-100 my-4' sx={{ padding: '0px' }} name='deliveryPersonName' value={formik.values.deliveryBoy} onChange={formik.handleChange} />
                                </div>
                                <div>
                                    <TextField placeholder="Delivery Boy Mobile" size="small" className='w-100' name='deliveryPersonContactNo' value={formik.values.deliveryBoyMob} onChange={formik.handleChange} />
                                </div>
                            </>
                        }
                        {orderStatus === "ORDER_PARTIALLY_DELIVERED" &&
                            <>
                                <br />
                                <div>
                                    {data.orderItems.map((value, i) => {
                                        return (
                                            <div key={i}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox onChange={(e) => {
                                                            if (e.target.checked) {
                                                                formik.values.orderItems.push({ "itemId": value.item, "quantity": Number(value.quantity) })
                                                            } else {
                                                                formik.values.orderItems = formik.values.orderItems.filter((val) => val.itemId !== value.item)
                                                            }
                                                        }} name={value.item} />
                                                    }
                                                    label={value.itemName}
                                                />
                                                <input className='form-control' type='' onChange={(e) => {
                                                    for (let i = 0; i < formik.values.orderItems.length; i++) {
                                                        if (formik.values.orderItems[i].itemId === value.item) {
                                                            formik.values.orderItems[i].quantity = Number(e.target.value);
                                                        }
                                                    }
                                                }} placeholder="quantity" size="small" />
                                            </div>
                                        )
                                    })}
                                    <br />
                                    <div>Enter OTP</div>
                                    <TextField placeholder="otp" size="small" className='w-100' name='otp' value={formik.values.otp} onChange={formik.handleChange} />
                                </div>
                            </>
                        }
                        <textarea type="text" name="aditionalRemarks" value={formik.values.aditionalRemarks} onChange={formik.handleChange} style={{ width: "100%", marginTop: '20px' }} />
                        <SubmitButton
                            loading={handleUpdateIsLoading}
                            fullWidth
                            label='Update Status'
                            sx={{ mb: 3 }}
                        />

                        <div>

                            <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.remarks && formik.touched.remarks ? formik.errors.remarks : null}</span>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    )
}

export default IRCTCStatusForm;