import React from 'react'
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useUser } from '../../components/hooks/user';
import StationComp from '../../components/Station/StationComp';

const StationsPage = () => {
    const { user } = useUser();
    return (
      <div>
        <Header user={user}/>
  <Sidenav/>
  <div className="content-wrapper">
<StationComp/>

</div>
      </div>
    )
  }

export default StationsPage