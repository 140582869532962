import React, { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";

import TextField from "../Common/MuiTextField";

import { useUpdateStation } from "../hooks/stations";

export default function UpdateStationForm({ data }) {
  const { handleUpdateStation, handleUpdateIsLoading } = useUpdateStation(
    data && data._id
  );
  const closeItemModal = useRef();

  const updateStation = async (data, { resetForm }) => {
    const res = await handleUpdateStation(data);
    // console.log(res)
    if (res.success) {
      toast.success("Station Updated");
      resetForm();
      closeItemModal.current.click();
    } else {
      toast.error(res.error);
    }
  };

  const validate = Yup.object({
    stationName: Yup.string().required("select Station Name"),
    stationCode: Yup.string().required("Enter Station Code"),
  });
  return (
    <Formik
      initialValues={{
        stationName: data ? data.stationName : "",

        stationCode: data ? data.stationCode : "",

        isServiceAvailable: data ? data.isServiceAvailable : "",
      }}
      validationSchema={validate}
      onSubmit={updateStation}
      enableReinitialize
    >
      {(formik) => (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Update Station Form
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body ">
            <Form className="form-row" onSubmit={formik.handleSubmit}>
              <div className="form-group col-md-6">
                <TextField
                  label="Station Name"
                  name="stationName"
                  placeholder="Station Name"
                  sx={{ mb: 3 }}
                  
                />
              </div>

              <div className="form-group col-md-6">
                <TextField
                  label="Station Code"
                  name="stationCode"
                  placeholder="Station Code"
                  sx={{ mb: 3 }}
                />
              </div>

              <div className="form-group col-md-6 item-div">
                <Field
                  as="select"
                  name="isServiceAvailable"
                  className="custom-select mr-sm-2"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Field>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  ref={closeItemModal}
                >
                  Close
                </button>
                {handleUpdateIsLoading ? (
                  <button className="btn btn-dark">...</button>
                ) : (
                  <button className="btn btn-primary">Update</button>
                )}

                {/* <SubmitButton  fullWidth label="Update" loading={handleUpdateIsLoading}/> */}
              </div>

              <div>
                <span className="form-error">
                  {formik.errors.status && formik.touched.status
                    ? formik.errors.status
                    : null}
                </span>
              </div>
              <div>
                <span className="form-error">
                  {formik.errors.remarks && formik.touched.remarks
                    ? formik.errors.remarks
                    : null}
                </span>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
