import React, { useEffect, useState } from 'react'
import { useItemList } from '../hooks/item'
import Loader from '../Loader'
import FilterComponent from '../Common/FilterComponent'
import DataTable from 'react-data-table-component'
import ViewItemModel from '../model/ViewItemModel'
import { endpoints } from '../../config/endpoints'
import UpdateItemModel from '../model/UpdateItemModel'
import { API_HOST_URL } from '../../config'
import DeleteItemModel from '../model/DeleteItemModel'



const Viewitems = ({ id }) => {
    const { itemData, itemIsLoading } = useItemList(id)
    const [flag, setFlag] = useState(false)
    const [item, setItem] = useState([])
    const [Itemid, setItemid] = useState([])
    const [render, setRender] = useState(false);





    const sendData = async (id) => {
        try {
            const fetchApi = await fetch(`${API_HOST_URL}/${endpoints.item.getDetail}/${id}`)
            const datat = await fetchApi.json()
            setItem(datat.data)
            setFlag(datat.success)
        } catch (error) {
            console.log(error);
            throw error;
        }
    };



    const [filterText, setFilterText] = React.useState("");

    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            // <div className="d-flex justify-content-between align-items-center mb-3">
               
                <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            // </div>
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: 'Item Name',
            selector: e => e.itemName,
            cell: e => <th scope="row">{e.itemName}</th>,
            sortable: true,
        },
        {
            name: 'Type',
            selector: e => <td>{e.itemType}</td>,
            sortable: true,
        },
        {
            name: 'Veg/Non-veg',
            selector: e => <td>
                {e.category == 'nonVeg' ? <span className="badge badge-danger">Non-Veg</span> : <span className="badge badge-success">Veg</span>} <br />
            </td>,
            sortable: true,
        },
        {
            name: 'Food Type',
            selector: e => <td>{e.itemType}</td>,
            sortable: true,
        },
        {
            name: 'Avalibility',
            selector: e => <td>
                {e.availability === true ? (
                    <p className="aval">Yes</p>
                ) : (
                    <p className="noaval">No</p>
                )}
            </td>
        },
        {
            name: 'Base Price',
            selector: e => <td>
                {!e.basePrice ? "" : e.basePrice.$numberDecimal + " rs"}
            </td>
        },

        {
            name: 'View',
            selector: e => <td >
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalview"
                    data-whatever="@getbootstrap"
                    onClick={() => {
                        sendData(e._id)
                    }}
                >
                    View
                </button>

            </td>
        }, {
            name: 'Update',
            selector: e => <td >
                {" "}

                <button type="button"
                    className="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalupdate"
                    data-whatever="@getbootstrap"
                    onClick={() => {
                        sendData(e._id)
                    }}
                >
                    Update
                </button>
            </td>,

        },
        {
            name: 'Delete',
            selector: e => <td>
                <button type="button"
                    className="btn btn-danger btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModaldelete"
                    data-whatever="@getbootstrap"
                    onClick={() => {
                        setItemid(e._id)

                    }}
                >
                    Delete
                </button>
            </td>
        },

    ];

    if (itemIsLoading) {
        return <Loader />
    }

    // Filter data
    const filteredItems = itemData.length > 0 ? itemData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    ) : [];


    return (
        <>
            <div className="card-body p-2">

                <ViewItemModel
                    id="exampleModalview"

                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    item={item}
                />

                <DeleteItemModel
                    id="exampleModaldelete"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    Itemid={Itemid}
                    render={render}
                    setRender={setRender}
                />

                < UpdateItemModel
                    id="exampleModalupdate"

                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    data={item}

                    flag={flag}
                // changeState = {changeState}
                />

                <div className="table-wrapper bg-white pt-3 rounded">
                <h5 className="mb-0  text-dark text-center">Restaurant Menu Items</h5>
                    {
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            direction="auto"
                            fixedHeaderScrollHeight="300px"
                            pagination
                            responsive
                            progressPending={itemIsLoading}
                            subHeaderAlign="right"
                            subHeaderWrap
                            subHeaderComponent={subHeaderComponent}
                            subHeader
                        />
                    }

                </div>
            </div>
        </>
    )
}

export default Viewitems