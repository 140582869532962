import { capitalize, Switch } from '@mui/material'
import React from 'react'
import { convertMinutesToHours, smartTrim, truncate } from '../../../utils/functions'
import RestroMenuTable from './RestroMenuTable';
import UpdateRestaurant from '../../../components/model/UpdateRestaurant';

export default function RestroDetailComp({ data, isLoading }) {
    // console.log('RESTRO DATA', data)
    const [updateModal, setUpdateModal] = React.useState(false)
    const [showMenuTable, setShowMenuTable] = React.useState(false);
    const walletValue = parseFloat(data?.wallet?.$numberDecimal ?? 0);
    const badgeClass = walletValue < 0 ? 'badge badge-danger' : 'badge badge-success';

    return (
        <div>

            <div className='card bg-light py-2 px-2 rounded-0 mb-1'>
                <div className="d-flex justify-content-between align-items-center">
                    <div className='d-flex align-items-start align-items-center'>
                        {data?.logo ? <img
                            className="profile-user-img img-fluid img-circle mr-2"
                            src={data.logo}
                            alt="Logo"
                        /> : <img
                            className="profile-user-img img-fluid img-circle mr-2"
                            src="../../dist/img/resturant.jpg"
                            alt="Logo"
                        />}
                        <div>
                            <h6 className="card-body-title font-p2 font-bolder text-warning mb-0">{data?.name}</h6>
                            <p className="text-muted mb-0 text-sm">{truncate(data?.address ?? '', 100)}</p>
                            <div>
                                {data?.status === "Active" && data?.restaurant_type?.map((e, index) => (
                                    <span
                                        key={index}
                                        className={e === "Non-Veg" ? "badge badge-danger mr-1" : "badge badge-success mr-1"}>
                                        {capitalize(e)}
                                    </span>
                                ))}
                                {data?.status !== "Active" && <span
                                    className={`badge ${data?.status === "Active" ? "badge-success" : " badge-danger"}`} >
                                    {data?.status}
                                </span>}
                            </div>
                            <hr className='m-0 p-0 mt-2 mb-1' />
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='card-title m-0 p-0 text-sm'>Min. Order:
                                    <span className='card-text text-sm font-bold text-dark m-0 p-0'> Rs. {data?.min_order_amount?.$numberDecimal ?? 0}</span>
                                </h6>
                                <h6>-</h6>
                                <h6 className='card-title m-0 p-0 text-sm'>Min. Time:
                                    <span className='card-text text-sm font-bold text-dark m-0 p-0'> {convertMinutesToHours(data?.min_order_time ?? 0)}</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <button className='btn btn-sm btn-primary mr-1' onClick={() => setUpdateModal(true)}>Edit</button>
                        <button className={`btn btn-sm ${showMenuTable ? 'btn-dark' : 'btn-primary'}  mr-1`} onClick={() => setShowMenuTable(!showMenuTable)}>{showMenuTable ? 'Hide' : 'Show'} Menu</button>
                        <hr className='m-0 p-0 mt-2 mb-2' />
                        <div className=''>
                            <h6 className='d-flex align-items-center card-text font-p2 font-bolder text-warning'>
                                <span className={badgeClass}>Balance Rs: {data?.wallet?.$numberDecimal}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                {showMenuTable && <RestroMenuTable data={data} />}


            </div>

            {updateModal && <UpdateRestaurant
                modalIsOpen={updateModal}
                setModalIsOpen={setUpdateModal}
                data={data}
            />}
        </div>
    )
}
