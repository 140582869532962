
import React, { useRef } from 'react';
import { Formik} from 'formik';
import * as Yup from 'yup';
import TextField from '../Common/MuiTextField';
import "yup-phone";
import { Stack } from '@mui/material';
import SubmitButton from '../Common/Button';
import { useUpdateVendor } from '../hooks/vendors';
import { toast } from 'react-toastify';

const UpdateVendorForm = ({data}) => {
     const closemodel = useRef()

const {handleUpdateVendor,handleUpdateIsLoading} = useUpdateVendor(data._id)

const updateVendor = async (val) =>{
    const res = await handleUpdateVendor(val);
    if(res.success){
        toast.success("Vendor Updated")
        closemodel.current.click()
    }else{
        // console.log(res)
        toast.error(res.error)
    }
}



    const validate = Yup.object({
        name: Yup.string().min(4)
            .required('Enter vendor\'s name.'),
        email: Yup.string().email()
            .required('Enter email address.'),
        mobile: Yup.string()
            .required('Enter mobile number.'),
      
    })
    return (
        <Formik
            initialValues={{
                name: data.name,
                email: data.email,
                mobile: data.mobile,
                altMobile: data.altMobile,

              
            }}
            validationSchema={validate}
            onSubmit={updateVendor}
            enableReinitialize
        >
{
    (formik) => 
     <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">
      Update Vendor
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        ref={closemodel}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    
    
    <form onSubmit={formik.handleSubmit}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='Vendor Name *' name='name' placeholder='Vendor name' />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='Mobile *' name='mobile' placeholder='Mobile' />
                    <TextField label='Alt. Contact' name='altMobile' placeholder='Alternate mobile' />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='Email *' name='email' placeholder='Email' />
                </Stack>



                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <SubmitButton
                        loading={handleUpdateIsLoading}
                        fullWidth
                        label='Update Vendor'
                    />
                </Stack>
            </form></div>
            </div>
}
 </Formik>
    )
}

export default UpdateVendorForm