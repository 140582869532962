import React, { useState } from 'react'
import { useInvoicesList } from '../../components/hooks/invoices';
import Sidenav from '../../components/Sidenav/Sidenav';
import Header from '../../components/Header/Header';
import { useUser } from '../../components/hooks/user';
import InvoicesTable from './Comp/InvoicesTable';

export default function InvoicesListPage() {
    const { user } = useUser();
    const { invoicesData, isLoadingInvoices, filterInvoices } = useInvoicesList();
    
    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper mt-2">
                <div className="card mx-2">
                    
                    <InvoicesTable data={invoicesData} isLoading={isLoadingInvoices} />
                </div>

            </div>
        </div>
    )
}
