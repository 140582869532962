import React from 'react'
import { useUser } from '../../components/hooks/user';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import BlogContent from '../../components/BlogComponent/BlogContent';

const Blog = () => {
  const { user} = useUser();

  return (
    <div>
      {" "}
      <Header user={user} />
      <Sidenav />
      <div className="content-wrapper">
        <BlogContent />
      </div>
    </div>
  );
}

export default Blog