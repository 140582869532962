import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "../Common/FilterComponent";
import Loader from "../Loader";
import { useItemType } from "../hooks/item";
import FoodAddModal from "../model/FoodAddModel";

const FoodTypeCont = () => {
    //   const { handleAddFoodType, addFoodTypeIsLoading } = useFoodTypeItem();
    const { itemTypeData, itemTypeIsLoading } = useItemType()
    // console.log(itemTypeData)
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: "Food Type",
            selector: (row) => row.food_type,
            cell: (row) => row.food_type,
            sortable: true,
        },

        {
            name: "Active",
            selector: (row) => (row.active ? "Yes" : "No"),
        },
    ];

    if (itemTypeIsLoading) {
        return <Loader />;
    }

    const filteredItems = itemTypeData && itemTypeData.filter(
        (item) =>
            JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
            -1
    );

    return (
        <>
            <div className="container" style={{ padding: "20px" }}>
                <div className="text-right">
                    <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalFood"
                        className="btn btn-dark"
                    >
                        Add Food Type <i className="fa fa-plus"></i>
                    </button>
                </div>
                <div className="table-wrapper">
                    <h3 className="text-center" style={{ padding: "5px" }}>
                        Food Type Item List
                    </h3>
                    {
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            direction="auto"
                            fixedHeaderScrollHeight="300px"
                            pagination
                            responsive
                            progressPending={itemTypeIsLoading}
                            // conditionalRowStyles={conditionalRowStyles}

                            subHeaderAlign="right"
                            subHeaderWrap
                            subHeaderComponent={subHeaderComponent}
                            subHeader
                        />
                    }
                </div>
            </div>

            <FoodAddModal
                id="exampleModalFood"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            />
        </>
    );
};

export default FoodTypeCont;
