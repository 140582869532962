import { Formik } from "formik";
import JoditEditor from "jodit-react";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import TextField from "../../components/Common/MuiTextField";
import { toast } from "react-toastify";
import { API_HOST_URL } from "../../config";
import { getTokens } from "../../utils/core";
import * as Yup from "yup";
import Sidenav from '../Sidenav/Sidenav';
import Header from '../Header/Header';
import { useUser } from '../hooks/user';
import { useAddLocation } from "../hooks/location";
import { useNavigate } from "react-router-dom";

const AddLocation = () => {
  const editor = useRef(null);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const file1 = useRef()
  const file2ref = useRef()
  const { user } = useUser();
  const validate = Yup.object({
    title: Yup.string().required("Enter title."),
    pageTitle: Yup.string().required("Enter pageTitle."),
    slug: Yup.string().required("Enter slug."),
    // content: Yup.string().required("Enter content."),
    headTag: Yup.string().required("Enter Head TaG."),
    stationName: Yup.string().required("Enter stationName."),
    stationCode: Yup.string().required("Enter stationCode."),
  });

  const navigate = useNavigate()

const {handleAddLocation,addLocationIsLoading} = useAddLocation()

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFile2Change = (event) => {
    setFile2(event.target.files[0]);
  };


const handleSubmitLocation = async(val,{resetForm}) =>{
  const data = await handleAddLocation(val)
 if(data.success){
toast.success("Location Added success!!")
resetForm()
navigate('/location')
 }else{
  toast.error(data.error)
        // throw error;
 }
}

  // const handleSubmit = async (val, { resetForm }) => {
  //   const {
  //     title,
  //     content,
  //     pageTitle,
  //     stationName,
  //     headTag,
  //     stationCode,
  //     slug
  //   } = val;
  

  //   const formData = new FormData();

  

  //   formData.append("title", title);
  //   formData.append("pageTitle", pageTitle);
  //   formData.append("twitterImage", file);
  //   formData.append("ogImage", file2);
  //   formData.append("content", content);
  //   formData.append("slug", slug);
  //   formData.append("headTag", headTag);
  //   formData.append("stationName", stationName);
  //   formData.append("stationCode", stationCode);

  //   const { accessToken } = getTokens();

  //   await fetch(`${API_HOST_URL}/api/v1/location/add`, {
  //     method: "POST",
  //     body: JSON.stringify(val),
  //     headers: { Authorization: `Bearer ${accessToken}` },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data)
  //         if(data.success){
              
  //             editor.current.value = ""
  //             file1.current.value = ""
              
  //             file2ref.current.value = ""
  //             setSelected([])
  //             resetForm();
  //             toast.success("Location Added Successfully!!");
            
  //       }else{
           
  //           toast.error(data.error)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       toast.error(error)
  //       throw error;
  //     });
  // };

  const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  // buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true
  },
  width: 800,
  height: 842
};

  return (
    <>
                 <Header user={user}/>
  <Sidenav/>
  <div className="content-wrapper">

 

      <Formik
        initialValues={{
          title: "",
          pageTitle: "",
          content: "",
         slug : "",
         headTag :"",
          stationName: "",
          stationCode: "",
        }}
        validationSchema={validate}
        onSubmit={handleSubmitLocation}
//  onSubmit={(val)=>console.log(val)}
        enableReinitialize
      >
        {(formik) => (
          <div style={{ width: "70%", margin: "auto" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Location
              </h5>
            </div>
            <div className="modal-body">
              <form
                onSubmit={formik.handleSubmit}
                className="form-row"
                id="form"
              >
                <div className="form-group col-md-12">
                  <TextField
                    label="Title"
                    name="title"
                    placeholder="Title"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <TextField
                    label="Page Title"
                    name="pageTitle"
                    placeholder="Page Title"
                    sx={{ mb: 3 }}
                  />
                </div>
                {/* <div className="form-group col-md-12">
                  <TextField
                    label="Meta Title"
                    name="metaTitle"
                    placeholder="Meta Title"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <TextField
                    label="Meta Keyword"
                    name="metaKeyword"
                    placeholder="Meta Keyword"
                    sx={{ mb: 3 }}
                  />
                </div> */}
                <div className="form-group col-md-12">
                  <TextField
                    label="Slug"
                    name="slug"
                    placeholder="Slug"
                    sx={{ mb: 3 }}
                  />
                </div>
                {/* <div className="form-group col-md-12">
                  <TextField
                    label="FB Title"
                    name="ogTitle"
                    placeholder="Og Title"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <TextField
                    label="Fb Description"
                    name="ogDescription"
                    placeholder="Og Description"
                    sx={{ mb: 3 }}
                  />
                </div> */}
                <div className="form-group col-md-12">
                  <TextField
                    label="Head Tag"
                    name="headTag"
                    placeholder="Head Tag"
                    sx={{ mb: 3 }}
                  />
                </div>
              
                <div className="form-group col-md-12">
                  <TextField
                    label="Station Name"
                    name="stationName"
                    placeholder="station Name"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-12">
                  <TextField
                    label="Station Code"
                    name="stationCode"
                    placeholder="station Code"
                    sx={{ mb: 3 }}
                  />
                </div>
     
             
     

                <div className="form-group col-md-4">
                  <label>Twitter Image</label>

                  <input
                    type="file"
                    name="twitterImage"
                    onChange={handleFile2Change}
                    ref={file1}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Fb Image</label>

                  <input
                    type="file"
                    ref={file2ref}
                    name="ogImage"
                    onChange={handleFileChange}
                  />
                </div>
              

                <div className="form-group col-md-12" style={{ maxWidth: editorConfig.width, margin: "0 auto" }}>
                  <JoditEditor
                    ref={editor}
                    config={editorConfig}
                    tabIndex={1}
                    onChange={(newContent) =>
                      formik.setFieldValue("content", newContent)
                    }
                  />
                </div>

                <div className="modal-footer">
               {addLocationIsLoading ? "Loading...." :  <button className="btn btn-success" type="submit">
                    Add
                  </button>}  
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
  </div>
    </>
  );
};

export default AddLocation;
