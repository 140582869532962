import { IconButton } from '@mui/material';
import React from 'react'
import Modal from 'react-modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import TransactionForm from './TransactionF/orm';
// import { useTransactionAdd } from '../../../components/hooks/vendors';
import toast from 'react-hot-toast';
import AddPromotionForm from './AddPromotionForm';
import { useUpdateRest } from '../../hooks/restaurants';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px'
    },
};

export default function AddPromotionModal({ data, modal, setModal }) {
    const { handleUpdateRest, handleUpdateIsLoading } = useUpdateRest(data?._id)
    const handleSubmit = async (values, { resetForm }) => {
        const toastId = toast.loading('Processing...');
        const res = await handleUpdateRest({
            promotions: {
                requirement: {
                    type: values?.requirement_type, //Enum - AMOUNT, PAYMENT_TYPE
                    minimumOrderAmount: values?.minimumOrderAmount
                },
                discount: {
                    type: values?.discount_type, //enum PERCENTAGE or FLAT
                    value: values?.value,
                    maxDiscount: values?.maxDiscount // In case of no upper limit of discount (maxDiscount : -1)
                }
            }
        });
        if (res.success) {

            toast.success('Promotion updated successfully.', { id: toastId })
            setModal(false);
            resetForm({ values: '' });
        } else {
            toast.error('Failed to updated promotion.', { id: toastId });
        }
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={refetch}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add new promotion"
        >
            <div className="container-fluid" style={{ width: '100%' }}>

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3">Mange promotion</h5>
                    <IconButton onClick={() => setModal(false)}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <AddPromotionForm
                        handleSubmit={handleSubmit}
                        isLoading={handleUpdateIsLoading}
                        data={{
                            requirement_type: data?.promotions?.requirement?.type,
                            minimumOrderAmount: data?.promotions?.requirement?.minimumOrderAmount,
                            discount_type: data?.promotions?.discount?.type,
                            value: data?.promotions?.discount?.value,
                            maxDiscount: data?.promotions?.discount?.maxDiscount,
                        }}
                    />
                </div>

            </div>

        </Modal>
    )
}
