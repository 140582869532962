import { Box, Card, CardContent, Typography } from "@mui/material";

export default function CancelReqDetail({ item }) {

    return <div className="card" >
        <div className="row p-3">
            <div className="flex-fill">
                <h6 className="text-secondary">Name</h6>
                <p>{item.name}</p>
            </div>
            <div className="flex-fill">
                <h6 className="text-secondary">Reason</h6>
                <p >{item.reason}</p>
            </div>
        </div>
        <div className="row p-3">
            <div className="flex-fill">
                <h6 className="text-secondary">Comment</h6>
                <p>{item.comment}</p>
            </div>
           
        </div>
        <div className="d-flex flex-row p-3">
            <div className="flex-fill">
                <h6 className="text-secondary">PNR</h6>
                <p>{item.pnr}</p>
            </div>
            <div className="flex-fill">
                <h6 className="text-secondary">Mobile</h6>
                <p>{item.mobile}</p>
            </div>
        </div>
        <div className="d-flex flex-row p-3">
            <div className="flex-fill">
                <h6 className="text-secondary">Order Id</h6>
                <p>{item.orderId}</p>
            </div>
            <div className="flex-fill">
                <h6 className="text-secondary">Status</h6>
                <p>{item.status}</p>
            </div>
        </div>
        {/* <div className="d-flex flex-row p-3">
            <div className="flex-fill">
                <button
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                >
                    Ok <i className="fa fa-check"></i>
                </button>
            </div>
        </div> */}
    </div>
}