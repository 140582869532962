


export const filterDataNonveg = (e,allRef,vegRef,NonvegRef,itemData,setFilterFood) => {
    allRef.current.click();
    if (vegRef.current.checked) {
      vegRef.current.checked = false;
      NonvegRef.current.checked = true;
      const Nonveg = itemData.filter((e) => e.category === "nonVeg");
      setFilterFood(Nonveg);
    } else if (vegRef.current.checked === false && e.target.checked) {
      NonvegRef.current.checked = true;
      vegRef.current.checked = false;
      const Nonveg = itemData.filter((e) => e.category === "nonVeg");
      setFilterFood(Nonveg);
    } else if (e.target.checked === false && vegRef.current.checked === false) {
      setFilterFood(itemData);
    }
  };




 export const filterDataVeg = (e,allRef,NonvegRef,vegRef,itemData,setFilterFood) => {
    allRef.current.click();
    if (NonvegRef.current.checked) {
      vegRef.current.checked = true;
      NonvegRef.current.checked = false;
      const veg = itemData.filter((e) => e.category === "veg");
      setFilterFood(veg);
    } else if (NonvegRef.current.checked === false && e.target.checked) {
      vegRef.current.checked = true;
      NonvegRef.current.checked = false;
      const veg = itemData.filter((e) => e.category === "veg");
      setFilterFood(veg);
    } else if (
      e.target.checked === false &&
      NonvegRef.current.checked === false
    ) {
      setFilterFood(itemData);
    }
  };
