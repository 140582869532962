import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'


import { toast } from 'react-toastify'
import SubmitButton from '../../../Common/Button'
import TextField from '../../../Common/MuiTextField'
import SelectField from '../../../Common/SelectField'
import { useNewUpdateOrderStatus } from '../../../hooks/orders'

const NewOrderStatusForm = ({ data, setNewstatusmodal }) => {

    const ROLES_OPTIONS = [
        { 'label': 'ORDER PREPARING', value: 'ORDER_PREPARING' },
        { 'label': 'ORDER DELIVERED', value: 'ORDER_DELIVERED' },
        { 'label': 'ORDER CANCELLED', value: 'ORDER_CANCELLED' },
        { 'label': 'ORDER UNDELIVERED', value: 'ORDER_UNDELIVERED' },
        { 'label': 'ORDER PARTIALLY DELIVERED', value: 'ORDER_PARTIALLY_DELIVERED' },

    ]

    const ORDER_CANCELED_REASON = [
        { 'label': 'BEYOND SERVICE HOUR', value: 'BEYOND_SERVICE_HOUR' },
        { 'label': 'TRAIN DELAYED', value: 'TRAIN_DELAYED' },
        { 'label': 'LAW N ORDER', value: 'LAW_N_ORDER' },
        { 'label': 'NATURAL CALAMITY', value: 'NATURAL_CALAMITY' },
        { 'label': 'PASSENGER JOURNEY CANCELLED', value: 'PASSENGER_JOURNEY_CANCELLED' },
    ]

    const ORDER_UNDELIVERED_REASON = [
        { 'label': 'CUSTOMER NOT AVAILABLE', value: 'CUSTOMER_NOT_AVAILABLE' },
        { 'label': 'OTP MISMATCH', value: 'OTP_MISMATCH' },
        { 'label': 'VENDOR INABILITY', value: 'VENDOR_INABILITY' },
        { 'label': 'VENDOR CLOSED', value: 'VENDOR_CLOSED' },
        { 'label': 'CUSTOMER DENIED', value: 'CUSTOMER_DENIED' },
    ]

    const [orderStatus, setOrderStatus] = useState();
    const { handleNewUpdateOrder, handleNewUpdateIsLoading } = useNewUpdateOrderStatus(
        data._id
    );
    const updateStatus = async (data) => {
        const res = await handleNewUpdateOrder(data);

        if (res.success) {
            setNewstatusmodal(false)
            toast.success("Order Status Updated Successfully");
        } else {
            toast.error(res.error);
        }

    }

    return (
        <div>
            <Formik
                initialValues={{
                    status: data ? data.status : "",

                    remarks: data && data.remarks ? data.remarks : "",
                    additionalRemarks: "Ok",
                    otp: "",
                    orderItems: [

                    ]

                }}
                onSubmit={updateStatus}
                enableReinitialize
            >
                {(formik) => (
                    <Form>

                        <div className='col-md-12 mt-3  '>
                            <Typography>Order Status <span className='req'>*</span></Typography>
                            <SelectField
                                name='status'
                                options={ROLES_OPTIONS}
                                isSearchable={false}
                                value={ROLES_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.status;
                                })}
                                onChange={(e) => { formik.setFieldValue('status', e.value); setOrderStatus(e.value); formik.setFieldValue('remarks', "") }}
                            />
                        </div>
                        <div className='col-12'>


                            {
                                formik.values.status === 'ORDER_CANCELLED' && (<>
                                    <div className='mt-3  '>
                                        <Typography>Reason <span className='req'>*</span></Typography>
                                        <SelectField
                                            name='remarks'
                                            options={ORDER_CANCELED_REASON}
                                            isSearchable={false}
                                            value={ORDER_CANCELED_REASON.filter(function (option) {
                                                return option.value === formik.values.remarks;
                                            })}
                                            onChange={(e) => formik.setFieldValue('remarks', e.value)}
                                        />
                                    </div>
                                </>)
                            }

                            {
                                formik.values.status === 'ORDER_UNDELIVERED' && (<>
                                    <div className=' mt-3  '>
                                        <Typography>Reason <span className='req'>*</span></Typography>
                                        <SelectField
                                            name='remarks'
                                            options={ORDER_UNDELIVERED_REASON}
                                            isSearchable={false}
                                            value={ORDER_UNDELIVERED_REASON.filter(function (option) {
                                                return option.value === formik.values.remarks;
                                            })}
                                            onChange={(e) => formik.setFieldValue('remarks', e.value)}
                                        />
                                    </div>
                                </>)
                            }

                            {formik.values.status === "ORDER_PARTIALLY_DELIVERED" &&
                                <>
                                    <br />
                                    <div>
                                        {data.orderItems.map((value, i) => {
                                            return (
                                                <div key={i}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    formik.values.orderItems.push({ "itemId": value.item, "quantity": Number(value.quantity) })
                                                                } else {
                                                                    formik.values.orderItems = formik.values.orderItems.filter((val) => val.itemId !== value.item)
                                                                }
                                                            }} name={value.item} />
                                                        }
                                                        label={value.itemName}
                                                    />
                                                    <input className='form-control' type='' onChange={(e) => {
                                                        for (let i = 0; i < formik.values.orderItems.length; i++) {
                                                            if (formik.values.orderItems[i].itemId === value.item) {
                                                                formik.values.orderItems[i].quantity = Number(e.target.value);
                                                            }
                                                        }
                                                    }} placeholder="quantity" size="small" />
                                                </div>
                                            )
                                        })}
                                        <br />
                                        <div>Enter otp</div>
                                        <TextField placeholder="otp" size="small" className='w-100' name='otp' value={formik.values.otp} onChange={formik.handleChange} />
                                    </div>
                                </>
                            }

                        </div>

                        <div className="col-md-12 mt-3">
                            <TextField name="additionalRemarks" label="Additional Remarks" />
                        </div>

                        <div className=' col-12 mt-3 '>
                            <SubmitButton
                                loading={handleNewUpdateIsLoading}
                                fullWidth
                                label='Update Status'
                                sx={{ mb: 3 }}
                            />
                        </div>
                        <div>

                            <span style={{ color: 'red', fontSize: '12px' }}>{formik.errors.remarks && formik.touched.remarks ? formik.errors.remarks : null}</span>
                        </div>

                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default NewOrderStatusForm
