
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '../Common/MuiTextField';
import "yup-phone";
import { Stack } from '@mui/material';
import SubmitButton from '../Common/Button';


export default function AddVendorForm({ handleSubmit, isLoading }) {
    const validate = Yup.object({
        name: Yup.string().min(4)
            .required('Enter vendor\'s name.'),
        email: Yup.string().email()
            .required('Enter email address.'),
        mobile: Yup.string()
            .required('Enter mobile number.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Enter your password.'),
        
    })
    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                mobile: '',
                altMobile: '',
                password: '',
              
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            <Form>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='Vendor Name *' name='name' placeholder='Vendor name' />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='Mobile *' name='mobile' placeholder='Mobile' />
                    <TextField label='Alt. Contact' name='altMobile' placeholder='Alternate mobile' />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <TextField label='Email *' name='email' placeholder='Email' />
                    <TextField label='Password *' name='password' placeholder='Password' />
                </Stack>

    

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={2}>
                    <SubmitButton
                        loading={isLoading}
                        fullWidth
                        label='Add Vendor'
                    />
                </Stack>
            </Form>
        </Formik>
    )
}
