import React from "react";
import OrderInfoForm from "../form/OrderInfoForm";
// import OrderInfoForm from "../Forms/OrderInfoForm";
// import Image from "next/image";

export default function PlaceOrderModal({
  handlePlaceOrderSubmit,
  isPlaceOrderLoading,
  pnrNumber,
  item,
  restId,
  payable,
  discount,
  setDiscount,
  total_amount ,
  paymenttext,
  setPaymentText,
  changePayment
}) {
  // console.log(item)
  return (
    <>
      <div
        className="modal"
        id="placeorder"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="tcModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modelPayment">
            <div style={{background:'#2a465c'}}>

        
            <img  src={"/assets/img/place-order-form.webp"} alt="10% off Yatri restro" height={"120"} width={"500"} />
          <div style={{textAlign:'right',background:'white',marginLeft:'8px',marginRight:'8px',borderTopLeftRadius:'20px',borderTopRightRadius:'20px'}}>
          <button
                type="button"
                className="btn shadow-none"
                aria-label="Close"
                data-dismiss="modal"
              >
                <i className="fa fa-times"></i>
              </button>
          </div>
            <div
              className="d-flex justify-content-between modelhead"
              style={{ padding: "20px" ,background:'white',marginLeft:'8px',marginRight:'8px',display:'flex',justifyContent:"space-between"}}
            >
              <h5 className="modal-title mb-3 modelhead" style={{width:'30%'}}>Place Order</h5>
              <h5 className="modal-title mb-3 modelhead" style={{textAlign:'right',width:'70%'}}>Amount :  <span className="text-success fw-500 text-center">{payable}</span> Rs.</h5>
            
            </div>
            <div  style={{ padding: "20px" ,background:'white',marginLeft:'8px',marginRight:'8px',display:'flex',justifyContent:"center"}}
>

            <span className="text-danger fw-500 text-center">Flat <span style={{fontWeight:'900'}}>10%</span> off on Online Payment</span>
            </div>
           
            <div className="modal-body" style={{background:'white',marginRight:'8px',marginLeft:'8px',marginBottom:'10px',borderBottomLeftRadius:'20px',borderBottomRightRadius:'20px'}}>

              <OrderInfoForm
                isLoading={isPlaceOrderLoading}
                handleSubmit={handlePlaceOrderSubmit}
                pnrNumber={pnrNumber}
                item={item}
                restId={restId}
                discount={discount}
                setDiscount={setDiscount}
                total_amount ={total_amount }
                paymenttext={paymenttext}
                setPaymentText={setPaymentText}
                payable={payable}
                changePayment={changePayment}
                />
            </div>
                </div>
          </div>
        </div>
      </div>
    </>
  );
}
