import React from 'react'
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useVendorAdd } from '../hooks/vendors';
import AddVendorForm from '../form/AddVendorForm';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
const customStyles = {
    content: {
        top: '60%',
        left: '60%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px'
    },
};

export default function AddVendorModal({ modalIsOpen, setModalIsOpen }) {
    const { handleAddVendor, addVendorIsLoading } = useVendorAdd()
    const handleSubmit = async (values, { resetForm }) => {
        const res = await handleAddVendor(values);
        if (res.success) {
            setModalIsOpen(false);
            toast.success('Added new vendor.');
            resetForm({ values: '' });
        } else {
            toast.error(res.error)
        }
    }
    return (
        <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={refetch}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add new station"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3">Add new vendor</h5>
                    <IconButton onClick={() => setModalIsOpen(false)}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <AddVendorForm
                        handleSubmit={handleSubmit}
                        isLoading={addVendorIsLoading}
                    />
                </div>

            </div>

        </Modal>
    )
}
