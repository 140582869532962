import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../components/hooks/user";
import { toast } from "react-toastify";
import LoginForm from "../../components/form/LoginForm";

const LoginPage = () => {
  let navigate = useNavigate();
  const { handleLogin, loginLoading } = useLogin();
  const [response, setResponse] = React.useState(null);
  


  // useEffect(()=>{
  //     const  accessToken = localStorage.getItem("token");
  //     if(!accessToken){
  //       navigate("/account/login")
  //     }else{
  //       navigate('/')
         
  //     }
  // },[])

  const handleSubmit = async (email, password) => {
    const res = await handleLogin(email, password);
    setResponse(res);
    if (res.success) {
      toast.success("Login successfull");
      navigate(`/`);
    } else {
      toast.error(res.error);
    }
  };
  return (
    <div className="am-signin-wrapper">
      <div className="am-signin-box">
        <div className="row no-gutters">
          <div className="col-lg-5">
            <div>
              <div className="pb-3">
                <img src="/assets/img/logo.png" alt="img" />
              </div>
              <p>
                Welcome to our login portal!<br></br>
                Please enter your credentials to access your account.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <h5 className="tx-gray-800 mg-b-25">Signin to Your Account</h5>
            <LoginForm
              handleSubmit={handleSubmit}
              loginLoading={loginLoading}
              response={response}
            />
          </div>
        </div>
        <p className="tx-center tx-white-5 tx-12 mg-t-15">
          &copy; {new Date().getFullYear()} Yatri Restro. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
