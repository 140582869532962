import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import { useState } from "react";


const INVOICES_QUERY_KEY = 'invoices';
const INVOICE_DATA_QUERY_KEY = 'invoice_data';
const INVOICE_QUERY_KEY = 'invoice';

export function useInvoicesList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([INVOICES_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.invoices.list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, error: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        // refetchOnWindowFocus: true,
        // refetchOnMount: true,
        enabled: enabled,
    })
    return {
        invoicesData: data,
        isLoadingInvoices: isLoading,
        filterInvoices: async (value) => setParam(value),
    }
}


// export function useInvoiceData(defaultParam = '', enabled = true) {
//     const [param, setParam] = useState(null);
//     const { isLoading, data } = useQuery([INVOICE_DATA_QUERY_KEY, { param: param, defaultParam }], async (key) => {
//         const param = key.queryKey[1].param;
//         const defaultParam = key.queryKey[1].defaultParam;
//         try {
//             const { accessToken } = getTokens();
//             const param_url = param ? param : defaultParam;
//             const res = await fetchJson(`${API_HOST_URL}/${endpoints.invoices.data}${param_url}`, {
//                 headers: { 'Authorization': `Bearer ${accessToken}` },
//             }, true)
//             return await res.json()

//         } catch (err) {
//             return { success: false, error: err };
//         }
//     }, {
//         cacheTime: Infinity,
//         staleTime: 30_000,
//         enabled: enabled,
//     })
//     return {
//         invoiceData: data,
//         isLoadingInvoice: isLoading,
//         getInvoice: async (value) => setParam(value),
//     }
// }



export function useInvoiceData() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();

    const mutation = useMutation((url) => {
        return fetchJson(`${API_HOST_URL}/${endpoints.invoices.data}${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        }, true);
    }, {
        retry: 2,
    });

    return {
        getInvoiceData: async (url) => {
            try {
                const res = await mutation.mutateAsync(url);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([INVOICES_QUERY_KEY]);
                }
                return data;
            } catch (err) {
                console.log(err);
                return {
                    success: false,
                    error: err
                };
            }
        },
        isLoadingInvoice: mutation.isLoading,
    };
}

export function useGenerateInvoice() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.invoices.create}/${values.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true), {
        retry: 2,
    })

    return {
        handleCreateInvoice: async (values) => {

            try {
                const res = await mutation.mutateAsync(values);
                const data = await res.json();
                if (data.success) {
                    await queryClient.invalidateQueries([INVOICES_QUERY_KEY])
                }
                return data;
            } catch (err) {
                console.log(err)
                return {
                    success: false,
                    error: err
                }
            }
        },
        isInvoiceGenerating: mutation.isLoading,
    }
}