import { getTokens } from "../../utils/core";
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { API_HOST_URL, TOKEN_STORE_NAME } from "../../config";


const USER_QUERY_KEY = 'user'
const USERS_QUERY_KEY = 'users'

// Get User
export function useUser() {
    const { isLoading, error, data, status } = useQuery([USER_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data }= await fetchJson(`${API_HOST_URL}/${endpoints.account.user}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { user: data, isLoading, error, status }
}

// Login 
export function useLogin() {
    const queryClient = useQueryClient();
    const mutation = useMutation(({ email, password }) => fetchJson(`${API_HOST_URL}/${endpoints.account.login}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            email,
            password
        })
    }, true))
    return {
        handleLogin: async (email, password) => {
            try {
                const res = await mutation.mutateAsync({ email, password });
                const user = await res.json();
                if (user.success) {
                    const { token } = user;
                    localStorage.setItem(TOKEN_STORE_NAME, JSON.stringify(token));
                    queryClient.setQueryData([USER_QUERY_KEY], user.data);
                }
                return user

            } catch (err) {
                return {
                    success: false,
                    error: 'something wents wrong.'
                }
            }
        },
        loginLoading: mutation.isLoading,
    }
}

// Logout
export function useLogout() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation(() => fetchJson(`${API_HOST_URL}/${endpoints.account.logout}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true))
    return async () => {
        try {
            await mutation.mutateAsync();
            localStorage.removeItem(TOKEN_STORE_NAME);
            queryClient.setQueryData([USER_QUERY_KEY], null);
            return true
        } catch (err) {
            return false
        }
    }
}

// Get Users List
export function useUsersList() {
    const { isLoading, data } = useQuery([USERS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const { data }= await fetchJson(`${API_HOST_URL}/${endpoints.account.users_list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { usersData: data, usersIsLoading:isLoading }
}


// Add New User
export function useAddUser() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
	const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.account.add_user}`, {
		method: 'POST',
		headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        },
		body: JSON.stringify(values)
	}, true))
    return { 
        handleAddUser: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const user = await res.json();
                if (user.success) {
                    console.log(user) 
                    await queryClient.invalidateQueries([USERS_QUERY_KEY])
                }
                
                return user
                
            } catch (err) {
                return {
                    success: false
                }
            }            
        },
        addUserIsLoading: mutation.isLoading,
    }
}

//Reset Password
export function useResetPassword(id){
    const {accessToken}=getTokens()
    const mutation=useMutation({
        mutationFn:(values)=>fetchJson(`${API_HOST_URL}/api/v1/admin/reset-password`,
            {
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${accessToken}`
                },
                body:JSON.stringify(values)
            }, true
        )
    })
    return{ handleResetPassword:async(values)=>{
        try {
            const res =await mutation.mutateAsync(values)
            const data =await res.json()
            return data
        } catch (error) {
            return {success:false}
        }
    }, handleResetLoading: mutation.isLoading,
}
}