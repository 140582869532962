import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import FilterComponent from "../Common/FilterComponent";
import Loader from "../Loader";
import { usePageList } from "../hooks/pages";
import ViewPageModel from "../model/ViewPageModel";

const PageCont = () => {
  const { pageData, pageIsLoading } = usePageList();
  const [page,setPage] = useState()
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <div>
          <img
            src={`https://backapi.yatrirestro.com/${row.ogImage}`}
            crossorigin="anonymous"
            alt={"img"}
            width={"100px"}
            height={"80px"}
          />
        </div>
      ),
      //            cell: row => <div>
      // <img  src= {`https://backapi.yatrirestro.com/uploads/1693287769473.png`} alt={"img"} width={"100px"} height={"80px"}/>
      //          </div>,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
      cell: (row) => row.slug,
      sortable: true,
    },
    {
        name: 'View',
        selector: row => <button className='btn btn-primary' data-toggle="modal"
        data-target="#exampleModalview2"
        data-whatever="@getbootstrap"
onClick={()=>{
    setPage(row)
}}
      >View</button>
    },
    {
        name: 'Update',
        selector: row =><Link to={`/update-page/${row.slug}/${row._id}`} className='btn btn-primary'>Update</Link>
    },
  ];

  if (pageIsLoading) {
    return <Loader />;
  }

  const filteredItems = pageData.length>0? pageData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  ):[];

  return (
    <>
      <div className="container" style={{ padding: "20px" }}>
        <div className="text-right">
          <Link to={"/addpages"} className="btn btn-dark">
            Add Page <i className="fa fa-plus"></i>
          </Link>
        </div>
        <div className="table-wrapper">
          <h3 className="text-center" style={{ padding: "5px" }}>
            Pages List
          </h3>
          {
            <DataTable
              columns={columns}
              data={filteredItems}
              direction="auto"
              fixedHeaderScrollHeight="300px"
              pagination
              responsive
              progressPending={pageIsLoading}
              // conditionalRowStyles={conditionalRowStyles}

              subHeaderAlign="right"
              subHeaderWrap
              subHeaderComponent={subHeaderComponent}
              subHeader
            />
          }
        </div>
      </div>

      <ViewPageModel
            id="exampleModalview2"
      
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            page={page}
          />
    </>
  );
};

export default PageCont;
