
import moment from "moment";

import { Card } from "@mui/material";
import { useRestaurantsList, stripResturantsList } from "../hooks/restaurants";
import { Form, Formik } from "formik";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import SelectField from "../Common/SelectField";
import { API_HOST_URL } from "../../config";
import React, { useState } from "react";
import { getTokens } from "../../utils/core";
import axios from "axios";
import SelectFieldOld from "../Common/SelectField_old";



export default function OrdersExport() {



    const { restaurantsData, restaurantsIsLoading } = useRestaurantsList();

    const [vendor, setVendor] = React.useState("")
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [isFetching, setIsFetching] = React.useState(false);
    const [error, setError] = React.useState(null);


    const [flag, setFlag] = useState(true);


    const changeFlag = () => {
        if (flag) {
            setFlag(false);
        } else {
            setFlag(true);
        }
    };

    const downloadFile = async () => {
        setIsFetching(true);
        setError(null);

        try {
            const { accessToken } = getTokens();
            const response = await axios.get(`${API_HOST_URL}/api/v1/order/admin/report/${vendor}?fromDate=${startDate}&toDate=${endDate}`, {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            const contentType = response.headers['content-type'];
            if (!contentType.includes('text/csv')) {
                throw new Error('Invalid file type. Expected text/csv');
            }

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv'); // Replace with the desired file name
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            setError(err);
        } finally {
            setIsFetching(false);
        }
    };
    return (
        <div className="p-3 ">
            <Card >
                <div className="p-3">
                    {/* {!flag ? (
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-bs-toggle="button"
                            onClick={changeFlag}
                        >
                            Download Report
                        </button>
                    ) : (
                        <button
                            className="btn btn-dark"
                            data-bs-toggle="button"
                            onClick={changeFlag}
                        >
                            Hide  <i className="fa fa-eye-slash"></i>
                        </button>
                    )} */}
                    <div>
                        {flag ? (
                            <div>
                                <Formik
                                    initialValues={{
                                        vendorname: "",
                                        datefrom: "",
                                        dateTo: ""
                                    }}

                                >
                                    {(formik) => (
                                        <Form>
                                            <div className='d-flex' style={{ flexDirection: "column" }}>
                                                <p className="text-center poppins-semibold">Export Data</p>
                                                <div className="d-flex flex-row align-items-center " style={{ justifyContent: "space-around" }}>

                                                    <div className='d-flex' style={{ width: "30%" }}>
                                                        {/* <div className='mx-2 mt-2'>
                                                            <Typography variant="h9">
                                                                Vendor
                                                            </Typography>
                                                        </div> */}
                                                        <div style={{ width: "80%" }}>
                                                            {/* {!restaurantsIsLoading ? ( */}
                                                            <SelectFieldOld
                                                                options={restaurantsData?.map(stripResturantsList)}
                                                                // onChange={(e) => formik.setFieldValue("vendorname", e.value)}
                                                                onChange={(e) => setVendor(e.value)}
                                                                name="vendorname"
                                                                placeholder="Select Vendor"
                                                                isLoading={restaurantsIsLoading}
                                                            />
                                                            {/* ) : ""} */}

                                                        </div>

                                                    </div>
                                                    <div className='' style={{ marginTop: "-4px" }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker
                                                                    slotProps={{ textField: { size: 'small' } }}
                                                                    // value={moment(startDate, 'YYYY-MM-DD')}
                                                                    value={startDate ? moment(startDate, 'YYYY-MM-DD') : null}
                                                                    ampm={false}
                                                                    // onChange={(value) => setStartDate(moment.utc(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'))}
                                                                    onChange={(value) => setStartDate(moment(value).format('YYYY-MM-DD'))}
                                                                    name="datefrom" label="Date From" />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className='' style={{ marginTop: "-4px" }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker
                                                                    slotProps={{ textField: { size: 'small' } }}
                                                                    value={endDate ? moment(endDate, 'YYYY-MM-DD') : null}
                                                                    ampm={false}
                                                                    onChange={(value) => setEndDate(moment(value).format('YYYY-MM-DD'))}
                                                                    name="dateTo"
                                                                    label="Date To"
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className=''>
                                                        {/* <button className='btn btn-primary' type="submit" onClick={downloadFile} disabled={isFetching} >  {isFetching ? 'Processing...' : 'Download File'}</button> */}
                                                        <button className='btn btn-primary d-flex align-items-center justify-content-center' type="submit" onClick={downloadFile} disabled={isFetching}>
                                                            <i className="material-icons-outlined me-2">{isFetching ? 'hourglass_empty' : 'file_download'}</i>
                                                            {isFetching ? 'Processing...' : 'Download File'}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div> {error && <p className="text-center mt-3 text-danger">Error downloading file: {error.message}</p>}</div>


                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Card>
        </div>
    )
}
