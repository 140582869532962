import React, { useMemo, useState } from 'react'
import FilterComponent from '../Common/FilterComponent';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { useFeatureList } from '../hooks/feature';

const FeatureComp = () => {
    const {featureData,featureIsLoading} = useFeatureList()
    const [filterText, setFilterText] =useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);



    const subHeaderComponent = useMemo(() => {
       const handleClear = () => {
           if (filterText) {
               setResetPaginationToggle(!resetPaginationToggle);
               setFilterText("");
           }
       };

       return (
           <FilterComponent
               onFilter={e => setFilterText(e.target.value)}
               onClear={handleClear}
               filterText={filterText}
           />
       );
   }, [filterText, resetPaginationToggle]);


   const columns = [
       {
           name: 'Image',
           selector: row => row.image,
           cell: row => <div>
<img   src= {row.image} alt={row.altTag} width={"100px"} height={"80px"}/>
         </div>,
           sortable: true,
       },
       {
           name: 'Alt Tag',
           selector: row => row.altTag,
           sortable :true
       },
       {
           name: 'Price',
           selector: row => row.price,
           cell: row=>row.price,
           sortable: true,
       },
       {
           name: 'Offer Price',
           selector: row => row.offerPrice
       },
      
       {
           name: 'Featured',
           selector: row => row.featured ?<span className="badge badge-success">YES</span> : <span className="badge badge-danger">NO</span>
       }
   ];

   if (featureIsLoading) {
       return <Loader />
   }



      const filteredItems = featureData.filter(
       item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
   );


    
   return <>
  
  


<div className="container" style={{padding : "20px"}}>
<div className="text-right">
   
   <Link  to={"/addfeatureitem"}  className="btn btn-dark">Add Featured Image <i className="fa fa-plus"></i></Link>  
              </div>
   <div className="table-wrapper">
<h3 className="text-center" style={{ padding: "5px" }}>
                Featured Item List
               </h3>
               {
                   <DataTable
                   columns={columns}
                   data={filteredItems}
                   
                   direction="auto"
                   fixedHeaderScrollHeight="300px"
                   pagination
                       responsive
                       progressPending={featureIsLoading}
                       // conditionalRowStyles={conditionalRowStyles}
                       
                       subHeaderAlign="right"
                       subHeaderWrap
                       subHeaderComponent={subHeaderComponent}
                       subHeader
                       />
                   }
           </div>
                   </div> 
   </>
}
export default FeatureComp