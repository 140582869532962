import { Field, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { useAddItem, useCusineType, useItemType } from "../hooks/item";
import TextField from "../Common/MuiTextField";
import SelectField from "../Common/SelectField";
import { Typography } from "@mui/material";
import Loader2 from "../Common/Loader2";
import { stripCusineList, stripItemList } from "../hooks/vendors";


const AddItemForm = () => {
    const { handleAddItem } = useAddItem();
    const { cusineTypeData } = useCusineType();
    const { itemTypeData, itemIsLoading } = useItemType();

    const cusineref = useRef();

    const { id } = useParams();
    const closeref = useRef();

    const signUpSchema = Yup.object({
        itemName: Yup.string().min(2).required("Please enter Item name"),
        description: Yup.string().required("Please enter Description"),
        // opening_time: Yup.string().required("Please select Opening time"),
        // closing_time: Yup.string().required("Please select Closing time"),
        category: Yup.string().required("Please select category"),
        // isFeatured: Yup.string().required("Please select Featured or not"),
        isFestiveItem: Yup.string().required("Please select Festive or not"),
        // festiveStartDate: Yup.string().required("Please Enter Festive Start Date"),
        // festiveEndDate: Yup.string().required("Please Enter Festive End Date"),
        // isBestSelling: Yup.string().required("Please select Best Selling or not"),
        isRecomended: Yup.string().required("Please select Recomanded or not"),
        yrPrice: Yup.string().required("Please enter Yr price"),
        basePrice: Yup.string().required("Please enter Base price"),
        gst: Yup.string().required("Please Enter Gst"),
        itemType: Yup.string().required("Please select Item type"),
        cusineType: Yup.string().required("Please select Cusine type"),
    });

    const sendData = async (data, { resetForm }) => {
        const res = await handleAddItem(data);
        if (res.success) {
            toast.success("Featured Item Added");
            document.getElementById("festiveEndDate").value = "";
            document.getElementById("festiveStartDate").value = "";

            resetForm();
            closeref.current.click();
        } else {
            toast.error(res.error);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    resturant: id,
                    itemName: "",
                    description: "",
                    festiveStartDate: "",
                    festiveEndDate: "",
                    opening_time: "",
                    closing_time: "",
                    isFeatured: true,
                    isFestiveItem: true,
                    isBestSelling: true,
                    isRecomended: true,
                    yrPrice: "",
                    basePrice: "",
                    gst: "",
                    itemType: "",
                    cusineType: "",
                    category: "veg",
                }}
                validationSchema={signUpSchema}
                onSubmit={sendData}
                // onSubmit={(val)=>console.log(val)}
                enableReinitialize
            >
                {(formik) => (
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Add Item
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ref={closeref}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={formik.handleSubmit} className="form-row">
                                <div className="form-group col-md-6">
                                    <TextField
                                        label="Item Name"
                                        name="itemName"
                                        placeholder="Item Name"
                                        sx={{ mb: 3 }}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <Field
                                        as="textarea"
                                        placeholder="Description"
                                        name="description"
                                        style={{ width: "100%" }}
                                    ></Field>
                                </div>

                                <div className="form-group col-md-6">
                                    <Typography variant="h9" color="#949494">
                                        Item Type <span className="req">*</span>
                                    </Typography>
                                    {itemTypeData ? (
                                        <SelectField
                                            options={itemTypeData.map(stripItemList)}
                                            onChange={(e) =>
                                                formik.setFieldValue("itemType", e.value)
                                            }
                                            name="itemType"
                                        />
                                    ) : (
                                        <Loader2 color="inherit" />
                                    )}
                                    <span className="form-error" style={{ color: "red", fontSizeL: "10px" }}>
                                        {formik.errors.itemType && formik.touched.itemType
                                            ? formik.errors.itemType
                                            : null}
                                    </span>
                                </div>

                                <div className="form-group col-md-6">
                                    <Typography variant="h9" color="#949494">
                                        Cusine Type <span className="req">*</span>
                                    </Typography>
                                    {cusineTypeData ? (
                                        <SelectField
                                            ref={cusineref}
                                            options={cusineTypeData.map(stripCusineList)}
                                            onChange={(e) =>
                                                formik.setFieldValue("cusineType", e.value)
                                            }
                                            name="cusineType"
                                        />
                                    ) : (
                                        <Loader2 color="inherit" />
                                    )}
                                    <span className="form-error" style={{ color: "red", fontSizeL: "10px" }}>
                                        {formik.errors.cusineType && formik.touched.cusineType
                                            ? formik.errors.cusineType
                                            : null}
                                    </span>
                                </div>

                                <div className="form-group col-md-6">
                                    <TextField
                                        label="Yatri Restro price"
                                        name="yrPrice"
                                        placeholder="yrPrice"
                                        sx={{ mb: 3 }}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <TextField
                                        label="basePrice"
                                        name="basePrice"
                                        placeholder="basePrice"
                                        sx={{ mb: 3 }}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="sale_price" className="input-label">
                                        Opening Time
                                    </label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        autoComplete="off"
                                        name="opening_time"
                                        id="opening_time"
                                        placeholder="Opening Time"
                                        value={formik.values.opening_time}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="form-error">
                                        {formik.errors.opening_time && formik.touched.opening_time
                                            ? formik.errors.opening_time
                                            : null}
                                    </span>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="closing_time" className="input-label">
                                        Closing Time
                                    </label>
                                    <input
                                        type="time"
                                        autoComplete="off"
                                        name="closing_time"
                                        className="form-control"
                                        id="closing_time"
                                        placeholder="Closing Time"
                                        value={formik.values.closing_time}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    <span className="form-error">
                                        {formik.errors.closing_time && formik.touched.closing_time
                                            ? formik.errors.closing_time
                                            : null}
                                    </span>
                                </div>

                                <div className="form-group col-md-6 item-div">
                                    <label htmlFor="isFeatured" className="input-label">
                                        Featured
                                    </label>

                                    <select
                                        name="isFeatured"
                                        id="isFeatured"
                                        className="custom-select mr-sm-2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.isFeatured}
                                        aria-label="Default select example"
                                    >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>

                                    <span className="form-error">
                                        {formik.errors.isFeatured && formik.touched.isFeatured
                                            ? formik.errors.isFeatured
                                            : null}
                                    </span>
                                </div>

                                <div className="form-group col-md-6 item-div">
                                    <label htmlFor="isFestiveItem" className="input-label">
                                        FestiveItem
                                    </label>

                                    <select
                                        name="isFestiveItem"
                                        id="isFestiveItem"
                                        className="custom-select mr-sm-2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.isFestiveItem}
                                        aria-label="Default select example"
                                    >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>

                                    <span className="form-error">
                                        {formik.errors.isFestiveItem && formik.touched.isFestiveItem
                                            ? formik.errors.isFestiveItem
                                            : null}
                                    </span>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Festive Start Date</label>
                                    <input
                                        type="date"
                                        name="festiveStartDate"
                                        id="festiveStartDate"
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Festive End Date</label>
                                    <input
                                        type="date"
                                        name="festiveEndDate"
                                        id="festiveEndDate"
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="form-group col-md-6 item-div">
                                    <label htmlFor="isBestSelling" className="input-label">
                                        BestSelling
                                    </label>

                                    <select
                                        name="isBestSelling"
                                        id="isBestSelling"
                                        onChange={formik.handleChange}
                                        className="custom-select mr-sm-2"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.isBestSelling}
                                        aria-label="Default select example"
                                    >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>

                                    <span className="form-error">
                                        {formik.errors.isBestSelling && formik.touched.isBestSelling
                                            ? formik.errors.isBestSelling
                                            : null}
                                    </span>
                                </div>

                                <div className="form-group col-md-6 item-div">
                                    <label htmlFor="isRecomended" className="input-label">
                                        Recomended
                                    </label>

                                    <select
                                        name="isRecomended"
                                        id="isRecomended"
                                        className="custom-select mr-sm-2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.isRecomended}
                                        aria-label="Default select example"
                                    >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>

                                    <span className="form-error">
                                        {formik.errors.isRecomended && formik.touched.isRecomended
                                            ? formik.errors.isRecomended
                                            : null}
                                    </span>
                                </div>

                                <div className="form-group col-md-6">
                                    <TextField
                                        label="gst"
                                        name="gst"
                                        placeholder="gst"
                                        sx={{ mb: 3 }}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="category" className="input-label">
                                        category
                                    </label>

                                    <select
                                        name="category"
                                        id="category"
                                        className="custom-select mr-sm-2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.category}
                                        aria-label="Default select example"
                                    >
                                        <option value="veg">VEG</option>
                                        <option value="nonVeg">NON-VEG</option>
                                    </select>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>

                                    {itemIsLoading ? (
                                        <Loader2 />
                                    ) : (
                                        <button className="btn btn-success" type="submit">
                                            Add
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default AddItemForm;
