import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import InvoiceContent from './InvoiceContent';
import { useDebouncedCallback } from 'use-debounce';

const InvoicePreview = ({ data, isLoading }) => {
    const invoiceRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
    });
    // console.log('MAIN DATA-> ', data)
    // data = {
    //     "commission": 36874, // get api
    //     "undeliveryCharges": 800, // get api
    //     "penalty": 3000, // input field
    //     "gstAtSource": 5525.70, // get api
    //     "onlinePaid": 53959.00, // get api
    //     "previousDues": 4000, // input field
    //     "invDate": "2024-09-07" // input field
    // };


    // Convert string values to numbers
    const commission = Number(data?.totalCommission ?? 0);
    const undeliveryCharges = Number(data?.undeliveryCharges ?? 0);
    const penalty = Number(data?.penalty ?? 0);
    const gstAtSource = Number(data?.gstAtSource ?? 0);
    const onlinePaid = Number(data?.onlinePaid ?? 0);
    const previousDues = Number(data?.previousDues ?? 0);

    // Calculate IGST
    const igst = (commission + undeliveryCharges + penalty) * 0.18;

    // Calculate Net Payable Amount
    const netPayable = commission + undeliveryCharges + penalty + igst + gstAtSource + previousDues - onlinePaid;



    return (
        <div className="container mt-5 border px-5 py-3" ref={invoiceRef}>
            <div >
                {/* Invoice Content */}
                <div className="row d-none">
                    <div className='m-auto px-5 mx-5'>
                        <p className='m-0 poppins-semibold text-warning text-sm'>Inspigear India Pv. Ltd.</p>
                        <p className='m-0 poppins-medium text-warning text-sm'>D/56, PC Colony, Kankarbag, Patna, Bihar-800020</p>
                        <p className='m-0 poppins-medium text-warning text-sm'>https://yatrirestro.com</p>
                    </div>
                    <hr className='custom-hr' />
                    <div>
                        <h5 className='text-center text-shadow poppins-bold mt-3 mb-4'>Tax Invoice</h5>
                    </div>
                </div>

                <div className='row d-none'>
                    <div className='d-flex justify-content-between'>
                        <div className='text-sm'>
                            <p className='m-1 poppins-semibold'>Inspigear India Private Limited</p>
                            <p className='m-1 poppins-medium'>D/56, PC Colony, Kankarbag, Patna, Bihar - 800020</p>
                            <p className='m-1 poppins-medium'>GSTIN: 10AAFCI5092N1ZF</p>
                            <p className='m-1 poppins-medium'>Email: support@yatrirestro.com</p>
                            <p className='m-1 poppins-semibold'>Invoice Number: #10361</p>
                            <p className='m-1 poppins-medium'>Invoice Date: 07/05/2024</p>

                        </div>
                        <div className='text-sm'>
                            <p className='m-1 poppins-medium'>Invoice Period: 07/05/2024 to 08/05/2024</p>
                            <p className='m-1 poppins-medium'>Outlet: : Jai Maa Kamakhya Family Restauran</p>
                            <p className='m-1 poppins-medium'>D/56, PC Colony, Kankarbag, Patna, Bihar - 800020</p>
                            <p className='m-1 poppins-medium'>Mobile: support@yatrirestro.com</p>
                            <p className='m-1 poppins-medium'>Email: support@yatrirestro.com</p>
                            <p className='m-1 poppins-medium'>GSTIN: 10AAFCI5092N1ZF</p>
                        </div>
                    </div>
                </div>

                <table className="table table-bordered text-center mt-4">
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Particulars</th>
                            <th>Amount (Rs.)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td className='poppins-semibold'>Total Commission</td>
                            <td>{commission}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Non-Delivery Fee</td>
                            <td>{undeliveryCharges}</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Penalty (By IRCTC)</td>
                            <td>{penalty}</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td className='poppins-semibold'>IGST</td>
                            <td>{igst.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td className='poppins-semibold'>GST Collected at Source</td>
                            <td>{gstAtSource}</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>*Add: Previous Month Balance</td>
                            <td>{previousDues}</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>*Less: Online Payments received</td>
                            <td>{onlinePaid}</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td className='poppins-bold'>Net Payable Amount</td>
                            <td>{netPayable.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="text-sm mt-4">
                    <p className='m-0'>This is a computer-generated invoice and requires no signature.</p>
                    <p className='m-0'>You may write to us at support@yatrirestro.com</p>
                </div>
            </div>

            {/* Download Button */}
            {/* <div className="text-sm mt-5">
                <button className="btn btn-primary" onClick={downloadInvoice}>
                    Download Invoice as PDF
                </button>
            </div> */}
        </div>
    );
};

export default InvoicePreview;