export const statusBadgeClass = {
    "ORDER_PLACED": "badge-warning",
    "ODRDER_PREPARING": "badge-warning",
    "ORDER_ACCEPTED": "badge-primary",
    "ORDER_READY": "badge-success",
    "ORDER_DELIVERED": "badge-success"
};


export const splitText = (text, maxLength) => {
    if (!text) return '-';
    const regex = new RegExp(`.{1,${maxLength}}`, 'g');
    return text.match(regex).join('\n');
};

export const truncate = (str, max, suffix = ' ...') => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;

export const smartTrim = (str, length, delim = ' ', appendix = ' ...') => {
    if (str.length <= length) return str;

    var trimmedStr = str.substr(0, length + delim.length);
    var lastDelimIndex = trimmedStr.lastIndexOf(delim);

    if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);

    if (trimmedStr) trimmedStr += appendix;
    return trimmedStr;
}


export function capitalize(str) {
    try {
        const lower = str.toLowerCase();
        return str.charAt(0).toUpperCase() + lower.slice(1);
    } catch (error) {
        return str;
    }
}


export function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''} ` : ''}${remainingMinutes > 0 ? `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}` : ''}`.trim();
}


export const formatWalletValue = (value) => {
    const number = parseFloat(value);
    if (isNaN(number)) return '0'; // Handle invalid numbers
    return number < 0 ? `-${Math.abs(number)}` : `${number}`;
};